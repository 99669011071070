<template>
  <v-container class="pa-4" fluid>
    <loading-bar :loading="isLoading" />
    <v-card class="translucent" rounded="0">
      <v-toolbar density="compact" class="bg-transparent" flat>
        <v-toolbar-title>
          {{ i18n.t("orderlist.order_list") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="download()">
          <v-icon>mdi-cloud-download</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        densitiy="compact"
        :headers="headers"
        hover
        :items="viewdata_ary"
        :items-per-page="15"
        :search="search"
        class="bg-transparent mt-4 order-list"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AppList"
};
</script>
<script setup>
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import LoadingBar from "@/components/parts/LoadingBar.vue";
import { useFilterDataStore } from "@/stores/filterData";
import { useInitialDataStore } from "@/stores/initialData";
import { useUserinfo } from "@/stores/userinfo";

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const initialDataStore = useInitialDataStore();
const userinfoStore = useUserinfo();

const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { firstdata, isLoading } = storeToRefs(initialDataStore);
const { productivity } = storeToRefs(userinfoStore);
const headers = ref([
  {
    title: i18n.t("orderlist.order_id"),
    align: "start",
    key: "order_id",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.order_no"),
    align: "start",
    key: "order_no",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.repeat_order_no"),
    align: "start",
    key: "repeat_order_no",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.plant"),
    align: "start",
    key: "city_plant_no",
    headerProps: { class: ["px-1", "minwidth96px", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.length_mm"),
    align: "end",
    key: "sheetsize_length_mm",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.width_mm"),
    align: "end",
    key: "sheetsize_width_mm",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.volume"),
    align: "end",
    key: "prod_volume",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.prod_volume_size"),
    key: "prod_volume_size",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.loss_volume"),
    align: "end",
    key: "loss_volume",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.loss_rate"),
    align: "end",
    key: "loss_rate",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.bundle"),
    key: "bundle",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.sc_os_slotter_input_value"),
    key: "sc_os_slotter_input_value",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.sc_ds_slotter_input_value"),
    key: "sc_ds_slotter_input_value",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.sc_register_input_value"),
    key: "sc_register_input_value",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.sc_box_depth_input_value"),
    key: "sc_box_depth_input_value",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.dc_information"),
    key: "dc_information",
    align: "center",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.flute"),
    key: "flute_str",
    align: "center",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.segment"),
    align: "center",
    key: "segment",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.from"),
    key: "prod_start_time_local",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.to"),
    key: "prod_end_time_local",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.time_min"),
    align: "end",
    key: "prod_time",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.stoptime_min"),
    align: "end",
    key: "stop_time",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.stop_count"),
    key: "stop_count",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.stop_count_per_vol"),
    key: "stop_count_per_vol",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.prod_speed_ratio"),
    key: "prod_speed_ratio",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  },
  {
    title: i18n.t("orderlist.productivity"),
    key: "productivity",
    align: "end",
    headerProps: { class: ["px-1", "nowrap"] }
  }
]);
const viewdata_ary = ref([]);
const search = ref("");

watch(
  stateFilterData,
  () => {
    fillData();
  },
  {
    deep: true,
    immediate: true
  }
);
watch(
  firstdata,
  newFirstData => {
    if (newFirstData && newFirstData.length > 0) {
      fillData();
    }
  },
  {
    immediate: true
  }
);

/**
 * テーブルデータを表示するメソッド
 */
function fillData() {
  const nextDateOf = yyyymmdd =>
    dayjs(yyyymmdd).add(1, "days").format("YYYY-MM-DD");
  const func_filter = item => {
    return (
      (unref(stateFilterData).selectregion.length === 0 ||
        unref(stateFilterData).selectregion.indexOf(item.region) >= 0) &&
      (unref(stateFilterData).selectcustomer.length === 0 ||
        unref(stateFilterData).selectcustomer.indexOf(item.customer) >= 0) &&
      (unref(stateFilterData).selectplant.length === 0 ||
        unref(stateFilterData).selectplant.indexOf(item.city_plant_no) >= 0) &&
      (unref(stateFilterData).selectsegment.length === 0 ||
        unref(stateFilterData).selectsegment.indexOf(item.segment) >= 0) &&
      (!unref(stateFilterData).from ||
        dayjs(item.prod_end_time_local).isAfter(unref(stateFilterData).from)) &&
      (!unref(stateFilterData).to ||
        dayjs(item.prod_end_time_local).isBefore(
          nextDateOf(unref(stateFilterData).to) // Toの日付の23:59まで含めるため、「Toの翌日より前のもの」で絞り込む
        )) &&
      (!unref(stateFilterData).selectorderid ||
        item.order_id.indexOf(unref(stateFilterData).selectorderid) >= 0) &&
      (!unref(stateFilterData).selectorderno ||
        item.order_no.indexOf(unref(stateFilterData).selectorderno) >= 0)
    );
  };

  viewdata_ary.value = unref(firstdata).filter(func_filter);
}
/**
 * ダウンロード
 * オブジェクトを変換してるので表よりヘッダ多い
 */
function download() {
  if (unref(viewdata_ary).length) {
    //ヘッダ
    const csvheader = Object.keys(unref(viewdata_ary)[0]);
    //オブジェクトのデータを配列-配列化
    let csvarray = unref(viewdata_ary).map(obj =>
      csvheader.map(key => {
        return obj[key];
      })
    );
    //ヘッダを頭につける
    const csvheader_text = csvheader.map(key => i18n.t("orderlist." + key));
    csvarray.unshift(csvheader_text);
    //stringの配列に変換
    const data = csvarray
      .map(row =>
        row.map(str => '"' + (str ? String(str).replace(/"/g, '""') : "") + '"')
      )
      .map(row => row.join(","))
      .join("\n");

    const now = new Date();
    const nowstr =
      String(now.getFullYear()) +
      String(now.getMonth() + 1) +
      String(now.getDate()) +
      String(now.getHours()) +
      String(now.getMinutes()) +
      String(now.getSeconds());

    const anchor = document.createElement("a");
    if (window.URL && anchor.download !== undefined) {
      // utf8
      const bom = "\uFEFF";
      const blob = new Blob([bom, data], { type: "text/csv" });
      anchor.download = "orderlist_" + nowstr + ".csv";

      // window.URL.createObjectURLを利用
      // https://developer.mozilla.org/ja/docs/Web/API/URL/createObjectURL
      anchor.href = window.URL.createObjectURL(blob);

      // これでも可
      // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(bom + data);

      // firefoxでは一度addしないと動かない
      document.body.appendChild(anchor);
      anchor.click();
      anchor.parentNode.removeChild(anchor);
    }
  }
}
function removeSegment() {
  //Segmentコラム非表示
  unref(headers).splice(8, 1);
}

if (!unref(productivity)) {
  removeSegment();
}
</script>

<style>
.minwidth96px {
  min-width: 96px;
}
.nowrap {
  white-space: nowrap;
}
.order-list .v-data-table__th {
  font-size: 12px;
  font-weight: 700 !important;
}
</style>
