<template>
  <highcharts :options="chartOptions" />
</template>

<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useSelectedGraphStyle } from "@/composables/useSelectedGraphStyle";

const prop = defineProps({
  viewData: {
    type: Array,
    default: function () {
      return [];
    }
  },
  hiddenSeriesData: {
    type: Array,
    default: function () {
      return [];
    }
  }
});

const emit = defineEmits(["clickOrderScatterPlot"]);
const i18n = useI18n();
const selectedGraphStyle = useSelectedGraphStyle();
const chartOptions = ref({
  chart: {
    type: "scatter",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "400px",
    zoomType: "xy"
  },
  time: { useUTC: false },
  title: { text: "" },
  legend: { enabled: true },
  series: [
    {
      name: i18n.t("productiontime.setup_time_per_order"),
      data: [],
      turboThreshold: 0, // データポイントの数に関係なく全てのポイントを描画
      allowPointSelect: true,
      color: "#C8E6C9",
      events: {
        click: e => {
          onBarClick(e);
        }
      }
    },
    {
      name: i18n.t("productiontime.test_time_per_order"),
      data: [],
      turboThreshold: 0, // データポイントの数に関係なく全てのポイントを描画
      allowPointSelect: true,
      color: "#C5CAE9",
      events: {
        click: e => {
          onBarClick(e);
        }
      }
    },
    {
      name: i18n.t("productiontime.prod_time_per_order"),
      data: [],
      turboThreshold: 0, // データポイントの数に関係なく全てのポイントを描画
      allowPointSelect: true,
      color: "#9FA8DA",
      events: {
        click: e => {
          onBarClick(e);
        }
      }
    },
    {
      name: i18n.t("productiontime.stop_time_per_order"),
      data: [],
      turboThreshold: 0, // データポイントの数に関係なく全てのポイントを描画
      allowPointSelect: true,
      color: "#FF8A65",
      events: {
        click: e => {
          onBarClick(e);
        }
      }
    },
    {
      name: i18n.t("productiontime.last_stop_time_per_order"),
      data: [],
      turboThreshold: 0, // データポイントの数に関係なく全てのポイントを描画
      allowPointSelect: true,
      color: "#EEEEEE",
      events: {
        click: e => {
          onBarClick(e);
        }
      }
    }
  ],
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    },
    allowDecimals: true,
    gridLineWidth: 1
  },
  yAxis: {
    max: null,
    min: 0,
    title: {
      text: null
    },
    allowDecimals: true
  },
  tooltip: {
    formatter: function () {
      return (
        this.point.tooltip_name_order_id +
        " : " +
        this.point.order_id +
        "<br>" +
        this.point.tooltip_name_order_no +
        " : " +
        this.point.order_no +
        "<br>" +
        this.point.tooltip_name_term +
        " : " +
        this.point.prod_start_time_local +
        " - " +
        this.point.prod_end_time_local +
        "<br>" +
        this.point.tooltip_name_y +
        " : " +
        "<b>" +
        this.point.y.toFixed(2) +
        "</b>"
      );
    }
  },
  plotOptions: {
    scatter: {
      cursor: "pointer",
      marker: {
        radius: 3.5,
        symbol: "circle",
        states: {
          hover: { enabled: true },
          select: {
            enabled: true,
            fillColor: null,
            lineColor: selectedGraphStyle.borderColor.value,
            lineWidth: selectedGraphStyle.borderWidth,
            radius: selectedGraphStyle.plotRadius
          }
        }
      }
    }
  }
});

watch(
  () => prop.viewData,
  () => {
    renderChart();
  }
);

function renderChart() {
  const options = unref(chartOptions);
  options.yAxis.title.text = i18n.t("others.unit.minutes");

  const setupTimeData = [];
  const testTimeData = [];
  const prodTimeData = [];
  const stopTimeData = [];
  const lastStopTimeData = [];

  prop.viewData.forEach(el => {
    const commonPoint = {
      x: dayjs(el.prod_end_time_local).valueOf(),
      order_id: el["order_id"],
      order_no: el["order_no"],
      prod_start_time_local: el["prod_start_time_local"],
      prod_end_time_local: el["prod_end_time_local"],
      tooltip_name_order_id: i18n.t("productiontime.order_id"),
      tooltip_name_order_no: i18n.t("productiontime.order_no"),
      tooltip_name_term: i18n.t("productiontime.term")
    };

    setupTimeData.push({
      ...commonPoint,
      y: el["setup_time_per_order"],
      tooltip_name_y: i18n.t("productiontime.setup_time_per_order")
    });
    testTimeData.push({
      ...commonPoint,
      y: el["test_time_per_order"],
      tooltip_name_y: i18n.t("productiontime.test_time_per_order")
    });
    prodTimeData.push({
      ...commonPoint,
      y: el["prod_time_per_order"],
      tooltip_name_y: i18n.t("productiontime.prod_time_per_order")
    });
    stopTimeData.push({
      ...commonPoint,
      y: el["stop_time_per_order"],
      tooltip_name_y: i18n.t("productiontime.stop_time_per_order")
    });
    lastStopTimeData.push({
      ...commonPoint,
      y: el["last_stop_time_per_order"],
      tooltip_name_y: i18n.t("productiontime.last_stop_time_per_order")
    });
  });

  options.series[0].data = setupTimeData;
  options.series[1].data = testTimeData;
  options.series[2].data = prodTimeData;
  options.series[3].data = stopTimeData;
  options.series[4].data = lastStopTimeData;

  // hiddenSeriesDataに基づいて各シリーズの可視性を設定
  options.series.forEach(s => {
    s.visible = !prop.hiddenSeriesData.includes(s.color);
  });
}

function onBarClick(event) {
  const targetOrderId = event.point.order_id;

  // オーダー情報（テーブル、折れ線、エラーテーブル）を表示するためのステータス、選択したオーダーのオーダーIDを親コンポーネントに送る
  emit("clickOrderScatterPlot", true, targetOrderId);
}
</script>
