import Highcharts from "highcharts";
import axios from "axios";
import { getTokenSilent } from "@/auth";

export const setChartOptions = darktheme => {
  Highcharts.normaltheme = {
    xAxis: {
      labels: { style: { color: "#666666" } }
    },
    yAxis: {
      title: { style: { color: "#666666" } },
      labels: { style: { color: "#666666" } },
      stackLabels: { style: { color: "#666666" } }
    },
    title: { style: { color: "#000000" } },
    subtitle: { style: { color: "#000000" } },
    legend: {
      itemStyle: { color: "#333333" },
      itemHoverStyle: {
        color: "#333333"
      },
      navigation: { style: { color: "#333333" } }
    },
    tooltip: {
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      pointFormat: "{series.name}:{point.y}",
      borderRadius: 10,
      style: {
        color: "#666666",
        fontFamily: "Roboto"
      }
    }
  };

  Highcharts.darktheme = {
    xAxis: {
      labels: { style: { color: "#ffffff" } }
    },
    yAxis: {
      title: { style: { color: "#ffffff" } },
      labels: { style: { color: "#ffffff" } },
      stackLabels: { style: { color: "#ffffff" } }
    },
    title: { style: { color: "#ffffff" } },
    subtitle: { style: { color: "#ffffff" } },
    legend: {
      itemStyle: {
        color: "#ffffff"
      },
      itemHoverStyle: { color: "#ffffff" },
      navigation: { style: { color: "#ffffff" } }
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      pointFormat: "{series.name}:{point.y}",
      borderRadius: 10,
      style: {
        color: "#F0F0F0",
        fontFamily: "Roboto"
      }
    }
  };

  if (darktheme) {
    Highcharts.setOptions(Highcharts.darktheme);
  } else {
    Highcharts.setOptions(Highcharts.normaltheme);
  }

  Highcharts.setOptions({
    lang: {
      thousandsSep: ","
    }
  });
};

export const getLocale = lang => {
  const localeArray = [
    { code: "en", name: "English", en: "English", ja: "英語" },
    { code: "ja", name: "日本語", en: "Japanese", ja: "日本語" }
  ];

  const localeData = localeArray.find(
    el => el.code === lang || el.en === lang || el.ja === lang
  );
  return localeData;
};

export const getLangOptions = locale => {
  let langOptions = "";
  if (locale === "ja") {
    langOptions = ["日本語", "英語"];
  } else if (locale === "en") {
    langOptions = ["Japanese", "English"];
  } else {
    langOptions = ["Japanese", "English"];
  }
  return langOptions;
};

export const connectToAPI = async (
  req,
  alartObj = { flg: false, text: "error" }
) => {
  const { accessToken } = await getTokenSilent();
  const response = await axios({
    headers: { Authorization: `Bearer ${accessToken}` },
    ...req
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      alartObj.flg = true;
      throw err;
    });
  return response;
};

export const convertUnit = (meterNum, isUnitInch, isUnitK) => {
  if (isUnitInch) {
    if (isUnitK) {
      const inchNum = Math.round((meterNum * 1550) / 1000);
      return inchNum;
    } else {
      const inchNum = meterNum * 1550;
      return inchNum;
    }
  } else {
    return meterNum;
  }
};
