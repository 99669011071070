<template>
  <v-navigation-drawer v-model="drawerFlg">
    <v-row class="mx-3 my-3 text-h6"
      >{{ i18n.t("sidebar.filters") }}
      <span style="margin-left: 5px; font-size: 12px"
        >[{{ dataperiod.fromDate }} - {{ dataperiod.toDate }}]</span
      >
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item v-if="route.path !== '/realtime'" class="py-0">
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          location="bottom"
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="fromDate"
              v-bind="props"
              append-inner-icon="mdi-calendar-today"
              clearable
              color="primary"
              :label="i18n.t('sidebar.from')"
              variant="underlined"
              readonly
              @click:clear="editFilter()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDateTime"
            color="primary"
            hide-header
            @update:model-value="setFromToDate('fromDate')"
          ></v-date-picker>
        </v-menu>
      </v-list-item>
      <v-list-item v-if="route.path !== '/realtime'" class="py-0">
        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          location="bottom"
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="toDate"
              v-bind="props"
              append-inner-icon="mdi-calendar-today"
              clearable
              color="primary"
              :label="i18n.t('sidebar.to')"
              variant="underlined"
              readonly
              @click:clear="editFilter()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDateTime"
            color="primary"
            hide-header
            @update:model-value="setFromToDate('toDate')"
          ></v-date-picker>
        </v-menu>
      </v-list-item>
      <v-list-item class="pb-0 pt-8">
        <v-select
          v-model="selectregion"
          color="primary"
          :items="regions"
          :label="i18n.t('sidebar.region')"
          :disabled="!enebleRegion"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('region')"
        ></v-select>
      </v-list-item>
      <v-list-item class="py-0">
        <v-select
          v-model="selectcustomer"
          color="primary"
          :items="customers"
          :label="i18n.t('sidebar.customer')"
          :disabled="!enebleCustomer"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('customer')"
        ></v-select>
      </v-list-item>
      <v-list-item class="py-0">
        <v-select
          v-model="selectplant"
          color="primary"
          :items="plants"
          :label="i18n.t('sidebar.plant')"
          :disabled="!eneblePlant"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('plant')"
        ></v-select>
      </v-list-item>
      <v-list-item
        v-if="
          productivity &&
          (route.path === '/productivity' || route.path === '/list')
        "
        class="py-0"
      >
        <v-select
          v-model="selectsegment"
          color="primary"
          :items="segments"
          :label="i18n.t('sidebar.segment')"
          append-icon="_"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('plant')"
        ></v-select>

        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              density="comfortable"
              variant="text"
              style="position: absolute; right: 11px; top: 14px"
              icon
            >
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>
            <v-img :src="helpimgPath" aspect-ratio="2" width="500px" />
          </span>
        </v-tooltip>
      </v-list-item>
      <v-list-item
        v-if="route.path === '/productivity' || route.path === '/list'"
        class="py-0"
      >
        <v-text-field
          v-model="selectorderid"
          color="primary"
          :label="i18n.t('sidebar.order_id')"
          variant="underlined"
          @update:model-value="editFilter('plant')"
        ></v-text-field>
      </v-list-item>
      <v-list-item
        v-if="route.path === '/productivity' || route.path === '/list'"
        class="py-0"
      >
        <v-text-field
          v-model="selectorderno"
          color="primary"
          :label="i18n.t('sidebar.order_no')"
          variant="underlined"
          @update:model-value="editFilter('plant')"
        ></v-text-field>
      </v-list-item>
      <v-list-item v-if="route.path === '/condition'" class="py-0">
        <v-select
          v-model="selectalarmType"
          color="primary"
          :items="alarmTypes"
          :label="i18n.t('sidebar.alarm_type')"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('alarm')"
        ></v-select>
      </v-list-item>
      <v-list-item v-if="route.path === '/condition'" class="py-0">
        <v-select
          v-model="selectcomponentNote"
          color="primary"
          :items="componentNotes"
          :label="i18n.t('sidebar.component_note')"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('component_note')"
        ></v-select>
      </v-list-item>
      <v-list-item v-if="route.path === '/condition'" class="py-0">
        <v-select
          v-model="selectmeasurement"
          color="primary"
          :items="measurements"
          :label="i18n.t('sidebar.measurement')"
          multiple
          chips
          variant="underlined"
          @update:model-value="editFilter('measurement')"
        ></v-select>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBar"
};
</script>
<script setup>
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import {
  connectToAPI,
  convertToFixedPointNumberOrNA,
  eqArrayEasily,
  replaceInvalidFluteWithNA,
  replaceUndefinedWithNA,
  translateDcInformation
} from "@/helpers/util";
import { useAlarmdetailStore } from "@/stores/alarmdetail";
import { useAuthorities } from "@/stores/authorities";
import { useDataperiodStore } from "@/stores/dataperiod";
import { useDrawerFlgStore } from "@/stores/drawerFlg";
import { useFilterDataStore } from "@/stores/filterData";
import { useInitialDataStore } from "@/stores/initialData";
import { usePlantsMasterStore } from "@/stores/plantsMaster";
import { usePreference } from "@/stores/preference";
import { useUserinfo } from "@/stores/userinfo";

import helpimgEn from "@/assets/translation/img/en/segment.png";
import helpimgJa from "@/assets/translation/img/ja/segment.png";

const i18n = useI18n();
const route = useRoute();
const alarmdetailStore = useAlarmdetailStore();
const authoritiesStore = useAuthorities();
const dataperiodStore = useDataperiodStore();
const drawerFlgStore = useDrawerFlgStore();
const filterDataStore = useFilterDataStore();
const initialDataStore = useInitialDataStore();
const plantsMasterStore = usePlantsMasterStore();
const preferenceStore = usePreference();
const userinfoStore = useUserinfo();

const { alarmdetail: alarms } = storeToRefs(alarmdetailStore);
const {
  isNotInitialized: isNotAuthoritiesInitialized,
  region: rawAuthRegion,
  customer: rawAuthCustomer,
  plant: rawAuthPlant
} = storeToRefs(authoritiesStore);
const { dataperiod: storePeriod } = storeToRefs(dataperiodStore);
const { drawerFlg } = storeToRefs(drawerFlgStore); //drawerのv-modelにセット
const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const {
  firstdata,
  productiondata,
  productiontimedata,
  stopfactordata,
  isLoading
} = storeToRefs(initialDataStore);
const { plantsMaster } = storeToRefs(plantsMasterStore);
const {
  isNotInitialized: isNotPreferenceInitialized,
  period: rawPeriod,
  dateFrom: rawFromDate,
  dateTo: rawToDate,
  language
} = storeToRefs(preferenceStore);
const {
  isNotInitialized: isNotUserinfoInitialized,
  productivity: rawProductivity
} = storeToRefs(userinfoStore);

const toDate = ref(null);
const fromDate = ref(null);
const toDateTime = ref(undefined);
const fromDateTime = ref(undefined);
const toMenu = ref(false);
const fromMenu = ref(false);
const regions = ref([]); //選択可能リージョン
const selectregion = ref([]); //選択したリージョン
const customers = ref([]); //選択可能顧客
const selectcustomer = ref([]); //選択した顧客
const plants = ref([]); //選択可能プラント
const selectplant = ref([]); //選択したプラント
const segments = ref([]); //選択可能分類
const selectsegment = ref([]); //選択した分類
const selectorderid = ref(""); //入力したオーダーID
const selectorderno = ref(""); //入力したオーダーNO
const selectplantid = ref([]); //選択したプラントのID
const alarmTypes = ref([]); //選択可能アラーム種類
const selectalarmType = ref([]); //選択したアラーム種類
const componentNotes = ref([]); //選択可能対象装置
const selectcomponentNote = ref([]); //選択した対象装置
const measurements = ref([]); //選択可能測定項目
const selectmeasurement = ref([]); //選択した測定項目
const dataperiod = ref({ period: "", fromDate: "", toDate: "" });
const enebleRegion = ref(true);
const enebleCustomer = ref(true);
const eneblePlant = ref(true);

const LIMIT_FIRST_DATA = 5000;
/**
 * 認証状態監視フラグ
 */
const authLoading = computed(() => {
  // authorities, preference, userinfo, それぞれの初期化が完了しているとき認証が完了していると考えることができる
  return !(
    unref(isNotAuthoritiesInitialized) ||
    unref(isNotPreferenceInitialized) ||
    unref(isNotUserinfoInitialized)
  );
});
const authRegion = computed(() => {
  return unref(rawAuthRegion).isAll ? [] : unref(rawAuthRegion).targets;
});
const authCustomer = computed(() => {
  return unref(rawAuthCustomer).isAll ? [] : unref(rawAuthCustomer).targets;
});
const authPlant = computed(() => {
  return unref(rawAuthPlant).isAll ? [] : unref(rawAuthPlant).targets;
});
const productivity = computed(() => {
  return unref(rawProductivity) === 1;
});
const authPeriod = computed(() => {
  return {
    period: unref(rawPeriod),
    fromDate: unref(rawFromDate),
    toDate: unref(rawToDate)
  };
});
const helpimgPath = computed(() => {
  if (unref(language) === "ja") {
    return helpimgJa;
  } else {
    return helpimgEn;
  }
});

//認証が監視終わったらデータ取るようにする
watch(authLoading, value => {
  if (!value) {
    loadData();
  }
});
watch(
  storePeriod,
  () => {
    loadData();
  },
  {
    deep: true
  }
);
watch(
  alarms,
  () => {
    createAlarmtypes();
    createComponentNotes();
    createMeasurements();
  },
  {
    deep: true
  }
);
watch(
  stateFilterData,
  async () => {
    //state割り当て
    selectregion.value = unref(stateFilterData).selectregion;
    selectcustomer.value = unref(stateFilterData).selectcustomer;
    selectplant.value = unref(stateFilterData).selectplant;
    toDate.value = unref(stateFilterData).to;
    fromDate.value = unref(stateFilterData).from;
    selectorderid.value = unref(stateFilterData).selectorderid;
    selectorderno.value = unref(stateFilterData).selectorderno;
    selectalarmType.value = unref(stateFilterData).selectalarmType;
    selectcomponentNote.value = unref(stateFilterData).selectcomponentNote;
    selectmeasurement.value = unref(stateFilterData).selectmeasurement;
    selectsegment.value = unref(stateFilterData).selectsegment;
  },
  {
    deep: true,
    immediate: false
  }
);

watch(fromDate, () => {
  if (unref(fromDate)) {
    fromDateTime.value = dayjs(unref(fromDate)).toDate();
  } else {
    fromDateTime.value = undefined;
  }
});

watch(toDate, () => {
  if (unref(toDate)) {
    toDateTime.value = dayjs(unref(toDate)).toDate();
  } else {
    toDateTime.value = undefined;
  }
});

/**
 * 画面起動時に必要なデータを取る
 */
async function getPlantsData() {
  const reqOptions = {
    method: "GET",
    url: "/api/mst/m_plants"
  };

  const response = await connectToAPI(reqOptions);
  let m_plants = response.data;

  plantsMaster.value = m_plants;
  editFilter();
}
/**
 * 画面起動時に必要なデータを取る
 */
async function getFirstData() {
  let firstdataAry = [];
  let i = 0;
  let tmpdata = {};
  do {
    const reqOptions = {
      method: "GET",
      url: "/api/orders",
      params: {
        limit: LIMIT_FIRST_DATA,
        offset: LIMIT_FIRST_DATA * i,
        date_from: unref(dataperiod).fromDate,
        date_to: unref(dataperiod).toDate
      }
    };

    const response = await connectToAPI(reqOptions);
    tmpdata = response.data;

    const formatter = item => {
      item.repeat_order_no = replaceUndefinedWithNA(item.repeat_order_no);
      item.sc_box_depth_input_value = convertToFixedPointNumberOrNA(
        item.sc_box_depth_input_value
      );
      item.sc_ds_slotter_input_value = convertToFixedPointNumberOrNA(
        item.sc_ds_slotter_input_value
      );
      item.sc_os_slotter_input_value = convertToFixedPointNumberOrNA(
        item.sc_os_slotter_input_value
      );
      item.sc_register_input_value = convertToFixedPointNumberOrNA(
        item.sc_register_input_value
      );
      item.dc_information = translateDcInformation(item.dc_information, i18n.t);
      item.flute_str = replaceInvalidFluteWithNA(item.flute_str);
      item.stop_count_per_vol = replaceUndefinedWithNA(item.stop_count_per_vol);
    };

    if (tmpdata.value !== undefined) {
      // テストが失敗するため、このチェックが必要。テストが失敗しないのであれば、外しても問題ないはず
      tmpdata.value.forEach(formatter);
    }

    firstdataAry = firstdataAry.concat(tmpdata.value);
    i++;
  } while (tmpdata.continue);

  firstdata.value = firstdataAry;
  isLoading.value = false;
}
/**
 * 画面起動時に必要なデータを取る
 * 比較画面用
 */
async function getProductionData() {
  const reqOptions = {
    method: "GET",
    url: "/api/aggvol",
    params: {
      date_from: unref(dataperiod).fromDate,
      date_to: unref(dataperiod).toDate
    }
  };
  const response = await connectToAPI(reqOptions);
  productiondata.value = response.data;
}
/**
 * 画面起動時に必要なデータを取る
 *
 * 生産時間分析用
 */
async function getProductionTimeData() {
  const reqOptions = {
    method: "GET",
    url: "/api/productiontime",
    params: {
      date_from: unref(dataperiod).fromDate,
      date_to: unref(dataperiod).toDate
    }
  };
  const response = await connectToAPI(reqOptions);
  productiontimedata.value = response.data;
}
/**
 * 画面起動時に必要なデータを取る
 *
 * 停止要因発生パート推移用
 */
async function getStopFactorData() {
  const reqOptions = {
    method: "GET",
    url: "/api/stopfactor",
    params: {
      date_from: unref(dataperiod).fromDate,
      date_to: unref(dataperiod).toDate
    }
  };
  const response = await connectToAPI(reqOptions);
  stopfactordata.value = response.data;
}
/**
 * datepicker操作時のメソッド
 */
function setFromToDate(type) {
  if (type === "fromDate") {
    fromDate.value = dayjs(unref(fromDateTime)).format("YYYY-MM-DD");
    fromMenu.value = false;

    //from入力時にtoがあったらチェック
    //入力したfromがtoより後ろだったらtoをclear
    if (unref(toDate)) {
      if (new Date(unref(toDate)) < new Date(unref(fromDate))) {
        toDate.value = null;
      }
    }
  }

  if (type === "toDate") {
    toDate.value = dayjs(unref(toDateTime)).format("YYYY-MM-DD");
    toMenu.value = false;

    //to入力時にfromがあったらチェック
    //入力したtoがfromより前だったらfromをclear
    if (unref(fromDate)) {
      if (new Date(unref(toDate)) < new Date(unref(fromDate))) {
        fromDate.value = null;
      }
    }
  }

  editFilter();
}
/**
 * フィルタ変更時のメソッド
 */
function editFilter(type) {
  if (
    type !== "region" &&
    type !== "customer" &&
    type !== "plant" &&
    type !== "alarm" &&
    type !== "component_note" &&
    type !== "measurement"
  )
    createRegions();
  if (
    type !== "customer" &&
    type !== "plant" &&
    type !== "alarm" &&
    type !== "component_note" &&
    type !== "measurement"
  )
    createCustomers();
  if (
    type !== "plant" &&
    type !== "alarm" &&
    type !== "component_note" &&
    type !== "measurement"
  )
    createPlants();
  if (type !== "alarm" && type !== "component_note" && type !== "measurement")
    createPlantIDs();
  if (type !== "alarm" && type !== "component_note" && type !== "measurement")
    createSegments();
  if (type !== "alarm" && type !== "component_note" && type !== "measurement")
    createAlarmtypes();
  if (type !== "component_note" && type !== "measurement")
    createComponentNotes();
  if (type !== "measurement") createMeasurements();

  stateFilterData.value = {
    selectregion: unref(selectregion),
    selectcustomer: unref(selectcustomer),
    selectplant: unref(selectplant),
    selectplantid: unref(selectplantid),
    from: unref(fromDate),
    to: unref(toDate),
    selectorderid: unref(selectorderid),
    selectorderno: unref(selectorderno),
    selectalarmType: unref(selectalarmType),
    selectcomponentNote: unref(selectcomponentNote),
    selectmeasurement: unref(selectmeasurement),
    selectsegment: unref(selectsegment)
  };
}
/**
 * リージョンの選択肢をつくるメソッド
 */
function createRegions() {
  let regionsarray = unref(plantsMaster).map(obj => obj.customer_region);
  regions.value = regionsarray.filter(
    (item, index, self) => self.indexOf(item) === index
  );
  selectregion.value = unref(selectregion).filter(
    item => unref(regions).indexOf(item) >= 0
  );
  // 選択肢が1つに制限されている場合は選択済み＆変更不可とする
  if (unref(authRegion).length === 1) {
    selectregion.value = unref(authRegion);
    enebleRegion.value = false;
  }
}
/**
 * カスタマーの選択肢をつくるメソッド
 */
function createCustomers() {
  const funcFilter = item => {
    return (
      unref(selectregion).length === 0 ||
      unref(selectregion).indexOf(item.customer_region) >= 0
    );
  };

  let filterddata = unref(plantsMaster).filter(funcFilter);
  let cusAry = filterddata.map(obj => obj.customer_name);
  customers.value = cusAry.filter(
    (item, index, self) => self.indexOf(item) === index
  );
  selectcustomer.value = unref(selectcustomer).filter(
    item => unref(customers).indexOf(item) >= 0
  );
  // 選択肢が1つに制限されている場合は選択済み＆変更不可とする
  if (unref(authCustomer).length === 1) {
    selectcustomer.value = unref(authCustomer);
    enebleCustomer.value = false;
  }
}
/**
 * プラントの選択肢をつくるメソッド
 */
function createPlants() {
  const funcFilter = item => {
    return (
      (unref(selectregion).length === 0 ||
        unref(selectregion).indexOf(item.customer_region) >= 0) &&
      (unref(selectcustomer).length === 0 ||
        unref(selectcustomer).indexOf(item.customer_name) >= 0)
    );
  };

  let filterddata = unref(plantsMaster).filter(funcFilter);
  let plAry = filterddata.map(obj => obj.city_plant_no);
  plants.value = plAry.filter(
    (item, index, self) => self.indexOf(item) === index
  );
  selectplant.value = unref(selectplant).filter(
    item => unref(plants).indexOf(item) >= 0
  );
  // 選択肢が1つに制限されている場合は選択済み＆変更不可とする
  if (unref(authPlant).length === 1) {
    selectplantid.value = unref(authPlant);
    //plantsMasterのなかからこのplant_id_dpacのデータを見つけてそれをselectplantにセットする
    //なかったらぶらんくにするしかない
    let flg = false;
    for (let pm of unref(plantsMaster)) {
      if (eqArrayEasily(pm.plant_id_dpac, unref(authPlant))) {
        selectplant.value = [pm.city_plant_no];
        flg = true;
        break;
      }
    }
    if (flg) {
      eneblePlant.value = false;
    }
  }
}
/**
 * 選択されたプラントを元に選択プラントIDをつくるメソッド
 */
function createPlantIDs() {
  const tmpFunc = (acc, value) => {
    if (unref(selectplant).indexOf(value.city_plant_no) >= 0) {
      if (acc.indexOf(value.plant_id_dpac) < 0) {
        acc.push(value.plant_id_dpac);
      }
    }
    return acc;
  };

  selectplantid.value = unref(plantsMaster).reduce(tmpFunc, []);
}
/**
 * segmentの選択肢をつくるメソッド
 */
function createSegments() {
  const funcFilter = item => {
    return (
      (unref(selectregion).length === 0 ||
        unref(selectregion).indexOf(item.region) >= 0) &&
      (unref(selectcustomer).length === 0 ||
        unref(selectcustomer).indexOf(item.customer) >= 0) &&
      (unref(selectplant).length === 0 ||
        unref(selectplant).indexOf(item.city_plant_no) >= 0) &&
      (!unref(toDate) ||
        new Date(item.prod_end_time_local) < new Date(unref(toDate))) &&
      (!unref(fromDate) ||
        new Date(item.prod_end_time_local) > new Date(unref(fromDate)))
    );
  };

  let filterddata = unref(firstdata).filter(funcFilter);
  let plAry = filterddata.map(obj => obj.segment);
  segments.value = plAry
    .filter((item, index, self) => self.indexOf(item) === index)
    .sort();
  selectsegment.value = unref(selectsegment).filter(
    item => unref(segments).indexOf(item) >= 0
  );
}
/**
 * アラームタイプの選択肢をつくるメソッド
 */
function createAlarmtypes() {
  let filterddata = structuredClone(toRaw(unref(alarms)));
  let plAry = filterddata.map(obj => obj.alarm_type);
  alarmTypes.value = plAry
    .filter((item, index, self) => self.indexOf(item) === index)
    .sort();
  selectalarmType.value = unref(selectalarmType).filter(
    item => unref(alarmTypes).indexOf(item) >= 0
  );
}
/**
 * コンポネントノートの選択肢をつくるメソッド
 */
function createComponentNotes() {
  const funcFilter = item => {
    return (
      unref(selectalarmType).length === 0 ||
      unref(selectalarmType).indexOf(item.alarm_type) >= 0
    );
  };

  let filterddata = structuredClone(toRaw(unref(alarms))).filter(funcFilter);
  let plAry = filterddata.map(obj => obj.component_note);
  componentNotes.value = plAry.filter(
    (item, index, self) => self.indexOf(item) === index
  );
  selectcomponentNote.value = unref(selectcomponentNote).filter(
    item => unref(componentNotes).indexOf(item) >= 0
  );
}
/**
 * メジャメントの選択肢をつくるメソッド
 */
function createMeasurements() {
  const funcFilter = item => {
    return (
      (unref(selectalarmType).length === 0 ||
        unref(selectalarmType).indexOf(item.alarm_type) >= 0) &&
      (unref(selectcomponentNote).length === 0 ||
        unref(selectcomponentNote).indexOf(item.component_note) >= 0)
    );
  };

  let filterddata = structuredClone(toRaw(unref(alarms))).filter(funcFilter);
  let plAry = filterddata.map(obj => obj.measurement);
  measurements.value = plAry.filter(
    (item, index, self) => self.indexOf(item) === index
  );
  selectmeasurement.value = unref(selectmeasurement).filter(
    item => unref(measurements).indexOf(item) >= 0
  );
}
function loadData() {
  isLoading.value = true;
  setDataAcquisitionPeriod();
  getPlantsData();
  getProductionData();
  getFirstData();
  getProductionTimeData();
  getStopFactorData();
}

function setDataAcquisitionPeriod() {
  let period = unref(storePeriod).period
    ? unref(storePeriod).period
    : unref(authPeriod).period;

  let fromDate, toDate;

  switch (period) {
    case "1w":
      fromDate = dayjs().subtract(1, "weeks").format("YYYY-MM-DD");
      toDate = dayjs().format("YYYY-MM-DD");
      break;
    case "1m":
      fromDate = dayjs().subtract(1, "months").format("YYYY-MM-DD");
      toDate = dayjs().format("YYYY-MM-DD");
      break;
    case "3m":
      fromDate = dayjs().subtract(3, "months").format("YYYY-MM-DD");
      toDate = dayjs().format("YYYY-MM-DD");
      break;
    case "6m":
      fromDate = dayjs().subtract(6, "months").format("YYYY-MM-DD");
      toDate = dayjs().format("YYYY-MM-DD");
      break;
    case "cu":
      if (unref(storePeriod).period) {
        fromDate = unref(storePeriod).fromDate;
        toDate = unref(storePeriod).toDate;
      } else {
        fromDate = unref(authPeriod).fromDate;
        toDate = unref(authPeriod).toDate;
      }
      break;
  }
  dataperiodStore.setDataperiod(period, fromDate, toDate);

  unref(dataperiod).period = period;
  unref(dataperiod).fromDate = fromDate;
  unref(dataperiod).toDate = toDate;
}

//state割り当て
selectregion.value = unref(stateFilterData).selectregion;
selectcustomer.value = unref(stateFilterData).selectcustomer;
selectplant.value = unref(stateFilterData).selectplant;
toDate.value = unref(stateFilterData).to;
fromDate.value = unref(stateFilterData).from;
selectorderid.value = unref(stateFilterData).selectorderid;
selectorderno.value = unref(stateFilterData).selectorderno;
selectalarmType.value = unref(stateFilterData).selectalarmType;
selectcomponentNote.value = unref(stateFilterData).selectcomponentNote;
selectmeasurement.value = unref(stateFilterData).selectmeasurement;
selectsegment.value = unref(stateFilterData).selectsegment;
</script>
