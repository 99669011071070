<template>
  <v-snackbar v-model="tempLoading" :timeout="-1" location="top">
    {{ i18n.t("others.message.loading") }}
    <v-progress-circular indeterminate />
  </v-snackbar>
</template>

<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  loading: { type: Boolean, default: false }
});

const i18n = useI18n();

const tempLoading = ref(prop.loading);

watch(
  () => prop.loading,
  newValue => {
    tempLoading.value = newValue;
  }
);
</script>
