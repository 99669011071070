import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@/auth";
import Top from "../views/Top.vue";
import Productivity from "../views/Productivity.vue";
import List from "../views/List.vue";
import Production from "../views/Production.vue";
import History from "../views/History.vue";
import Condition from "../views/Condition.vue";
import ErrorAnalysis from "../views/ErrorAnalysis.vue";
import User from "../views/User.vue";
import Realtime from "../views/Realtime.vue";
import ProductionTime from "../views/ProductionTime.vue";
import StopFactor from "../views/StopFactor.vue";
import Report from "../views/Report.vue";
import ReportList from "../views/ReportList.vue";
import ManualAdjustmentAmount from "../views/ManualAdjustmentAmount.vue";

const routes = [
  {
    path: "/",
    name: "Top",
    component: Top
  },
  {
    path: "/productivity",
    name: "productivity",
    component: Productivity
  },
  {
    path: "/list",
    name: "list",
    component: List
  },
  {
    path: "/production",
    name: "production",
    component: Production
  },
  {
    path: "/history",
    name: "history",
    component: History
  },
  {
    path: "/condition",
    name: "condition",
    component: Condition
  },
  {
    path: "/erroranalysis",
    name: "erroranalysis",
    component: ErrorAnalysis
  },
  {
    path: "/user",
    name: "user",
    component: User
  },
  {
    path: "/realtime",
    name: "realtime",
    component: Realtime
  },
  {
    path: "/productiontime",
    name: "productiontime",
    component: ProductionTime
  },
  {
    path: "/stopfactor",
    name: "stopfactor",
    component: StopFactor
  },
  {
    path: "/report",
    name: "report",
    component: Report
  },
  {
    path: "/report-list",
    name: "report-list",
    component: ReportList
  },
  {
    path: "/manualadjustmentamount",
    name: "manualadjustmentamount",
    component: ManualAdjustmentAmount
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});
router.beforeEach(authGuard);

export default router;
