<template>
  <v-card class="translucent" rounded="0">
    <v-toolbar density="compact" flat class="bg-transparent">
      <v-toolbar-title>
        {{ i18n.t("erroranalysis.table_title") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-data-table
      v-model:expanded="expanded"
      density="comfortable"
      :headers="errorlist_headers"
      hover
      :items="viewdata"
      item-value="errors"
      show-expand
      class="error-list"
      @update:expanded="if (expanded.length > 1) expanded.shift();"
    >
      <template #item="{ item, internalItem, toggleExpand, isExpanded }">
        <tr>
          <td>
            <v-btn
              density="comfortable"
              icon
              variant="text"
              @click="toggleExpand(internalItem)"
            >
              <v-icon>{{
                isExpanded(internalItem) ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </td>
          <td>
            {{ item[rankByType] }}
          </td>
          <td>
            <p v-for="(el, index) in item.errors" :key="index">
              {{ el }}
            </p>
          </td>

          <td>
            <p v-for="(el, index) in item.meaning" :key="index">
              {{ el }}
            </p>
          </td>
          <td>{{ item.stop_duration_min }}</td>
          <td>{{ item.count }}</td>
          <td>
            {{ Math.round((item.stop_duration_min / item.count) * 100) / 100 }}
          </td>
          <td>{{ Math.round(item[rateByType] * 100) }}%</td>
          <td>
            <p v-for="(el, index) in item.main_error" :key="index">
              {{ el }}
            </p>
          </td>
        </tr>
      </template>
      <template #expanded-row="{ item }">
        <tr class="expanded">
          <td class="expanded-td" :colspan="errorlist_headers.length + 1">
            <v-data-table
              density="compact"
              :headers="errordetail_headers"
              hover
              :items="convertToList(item)"
            >
              <template #bottom></template>
            </v-data-table>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  },
  chartType: {
    type: String,
    default: function () {
      return "errors";
    }
  }
});

const i18n = useI18n();

const errorlist_headers = ref([
  {
    title: "",
    width: "1%"
  },
  {
    title: i18n.t("erroranalysis.rank"),
    align: "start",
    key: "rank_count"
  },
  {
    title: i18n.t("erroranalysis.error_code"),
    align: "start",
    key: "errors"
  },
  {
    title: i18n.t("erroranalysis.meaning"),
    align: "start",
    key: "count"
  },
  {
    title: i18n.t("erroranalysis.stop_time"),
    align: "start",
    key: "stop_duration_min"
  },
  {
    title: i18n.t("erroranalysis.stop_count"),
    align: "start",
    key: "count"
  },
  {
    title: i18n.t("erroranalysis.ave_stop_time"),
    align: "start",
    key: "count"
  },
  {
    title: i18n.t("erroranalysis.rate"),
    align: "start",
    key: "rate_count"
  },
  {
    title: i18n.t("erroranalysis.main_error"),
    align: "start",
    key: "main_error"
  }
]);
const errordetail_headers = ref([
  {
    title: i18n.t("erroranalysis.error_code"),
    key: "errors"
  },
  {
    title: i18n.t("erroranalysis.meaning"),
    key: "meaning"
  },
  {
    title: i18n.t("erroranalysis.detect"),
    key: "detect_condition"
  },
  {
    title: i18n.t("erroranalysis.countermeasure"),
    key: "countermeasure"
  },
  {
    title: i18n.t("erroranalysis.cause"),
    key: "cause_of_occurrence"
  }
]);
const expanded = ref([]);

const rankByType = computed(() => {
  return prop.chartType === "errors" ? "rank_count" : "rank_duration";
});
const rateByType = computed(() => {
  return prop.chartType === "errors" ? "rate_count" : "rate_duration";
});

function convertToList(item) {
  return [item];
}
</script>

<style scoped>
.expanded {
  vertical-align: top;
  box-shadow: inset 0px 0px 20px -5px rgba(50, 50, 50, 1);
}
.expanded-td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.v-data-table {
  white-space: pre-line;
}
:deep(.error-list.v-data-table table thead tr th) {
  font-size: 12px;
  font-weight: 700;
}
</style>
