import { connectToAPI } from "@/helpers/util";

/**
 * 生産時間情報を含んだオーダー情報を取得APIを実行する
 * @param plantIdDpac 選択した工場のplant_id_dpac
 * @param targetDate 選択した棒グラフの日付（yyyy−mm−dd形式）
 * @param timeType 選択した時間集計区分（day or month or week）
 * @param alertObj エラー時のアラート情報
 */
export async function getProductiontimeOrder(
  plantIdDpac: string,
  targetDate: string,
  timeType: string
) {
  const reqOptions = {
    method: "GET",
    url: "/api/productiontime/orders",
    params: {
      plant_id_dpac: plantIdDpac,
      target_date: targetDate,
      time_type: timeType
    }
  };

  const response = await connectToAPI(reqOptions);

  return response.data;
}
