import { connectToAPI } from "@/helpers/util";

/**
 * エラーマスタ取得APIを実行する
 * @param errorMaster APIで取得したエラーマスタデータ
 * @param language ユーザーの言語設定
 * @param alertObj エラー時のアラート情報
 */
export async function getErrorMaster(
  errorMaster: [],
  language: string,
  alertObj: {
    flg: boolean;
    text: string;
  }
) {
  if (errorMaster.length > 0) {
    return;
  }

  const reqOptions = {
    method: "GET",
    url: "/api/mst/m_errors",
    params: {
      lang: language
    }
  };
  const response = await connectToAPI(reqOptions, alertObj);

  return response.data;
}
