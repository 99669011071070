import { connectToAPI } from "@/helpers/util";

/**
 * オーダーの生産データ取得APIを実行する
 * @param order_id 散布図で選択したオーダーのオーダーID
 * @param alertObj エラー時のアラート情報
 */
export async function getOrderProd(
  order_id: string,
  alertObj: {
    flg: boolean;
    text: string;
  }
) {
  const reqOptions = {
    method: "GET",
    url: "/api/orderprod",
    params: { order: order_id }
  };

  const response = await connectToAPI(reqOptions, alertObj);

  return response.data;
}
