import { defineStore } from "pinia";
import { ref } from "vue";

/**
 * フィルター情報
 */
export const useFilterDataStore = defineStore(
  "filterData",
  () => {
    const filterData = ref({
      selectregion: [],
      selectcustomer: [],
      selectplant: [],
      selectplantid: [],
      from: null,
      to: null,
      selectorderid: "",
      selectorderno: "",
      selectalarmType: [],
      selectcomponentNote: [],
      selectmeasurement: [],
      selectsegment: []
    });

    return {
      filterData
    };
  },
  {
    persist: {
      key: "FilterData",
      paths: ["filterData"],
      storage: window.localStorage
    }
  }
);
