<template>
  <v-snackbar
    v-model="tempAlartObj.flg"
    timeout="5000"
    location="top right"
    color="error"
  >
    {{ tempAlartObj.text }}
    <template #actions>
      <v-btn variant="text" @click="tempAlartObj.flg = false">
        <v-icon size="x-large">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  alartObj: {
    type: Object,
    default: function () {
      const i18n = useI18n();
      return { flg: false, text: i18n.t("others.alart.apierror") };
    }
  }
});

const tempAlartObj = ref({ ...prop.alartObj });

watch(
  () => prop.alartObj,
  newValue => {
    tempAlartObj.value = { ...newValue };
  },
  {
    deep: true
  }
);
</script>
