import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import pinia from "./stores";
import vuetify from "./plugins/vuetify";

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(vuetify);

// Google Analytics GA4の設定
import VueGtag from "vue-gtag";
app.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID
    }
  },
  router
);

import { datadogRum } from "@datadog/browser-rum";
datadogRum.init({
  applicationId: "a803d215-0da7-48d6-a62c-00f35cc67668",
  clientToken: "pub9985015ecd634a8030f179487b8c745c",
  site: "datadoghq.com",
  service: "ppm-iot-cms",
  env: import.meta.env.VITE_APP_MODE,
  version: __PACKAGE_VERSION__,
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask"
});
datadogRum.startSessionReplayRecording();

import { createI18n } from "vue-i18n";

import sidebar from "./assets/translation/sidebar.json";
import productivity from "./assets/translation/productivity.json";
import production from "./assets/translation/production.json";
import condition from "./assets/translation/condition.json";
import orderlist from "./assets/translation/orderlist.json";
import history from "./assets/translation/history.json";
import erroranalysis from "./assets/translation/erroranalysis.json";
import user from "./assets/translation/user.json";
import others from "./assets/translation/others.json";
import releasenote from "./assets/translation/releasenote.json";
import productiontime from "./assets/translation/productiontime.json";
import stopfactor from "./assets/translation/stopfactor.json";
import report from "./assets/translation/report.json";
import report_unit from "./assets/translation/report_unit.json";
import stopfactor_error from "./assets/translation/stopfactor_error.json";
import stopfactor_unit from "./assets/translation/stopfactor_unit.json";
import frequentstops from "./assets/translation/frequentstops.json";
import manual_adjustment_amount from "./assets/translation/manual_adjustment_amount.json";

const i18n = createI18n({
  legacy: false,
  locale: "en",
  messages: {
    en: {
      sidebar: sidebar.en,
      productivity: productivity.en,
      production: production.en,
      condition: condition.en,
      orderlist: orderlist.en,
      history: history.en,
      erroranalysis: erroranalysis.en,
      user: user.en,
      others: others.en,
      releasenote: releasenote.en,
      productiontime: productiontime.en,
      stopfactor: stopfactor.en,
      report: report.en,
      report_unit: report_unit.en,
      stopfactor_error: stopfactor_error.en,
      stopfactor_unit: stopfactor_unit.en,
      frequentstops: frequentstops.en,
      manual_adjustment_amount: manual_adjustment_amount.en
    },
    ja: {
      sidebar: sidebar.ja,
      productivity: productivity.ja,
      production: production.ja,
      condition: condition.ja,
      orderlist: orderlist.ja,
      history: history.ja,
      erroranalysis: erroranalysis.ja,
      user: user.ja,
      others: others.ja,
      releasenote: releasenote.ja,
      productiontime: productiontime.ja,
      stopfactor: stopfactor.ja,
      report: report.ja,
      report_unit: report_unit.ja,
      stopfactor_error: stopfactor_error.ja,
      stopfactor_unit: stopfactor_unit.ja,
      frequentstops: frequentstops.ja,
      manual_adjustment_amount: manual_adjustment_amount.ja
    }
  },
  warnHtmlMessage: false
});
app.use(i18n);

import { flashToast } from "@alamtheinnov/flashtoast";
import "@alamtheinnov/flashtoast/dist/flashtoast.css";
app.use(flashToast);

app.mount("#app");
