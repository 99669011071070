<template>
  <v-overlay
    contained
    :model-value="isLoading"
    class="align-center justify-center text-white"
  >
    <v-progress-circular indeterminate />
    {{ loadingText }}
  </v-overlay>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: false
  },
  textKey: {
    type: String,
    default: "others.message.loading"
  }
});

const i18n = useI18n();
const loadingText = i18n.t(props.textKey);
</script>
