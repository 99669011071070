<template>
  <v-card rounded="0" height="100%">
    <v-toolbar density="compact" flat class="bg-transparent">
      <v-toolbar-title>
        {{ i18n.t("erroranalysis.pareto_chart") }}
      </v-toolbar-title>
    </v-toolbar>
    <highcharts :options="chartOptions" />
  </v-card>
</template>

<script setup>
import { Chart as highcharts } from "highcharts-vue";
import { computed, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  },
  chartType: {
    type: String,
    default: function () {
      return "errors";
    }
  }
});

const i18n = useI18n();

const chartOptions = ref({
  chart: {
    renderTo: "container",
    type: "column",
    backgroundColor: "transparent"
  },
  title: {
    text: ""
  },
  tooltip: {
    shared: true,
    //thisにポイントデータがくる
    formatter: function () {
      return (
        this.x +
        "<br>" +
        this.points[0].series.name +
        ": " +
        this.points[0].y +
        "<br>" +
        this.points[1].series.name +
        ": " +
        Math.round(this.points[1].y) +
        "%"
      );
    }
  },
  xAxis: {
    categories: [],
    crosshair: true
  },
  yAxis: [
    {
      title: {
        text: ""
      }
    },
    {
      title: {
        text: ""
      },
      minPadding: 0,
      maxPadding: 0,
      max: 100,
      min: 0,
      endOnTick: false,
      tickAmount: 2,
      opposite: true,
      labels: {
        format: "{value}%"
      }
    }
  ],
  credits: { enabled: false },
  series: []
});

const contentByType = computed(() => {
  return prop.chartType === "errors" ? "count" : "stop_duration_min";
});
const unitByType = computed(() => {
  return prop.chartType === "errors" ? "count" : "min";
});
const rateByType = computed(() => {
  return prop.chartType === "errors" ? "rate_count" : "rate_duration";
});

watch(
  () => prop.viewdata,
  () => {
    renderChart();
  }
);

function renderChart() {
  unref(chartOptions).series = [];
  const xAxis = [];
  const pareto = [];
  let ratesum = 0;

  const content = prop.viewdata.map(el => {
    ratesum += el[unref(rateByType)] * 100;
    xAxis.push(el.errors);
    pareto.push(ratesum);
    return el[unref(contentByType)];
  });
  content.sort((a, b) => b - a);

  //小数点計算でたまに最後が100.01%など100％こえちゃうことがあるので無理やり修正する
  if (pareto[pareto.length - 1] > 100) {
    pareto[pareto.length - 1] = 100;
  }

  unref(chartOptions).series.push(
    {
      type: "column",
      name: i18n.t("erroranalysis." + prop.chartType),
      yAxis: 0,
      zIndex: 0,
      data: content
    },
    {
      type: "line",
      name: i18n.t("erroranalysis.pareto"),
      color: "#f7a35c",
      yAxis: 1,
      zIndex: 1,
      data: pareto
    }
  );

  unref(chartOptions).xAxis.categories = xAxis;
  unref(chartOptions).yAxis[0].title.text = i18n.t(
    "erroranalysis." + unref(unitByType)
  );
}
</script>
