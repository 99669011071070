import { defineStore } from "pinia";
import { ref } from "vue";

export const useErrorMasterStore = defineStore("errorMaster", () => {
  const errorMaster = ref([]);

  return {
    errorMaster
  };
});
