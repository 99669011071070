<template>
  <div v-if="darkmode">
    <v-col class="pa-0">
      <v-btn
        v-for="button in buttons"
        :key="button"
        :class="['toggle-button-dark', { active: currentButton === button }]"
        :disabled="disabled"
        size="small"
        rounded
        variant="flat"
        class="mr-3"
        @click="selectButton(button)"
      >
        {{ i18n.rt(i18n.tm("others.button")[button]) }}
      </v-btn>
    </v-col>
  </div>
  <div v-else>
    <v-col class="pa-0">
      <v-btn
        v-for="button in buttons"
        :key="button"
        :class="['toggle-button', { active: currentButton === button }]"
        :disabled="disabled"
        size="small"
        rounded
        variant="flat"
        class="mr-3"
        @click="selectButton(button)"
      >
        {{ i18n.rt(i18n.tm("others.button")[button]) }}
      </v-btn>
    </v-col>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { usePreference } from "@/stores/preference";

const prop = defineProps({
  contents: {
    type: Array,
    default: function () {
      return [];
    }
  },
  default: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["select-button"]);

const i18n = useI18n();
const preferenceStore = usePreference();

const { darkmode } = storeToRefs(preferenceStore);
const currentButton = ref(prop.default);

const buttons = computed(() => {
  return prop.contents;
});

watch(
  () => prop.default,
  () => {
    selectButton(prop.default);
  }
);

function selectButton(button) {
  currentButton.value = button;
  emit("select-button", unref(currentButton));
}
</script>
<style scoped>
.toggle-button {
  background: #f0f0f0;
  color: black !important;
}
.toggle-button.active {
  background: #cfd8dc !important;
  color: black !important;
}
.toggle-button-dark {
  background: #666666 !important;
  color: black !important;
}
.toggle-button-dark.active {
  background: white !important;
  color: black !important;
}
</style>
