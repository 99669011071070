<template>
  <v-container class="pa-3" fluid>
    <loading-bar :loading="isLoading" />
    <v-alert v-if="isErrored" type="warning">
      {{ i18n.t("report.common.error_msg") }}
      <v-btn color="black" @click="requestListReports">{{
        i18n.t("report.common.re_request")
      }}</v-btn>
    </v-alert>
    <v-row>
      <v-col cols="12" class="report-list-title-col">
        <div class="list-title">
          <v-select
            v-model="plant_id_dpac"
            density="comfortable"
            :items="plant_select_items"
            item-title="text"
            item-value="value"
            variant="solo"
            @update:model-value="requestListReports"
          />
        </div>
        <div class="list-title">
          <h2>{{ i18n.t("report.report_list.title") }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="report-list"
          class="elevation-1"
          density="comfortable"
          hover
          :items="reports"
          :items-per-page="10"
          :headers="headers"
        >
          <template #item.yyyymm="{ item }">
            <router-link
              :to="{
                path: '/report',
                query: { plant_id_dpac: plant_id_dpac, yyyymm: item.yyyymm }
              }"
              style="text-decoration: none"
            >
              {{
                dayjs(item.yyyymm).format(
                  i18n.t("report.report_list.report_month")
                )
              }}
            </router-link>
          </template>
          <template #item.fault_unit_count="{ item }">
            <span :class="{ having: item.fault_unit_count > 0 }">
              {{
                item.fault_unit_count == null
                  ? ""
                  : `${item.fault_unit_count}${i18n.t("report.report_list.count_unit")}`
              }}
            </span>
          </template>
          <template #item.repair_unit_count="{ item }">
            <span :class="{ having: item.repair_unit_count > 0 }">
              {{
                item.repair_unit_count == null
                  ? ""
                  : `${item.repair_unit_count}${i18n.t("report.report_list.count_unit")}`
              }}
            </span>
          </template>
          <template #item.avg_order_productivity="{ item }">
            {{
              item.avg_order_productivity == null
                ? ""
                : `${item.avg_order_productivity.toFixed(0)}%`
            }}
          </template>
          <template #item.sum_prod_volume="{ item }">
            {{
              item.sum_prod_volume == null
                ? ""
                : `${item.sum_prod_volume.toLocaleString(undefined)}${i18n.t("report.report_list.volume_unit")}`
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="js">
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, onMounted, onBeforeUnmount, ref, unref } from "vue";
import { useI18n } from "vue-i18n";

import LoadingBar from "@/components/parts/LoadingBar.vue";
import { connectToAPI } from "@/helpers/util";
import { useDrawerFlgStore } from "@/stores/drawerFlg";
import { usePreference } from "@/stores/preference";

import ReportTargetPlants from "@/assets/report_target_plants.json";

const i18n = useI18n();
const drawerFlgStore = useDrawerFlgStore();
const preferenceStore = usePreference();

const { drawerFlg } = storeToRefs(drawerFlgStore);
const { darkmode } = storeToRefs(preferenceStore);
const isLoading = ref(true);
const prevDarkMode = ref(false);
const isErrored = ref(false);
const plant_id_dpac = ref(null);
const plants = ref([]);
const reports = ref([]);
const headers = ref([
  {
    title: i18n.t("report.report_list.headers.report_month"),
    value: "yyyymm",
    width: "10%"
  },
  {
    title: i18n.t("report.report_list.headers.fault_unit"),
    value: "fault_unit_count",
    align: "end",
    width: "15%"
  },
  {
    title: i18n.t("report.report_list.headers.repair_unit"),
    value: "repair_unit_count",
    align: "end",
    width: "15%"
  },
  {
    title: i18n.t("report.report_list.headers.avg_order_productivity"),
    value: "avg_order_productivity",
    align: "end",
    width: "15%"
  },
  {
    title: i18n.t("report.report_list.headers.sum_prod_volume"),
    value: "sum_prod_volume"
  }
]);

/**
 * レポート一覧のAPIを叩くときのQueryStringを生成する
 * @return {{}|{plant_id_dpac: string}}
 */
const createListReportsQuery = computed(() => {
  return unref(plant_id_dpac) == null
    ? {}
    : { plant_id_dpac: unref(plant_id_dpac) };
});
/**
 * プラント選択用にselect boxの要素を算出する
 * @return {{text: string, value: string}[]}
 */
const plant_select_items = computed(() => {
  // 定義された5プラントの情報を抽出
  const targetPlantData = unref(plants).filter(item =>
    ReportTargetPlants.includes(item.plant_id_dpac)
  );
  return targetPlantData.map(item => {
    return {
      value: item.plant_id_dpac,
      text: `${item.customer_name} ${item.plant_id_dashboard}`
    };
  });
});

onMounted(async () => {
  // フィルタを閉じる
  drawerFlg.value = false;

  // 強制的にDarkModeを解除
  prevDarkMode.value = unref(darkmode);
  darkmode.value = false;

  await requestListReports();
});

onBeforeUnmount(() => {
  // 他画面に遷移時にフィルタを復活
  drawerFlg.value = true;

  // DarkModeの設定をレストア
  darkmode.value = unref(prevDarkMode);
});

/**
 * 表示しているデータをクリアする
 */
function clear() {
  reports.value = [];
}
/**
 * レポート一覧を取得する
 */
async function requestListReports() {
  isLoading.value = true;
  isErrored.value = false;
  try {
    const response = await connectToAPI({
      method: "GET",
      url: "/api/list-reports",
      params: unref(createListReportsQuery)
    });
    reports.value = response.data.reports;
    plants.value = response.data.plants;
    plant_id_dpac.value = response.data.plant_id_dpac;
  } catch (e) {
    isErrored.value = true;
    clear();
  } finally {
    isLoading.value = false;
  }
}
</script>

<style>
div.report-list-title-col {
  display: flex;
  align-items: flex-start;
}

div.list-title {
  display: inline-block;
  margin-left: 0.5rem;
}

#report-list .v-data-table__th {
  background-color: rgba(243, 243, 244, 10);
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}

span.having {
  color: red;
}
</style>
