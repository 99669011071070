import { defineStore } from "pinia";
import { ref } from "vue";

/**
 * 想定する言語/ロケール
 */
export type ValidLanguage = "en" | "ja";

/**
 * 想定する期間
 */
export type ValidPeriod = "1w" | "1m" | "3m" | "6m" | "cu";

/**
 * ユーザー設定情報を保持するStore
 */
export const usePreference = defineStore("preference", () => {
  const isNotInitialized = ref(true);
  const language = ref<ValidLanguage>("en");
  const darkmode = ref(false);
  const dateFrom = ref("");
  const dateTo = ref("");
  const period = ref<ValidPeriod>("3m");
  const unitInch = ref(true);

  /**
   * Storeの初期化を行うために実行する
   */
  function initialize(props: {
    language: ValidLanguage;
    darkmode: boolean;
    dateFrom: string;
    dateTo: string;
    period: ValidPeriod;
    unitInch: boolean;
  }) {
    isNotInitialized.value = false;
    language.value = props.language;
    darkmode.value = props.darkmode;
    dateFrom.value = props.dateFrom;
    dateTo.value = props.dateTo;
    period.value = props.period;
    unitInch.value = props.unitInch;
  }

  return {
    isNotInitialized,
    language,
    darkmode,
    dateFrom,
    dateTo,
    period,
    unitInch,
    initialize
  };
});
