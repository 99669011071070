import { defineStore } from "pinia";
import { ref } from "vue";

/**
 * drawerの制御
 */
export const useDrawerFlgStore = defineStore("drawerFlg", () => {
  const drawerFlg = ref(true);

  return {
    drawerFlg
  };
});
