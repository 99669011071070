<template>
  <v-card rounded="0" height="100%">
    <v-toolbar density="compact" flat class="bg-transparent">
      <v-toolbar-title>
        {{ i18n.t("manual_adjustment_amount.bar_chart") }}
      </v-toolbar-title>
    </v-toolbar>
    <highcharts ref="chartRef" :options="chartOptions" />
  </v-card>
</template>

<script setup>
import { Chart as highcharts } from "highcharts-vue";
import { ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useSelectedGraphStyle } from "@/composables/useSelectedGraphStyle";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  }
});
const emit = defineEmits(["clickTargetComponentNote"]);

const i18n = useI18n();
const selectedGraphStyle = useSelectedGraphStyle();
let clickedPointId = null;
const chartRef = ref(null);

const chartOptions = ref({
  chart: {
    renderTo: "container",
    type: "column",
    backgroundColor: "transparent"
  },
  title: {
    text: ""
  },
  tooltip: {
    shared: true,
    //thisにポイントデータがくる
    formatter: function () {
      return (
        this.x + "<br>" + this.points[0].series.name + ": " + this.points[0].y
      );
    }
  },
  xAxis: {
    categories: [],
    crosshair: true
  },
  legend: {
    enabled: false
  },
  yAxis: [
    {
      title: {
        text: ""
      }
    }
  ],
  credits: { enabled: false },
  series: [],
  plotOptions: {
    series: {
      cursor: "pointer",
      events: {
        click: function (e) {
          pointClickAndUpdateStyle(e, this.chart);
          emitSelectedComponentNoteInfo(e);
        }
      }
    }
  }
});
const process = ref([]);

watch(
  () => prop.viewdata,
  () => {
    renderChart();
  }
);

function renderChart() {
  const chart = chartRef.value?.chart;
  resetPreviousPointStyle(chart);

  unref(chartOptions).series = [];
  const xAxis = [];

  process.value = [];

  const content = prop.viewdata.map(el => {
    xAxis.push(el.component_note);
    unref(process).push(el.process);
    return el["exceed_tolerance_count"];
  });

  unref(chartOptions).series.push({
    type: "column",
    name: i18n.t("manual_adjustment_amount.exceed_counts"),
    yAxis: 0,
    zIndex: 0,
    data: content
  });

  unref(chartOptions).xAxis.categories = xAxis;
  unref(chartOptions).yAxis[0].title.text = i18n.t(
    "manual_adjustment_amount.exceed_counts_yaxis_label"
  );
}

function resetPreviousPointStyle(chart) {
  if (clickedPointId && chart) {
    const prevPoint = chart.get(clickedPointId);
    if (prevPoint) {
      prevPoint.update({ borderColor: null, borderWidth: 0 });
    }
  }
}

function pointClickAndUpdateStyle(event, chart) {
  resetPreviousPointStyle(chart);

  event.point.update({
    borderColor: selectedGraphStyle.borderColor.value,
    borderWidth: selectedGraphStyle.borderWidth
  });
  clickedPointId = event.point.id;
}

function emitSelectedComponentNoteInfo(event) {
  const selectComponentNoteInfo = {
    process: unref(process)[event.point.x],
    component_note: event.point.category,
    showFlag: true
  };
  emit("clickTargetComponentNote", selectComponentNoteInfo);
}
</script>
