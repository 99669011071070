import { defineStore } from "pinia";
import { ref } from "vue";

export const useAlarmdetailStore = defineStore("alarmdetail", () => {
  const alarmdetail = ref([]);

  return {
    alarmdetail
  };
});
