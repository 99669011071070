<template>
  <v-container class="pa-0" fluid>
    <alart-snack-bar :alart-obj="alartObj" />
    <loading-bar :loading="isdataloading" />
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-toolbar class="bg-transparent" elevation="4">
          <toggle-buttons
            :contents="typeButtons"
            :default="'errors'"
            class="px-4"
            @select-button="changeType"
          />
          <v-spacer />
        </v-toolbar>
      </v-col>
    </v-row>

    <v-alert v-if="errored" type="warning">
      {{ i18n.t("erroranalysis.error_msg") }}
    </v-alert>

    <v-row v-if="showTopMessage" class="ma-0">
      <v-col class="pa-4 pb-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{
            i18n.t("erroranalysis.message")
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0 height">
      <v-col class="pa-4 pr-2 pb-0" cols="6">
        <pareto-chart :viewdata="overviewDisp" :chart-type="chartType" />
      </v-col>
      <v-col class="pa-4 pl-2 pb-0" cols="6">
        <timeseries :viewdata="timeseriesDisp" :chart-type="chartType" />
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col class="pa-4">
        <error-table :viewdata="tableDataDisp" :chart-type="chartType" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import ParetoChart from "@/components/erroranalysis/ParetoChart.vue";
import Timeseries from "@/components/erroranalysis/Timeseries.vue";
import ErrorTable from "@/components/erroranalysis/ErrorTable.vue";
import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import { connectToAPI } from "@/helpers/util";
import { useErrorMasterStore } from "@/stores/errorMaster";
import { useFilterDataStore } from "@/stores/filterData";
import { usePreference } from "@/stores/preference";

const i18n = useI18n();
const errorMasterStore = useErrorMasterStore();
const filterDataStore = useFilterDataStore();
const preferenceStore = usePreference();

const { errorMaster } = storeToRefs(errorMasterStore);
const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { language } = storeToRefs(preferenceStore);
const alartObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});
const typeButtons = ref(["errors", "stop_time"]);
const chartType = ref("errors");
const overview = ref([]);
const overviewCount = ref([]);
const overviewDuration = ref([]);
const timeseriesCount = ref([]);
const timeseriesDuration = ref([]);
const timeseriesList = ref([]);
const tableDataCount = ref([]);
const tableDataDuration = ref([]);
const isdataloading = ref(false);
const errored = ref(false);
const showTopMessage = ref(true);
const clearAllData = ref(true);

const overviewDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(chartType) === "errors"
    ? unref(overviewCount)
    : unref(overviewDuration);
});
const timeseriesDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(chartType) === "errors"
    ? unref(timeseriesCount)
    : unref(timeseriesDuration);
});
const tableDataDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(chartType) === "errors"
    ? unref(tableDataCount)
    : unref(tableDataDuration);
});

watch(
  stateFilterData,
  async value => {
    if (value.selectplantid.length !== 1 || !value.from || !value.to) {
      showTopMessage.value = true;
      clearAllData.value = true;
      return;
    }
    isdataloading.value = true;
    errored.value = false;
    showTopMessage.value = false;
    clearAllData.value = false;

    await Promise.all([getOverview(), getTimeseriesList(), getErrorMaster()])
      .catch(() => {
        isdataloading.value = false;
        errored.value = true;
      })
      .then(() => {
        prepareData();
        isdataloading.value = false;
      });
  },
  {
    deep: true,
    immediate: true
  }
);

async function getOverview() {
  const reqOptions = {
    method: "GET",
    url: "/api/erroranalysis/overview",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to
    }
  };
  await connectToAPI(reqOptions, unref(alartObj))
    .catch(error => {
      throw error;
    })
    .then(response => {
      overview.value = response.data;
    });
}
async function getTimeseriesList() {
  const reqOptions = {
    method: "GET",
    url: "/api/erroranalysis/timeseries",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to
    }
  };
  await connectToAPI(reqOptions, unref(alartObj))
    .catch(error => {
      throw error;
    })
    .then(response => {
      timeseriesList.value = response.data;
    });
}
async function getErrorMaster() {
  if (unref(errorMaster).length > 0) {
    return;
  }

  const reqOptions = {
    method: "GET",
    url: "/api/mst/m_errors",
    params: {
      lang: unref(language)
    }
  };
  await connectToAPI(reqOptions, unref(alartObj))
    .catch(error => {
      throw error;
    })
    .then(response => {
      errorMaster.value = response.data;
    });
}
function prepareData() {
  overviewCount.value = unref(overview)
    .filter(el => el.rank_count < 21)
    .sort((a, b) => a.rank_count - b.rank_count)
    .slice(0, 20);

  overviewDuration.value = unref(overview)
    .filter(el => el.rank_duration < 21)
    .sort((a, b) => a.rank_duration - b.rank_duration)
    .slice(0, 20);
  const createTableData = overview => {
    return overview.map(el => {
      const mst = unref(errorMaster).find(
        mst => JSON.stringify(mst.errorcode) === JSON.stringify(el.errors)
      );
      return {
        ...el,
        meaning: mst?.meaning,
        detect_condition: mst?.detect_condition,
        cause_of_occurrence: mst?.cause_of_occurrence,
        main_error: mst?.main_error,
        countermeasure: mst?.countermeasure
      };
    });
  };

  const createTimeseries = overview => {
    return unref(timeseriesList)
      .map(ts => {
        const found = overview.find(
          ov => JSON.stringify(ov.errors) === JSON.stringify(ts.errors)
        );
        if (found) {
          return {
            ...ts,
            rank_count: found.rank_count,
            rank_duration: found.rank_duration
          };
        }
      })
      .filter(el => el);
  };

  tableDataCount.value = createTableData(unref(overviewCount));
  tableDataDuration.value = createTableData(unref(overviewDuration));
  timeseriesCount.value = createTimeseries(unref(overviewCount));
  timeseriesDuration.value = createTimeseries(unref(overviewDuration));
}
function changeType(clickedButton) {
  chartType.value = clickedButton;
}
</script>
