<template>
  <div>
    <v-row class="mx-2 mt-2 mb-0">
      <v-col class="pa-2" xl="3" lg="3" md="3" sm="6" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{ i18n.t("production.order") }}</v-toolbar-title>
          </v-toolbar>
          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="orderDonut"
          />
        </v-card>
      </v-col>

      <v-col class="pa-2" xl="3" lg="3" md="3" sm="6" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{ i18n.t("production.volume") }}</v-toolbar-title>
          </v-toolbar>
          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="volDonut"
          />
        </v-card>
      </v-col>

      <v-col class="pa-2" xl="3" lg="3" md="3" sm="6" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("production.volume_size")
            }}</v-toolbar-title>
          </v-toolbar>
          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="volSizeDonut"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { Chart as highcharts } from "highcharts-vue";
import { storeToRefs } from "pinia";
import { computed, ref, toRaw, watch } from "vue";
import { useI18n } from "vue-i18n";

import { convertUnit } from "@/helpers/util";
import { usePreference } from "@/stores/preference";

const prop = defineProps({
  viewdata: {
    type: Object,
    default: function () {
      return {};
    }
  },
  colors: {
    type: Object,
    default: function () {
      return {};
    }
  }
});

const i18n = useI18n();
const preferenceStore = usePreference();

const { unitInch } = storeToRefs(preferenceStore);
const donutsObjs = ref({
  labels: [],
  Order: {
    donutData: {}
  },
  Volume: {
    donutData: {}
  },
  "Volume x Size": {
    donutData: {}
  }
});

const orderDonut = ref({});
const volDonut = ref({});
const volSizeDonut = ref({});
const defaultOptions = ref({
  chart: {
    className: "donut-chart",
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    height: "300px",
    backgroundColor: "transparent"
  },
  title: {
    useHTML: true,
    text: "",
    align: "center",
    verticalAlign: "middle",
    y: 30,
    style: { fontFamily: "Roboto", fontSize: "28px" }
  },
  subtitle: {
    useHTML: true,
    fontFamily: "Roboto",
    text: "",
    align: "center",
    verticalAlign: "middle",
    fontSize: "14px",
    y: -20,
    style: { fontFamily: "Roboto" }
  },
  tooltip: {
    pointFormat: "{point.y:,.0f}"
  },
  credits: {
    text: ""
  },
  accessibility: {
    point: {
      valueSuffix: "%"
    }
  },
  plotOptions: {
    pie: {
      borderWidth: "0",
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: "allow",
        verticalAlign: "middle",
        x: 0,
        y: -5,
        useHTML: true,
        format: "{point.y:,.0f}<br>{point.percentage:.0f} %",
        distance: -20,
        style: {
          fontFamily: "Roboto",
          fontSize: "67%",
          fontWeight: "none",
          color: "white",
          textOutline: "none"
        }
      },
      startAngle: 0,
      endAngle: 360,
      center: ["50%", "53%"],
      size: "110%"
    }
  },
  series: [
    {
      type: "pie",
      name: "",
      innerSize: "65%",
      data: []
    }
  ]
});

const orderTotal = computed(() => {
  return donutsObjs.value.Order.donutData.datasets[0].data.reduce(
    (a, x) => a + x,
    0
  );
});
const volTotal = computed(() => {
  return donutsObjs.value.Volume.donutData.datasets[0].data.reduce(
    (a, x) => a + x,
    0
  );
});
const volSizeTotal = computed(() => {
  const volSizeData = donutsObjs.value["Volume x Size"].donutData.datasets[0];
  const volSizeTotal = volSizeData.data.reduce((a, x) => a + x, 0);
  return convertUnit(volSizeTotal, unitInch.value, true);
});

watch(
  () => prop.viewdata,
  value => {
    if (Object.keys(value).length > 0) {
      createDonutData();
      pushData();
    }
  },
  {
    immediate: true
  }
);

function createDonutData() {
  const plantData = Object.values(prop.viewdata)
    .flat()
    .reduce((acc, item) => {
      const { plant_id_dpac, city_plant_no, order_count, sum, vxs } = item;
      if (!acc[plant_id_dpac]) {
        acc[plant_id_dpac] = {
          city_plant_no,
          count: 0,
          volume: 0,
          volume_size: 0
        };
      }
      acc[plant_id_dpac].count += order_count;
      acc[plant_id_dpac].volume += sum;
      acc[plant_id_dpac].volume_size += vxs;

      return acc;
    }, {});

  donutsObjs.value.labels = Object.values(plantData).map(p => p.city_plant_no);
  const orderData = Object.values(plantData).map(p => p.count);
  const volumeData = Object.values(plantData).map(p => p.volume);
  const volumeSizeData = Object.values(plantData).map(p => p.volume_size);

  donutsObjs.value.Order.donutData = {
    datasets: [
      {
        data: orderData
      }
    ]
  };
  donutsObjs.value.Volume.donutData = {
    datasets: [
      {
        data: volumeData
      }
    ]
  };
  donutsObjs.value["Volume x Size"].donutData = {
    datasets: [
      {
        data: volumeSizeData
      }
    ]
  };
}

function pushData() {
  orderDonut.value = structuredClone(toRaw(defaultOptions.value));
  volDonut.value = structuredClone(toRaw(defaultOptions.value));
  volSizeDonut.value = structuredClone(toRaw(defaultOptions.value));

  orderDonut.value.title.text = orderTotal.value.toLocaleString();
  volDonut.value.title.text = volTotal.value.toLocaleString();
  volSizeDonut.value.title.text = volSizeTotal.value.toLocaleString();

  orderDonut.value.subtitle.text = i18n.t("production.order_donut_subtitle");
  volDonut.value.subtitle.text = i18n.t("production.volume_donut_subtitle");

  volSizeDonut.value.subtitle.text = i18n.t(
    unitInch.value
      ? "production.volume_size_donut_subtitle_in"
      : "production.volume_size_donut_subtitle_m"
  );

  orderDonut.value.series[0].data = donutsObjs.value.labels.map(
    (label, index) => ({
      name: label,
      y: donutsObjs.value.Order.donutData.datasets[0].data[index],
      color: prop.colors[label]
    })
  );

  volDonut.value.series[0].data = donutsObjs.value.labels.map(
    (label, index) => ({
      name: label,
      y: donutsObjs.value.Volume.donutData.datasets[0].data[index],
      color: prop.colors[label]
    })
  );

  volSizeDonut.value.series[0].data = donutsObjs.value.labels.map(
    (label, index) => ({
      name: label,
      y: donutsObjs.value["Volume x Size"].donutData.datasets[0].data[index],
      color: prop.colors[label]
    })
  );
}
</script>

<style scoped>
.donut-chart .highcharts-data-label span {
  text-align: center;
}
</style>
