<!--
Vue.jsの2系から3系への移行時、Vuatify 3.X.Xにてv-treeviewが未実装だったため作成した。
Vuetifyにv-treeviewが実装され次第、そちらに移行することを推奨する。
-->
<template>
  <template v-for="item in items" :key="item.id">
    <v-list-item
      v-if="!item.children"
      color="primary"
      :title="item.name"
      :value="item.id"
      class="list-content"
      @click="emit('selectNode', item)"
    ></v-list-item>
    <template v-else>
      <v-list-group :value="item.id" append-icon="false" class="group-list">
        <template #activator="{ isOpen, props }">
          <v-list-item
            v-bind="props"
            :prepend-icon="isOpen ? 'mdi-menu-down' : 'mdi-menu-right'"
            slim
            :title="item.name"
          ></v-list-item>
        </template>
        <tree-view
          :items="item.children"
          @select-node="item => emit('selectNode', item)"
        />
      </v-list-group>
    </template>
  </template>
</template>

<script setup>
defineProps({
  items: {
    type: Array,
    default: function () {
      return [];
    }
  }
});
const emit = defineEmits(["selectNode"]);
</script>

<style scoped>
.group-list {
  --prepend-width: 10px;
}
.list-content {
  margin-left: 30px;
}
</style>
