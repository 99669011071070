import { createVuetify } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import { ja } from "vuetify/locale";
import "vuetify/styles";

import DateFnsAdapter from "@date-io/date-fns";
import dateEnUs from "date-fns/locale/en-US";
import dateJaJp from "date-fns/locale/ja";

const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        bland_light: "#82a0aa",
        bland_dark: "#3c5d69",
        bland_black: "#3c5d69",
        bland_red: "#e31f26"
      }
    },
    dark: false
  },
  icons: {
    defaultSet: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    sets: {
      mdi
    }
  },
  locale: {
    locale: "en",
    messages: { ja }
  },
  date: {
    adapter: DateFnsAdapter,
    locale: {
      ja: dateJaJp,
      en: dateEnUs
    }
  }
});

export default vuetify;
