import ReportTargetPlants from "@/assets/report_target_plants.json";
import ManualAdjustmentAmountTargetPlants from "@/assets/manual_adjustment_amount_target_plants.json";
import type { AuthorityPart } from "@/stores/authorities";
import dayjs from "dayjs";

/**
 * レポートを見ることができるか確認する
 * @param authPlant Store "authorities"に保存されたplant
 */
export function canReadReport(authPlant: AuthorityPart): boolean {
  // 関数の中で `useAuthorities()`して状態を取得すると状態の変化が反映されないため
  // 状態の変化を検知して変化後の値を関数に渡すようにした

  if (authPlant.isAll) {
    return true;
  }

  const validPlants = authPlant.targets.filter(item =>
    ReportTargetPlants.includes(item)
  );
  return validPlants.length > 0;
}

/**
 * 手動調整値をみることができるか確認する
 * @param authPlant Store "authorities"に保存されたplant
 */
export function canReadManualAdjustmentAmountTarget(
  authPlant: AuthorityPart
): boolean {
  // 関数の中で `useAuthorities()`して状態を取得すると状態の変化が反映されないため
  // 状態の変化を検知して変化後の値を関数に渡すようにした

  if (authPlant.isAll) {
    return true;
  }

  const validPlants = authPlant.targets.filter(item =>
    ManualAdjustmentAmountTargetPlants.includes(item)
  );

  return validPlants.length > 0;
}

/**
 * 配列の値が同じかどうか判定する
 */
export function eqArrayEasily(a: unknown, b: unknown): boolean {
  if (
    Object.prototype.toString.call(a) !== "[object Array]" ||
    Object.prototype.toString.call(b) !== "[object Array]"
  ) {
    return false;
  }
  const aa = a as Array<unknown>;
  const bb = b as Array<unknown>;
  if (aa.length !== bb.length) {
    return false;
  }
  for (const i in aa) {
    if (aa[i] !== bb[i]) {
      return false;
    }
  }
  return true;
}

/**
 * 指定された日が月曜日かを判定する
 * @param targetDate 指定された日
 */
export function isMonday(targetDate: dayjs.Dayjs): boolean {
  // 1は月曜日
  return targetDate.day() === 1;
}

/**
 * 指定された日が日曜日かを判定する
 * @param targetDate 指定された日
 */
export function isSunday(targetDate: dayjs.Dayjs): boolean {
  // 0は日曜日
  return targetDate.day() === 0;
}

/**
 * 指定された日が月の初日かを判定する
 * @param targetDate 指定された日
 */
export function isFirstDayOfMonth(targetDate: dayjs.Dayjs): boolean {
  // 1日かどうか比
  return targetDate.date() === 1;
}

/**
 * 指定された日が月の最終日かを判定する
 * @param targetDate 指定された日
 */
export function isLastDayOfMonth(targetDate: dayjs.Dayjs): boolean {
  // 月の最終日か比較
  return targetDate.date() === targetDate.daysInMonth();
}

/**
 * 指定された日の週の月曜日の日付を算出する
 * 例）2024-07-17(水)→2024-07-15(月)、2024-07-21(日)→2024-07-15(月)
 * @param targetDate (yyyy-mm-dd)
 */
export function getMonday(targetDate: dayjs.Dayjs): string {
  // 月曜日からの日数差
  let diff = targetDate.day() - 1;

  if (diff < 0) {
    // 日曜日の場合は前の月曜日までの差を計算
    diff = 6;
  }

  const mondayDate = targetDate.subtract(diff, "day");

  return mondayDate.format("YYYY-MM-DD");
}

type SeriesDataPoint = {
  x: number | Date | string;
  y: number;
};
type FormattedDataPoint = SeriesDataPoint & {
  formattedX: string;
};

/**
 * 指定された期間のデータポイントの平均値を計算する
 *
 * @param seriesData - 計算対象のデータポイントの配列
 * @param timeType - 時間の単位 ("day" | "week" | "month")
 * @param fromDate - 計算開始日 (dayjs.Dayjs)
 * @param toDate - 計算終了日 (dayjs.Dayjs)
 * @returns 平均値 (データが2点以上ある場合) または null (データが1点以下の場合)
 *
 * @description
 * この関数は、指定された期間内のデータポイントの平均値を計算します。
 * 時間の単位に応じて、適切なフィルタリングを行います：
 * - "month": 月単位でフィルタリングし、開始月と終了月が完全に含まれる場合のみ計算に含めます。
 * - "week": 週単位でフィルタリングし、開始日と終了日を含む完全な週のデータのみを計算に含めます。
 * - "day": 日単位で全データを含めます。
 *
 * 注意: toDateが現在日より後の場合、現在日に置き換えられます。
 */
export function calculateAverageByPeriod(
  seriesData: SeriesDataPoint[],
  timeType: "day" | "week" | "month",
  fromDate: dayjs.Dayjs,
  toDate: dayjs.Dayjs
): number | null {
  const formattedData: FormattedDataPoint[] = seriesData.map(obj => ({
    ...obj,
    formattedX: dayjs(obj.x).format("YYYY-MM-DD")
  }));

  const calculateAverage = (data: FormattedDataPoint[]): number =>
    data.reduce((sum, obj) => sum + obj.y, 0) / data.length;

  const handleMonthFiltering = (
    data: FormattedDataPoint[],
    fromDate: dayjs.Dayjs,
    modifiedToDate: dayjs.Dayjs
  ): FormattedDataPoint[] => {
    const fromToSameMonth = fromDate.isSame(modifiedToDate, "month");
    if (fromToSameMonth) {
      const isFirstDay = isFirstDayOfMonth(fromDate);
      const isLastDay = isLastDayOfMonth(modifiedToDate);
      return isFirstDay && isLastDay ? data : [];
    }

    return data.filter(obj => {
      const itemDate = dayjs(obj.formattedX);
      let isIncluded = false;

      if (itemDate.isSame(fromDate, "month")) {
        isIncluded = isFirstDayOfMonth(fromDate);
      } else if (itemDate.isSame(modifiedToDate, "month")) {
        isIncluded = isLastDayOfMonth(modifiedToDate);
      } else {
        isIncluded =
          itemDate.isAfter(fromDate, "month") &&
          itemDate.isBefore(modifiedToDate, "month");
      }
      return isIncluded;
    });
  };

  const handleWeekFiltering = (
    data: FormattedDataPoint[],
    fromDate: dayjs.Dayjs,
    modifiedToDate: dayjs.Dayjs
  ): FormattedDataPoint[] => {
    const fromDateStart = fromDate.startOf("day");
    const toDateEnd = modifiedToDate.endOf("day");

    return data.filter(item => {
      const itemDate = dayjs(item.x);
      // 月曜日
      const itemWeekStart = itemDate
        .startOf("week")
        .add(1, "day")
        .startOf("day");

      // 日曜日
      const itemWeekEnd = itemWeekStart.add(6, "day").endOf("day");

      const fromIncluded =
        fromDateStart.isSame(itemWeekStart) ||
        fromDateStart.isBefore(itemWeekStart);
      const toIncluded =
        toDateEnd.isSame(itemWeekEnd) || toDateEnd.isAfter(itemWeekEnd);

      return fromIncluded && toIncluded;
    });
  };

  let filteredData = formattedData;

  const today = dayjs();
  const modifiedToDate = toDate.isAfter(today) ? today : toDate;

  if (timeType === "month") {
    filteredData = handleMonthFiltering(
      formattedData,
      fromDate,
      modifiedToDate
    );
  } else if (timeType === "week") {
    filteredData = handleWeekFiltering(formattedData, fromDate, modifiedToDate);
  }

  const average =
    filteredData.length > 1 ? calculateAverage(filteredData) : null;

  return average;
}

/**
 * undefined、nullの場合にN/Aに置換する（主にオーダーテーブルに使用）
 * @param value
 */
export function replaceUndefinedWithNA(
  value: string | null | undefined
): string {
  return value === null || value === undefined ? "N/A" : value;
}

/**
 * フルートについてundefined、null、空文字列の場合にN/Aに置換する（主にオーダーテーブルに使用）
 * @param value
 */
export function replaceInvalidFluteWithNA(
  value: string | null | undefined
): string {
  return value === null || value === undefined || value === "" ? "N/A" : value;
}

/**
 * 値が入っている場合は固定小数点第1位までを返し、undefined、nullの場合にN/Aに置換する（主にオーダーテーブルに使用）
 * @param value
 */
export function convertToFixedPointNumberOrNA(
  value: number | null | undefined
): number | string {
  return value === null || value === undefined
    ? "N/A"
    : Number(value).toFixed(1);
}

/**
 * DC情報の数値に対応するローカライズされた文字列を返す。
 *
 * @param {number} value - DC情報を表す数値。
 * @param {(key: string) => string} t - 翻訳キーをローカライズされた文字列に変換するための翻訳関数。
 *                                      ※ヘルパー関数内で直接i18nを利用できないため。
 * @returns {string} - 指定された数値に対応する翻訳済みの文字列。認識されない値の場合は "N/A" を返します。
 */
export function translateDcInformation(
  value: number,
  t: (key: string) => string
): string {
  switch (value) {
    case 0:
    case 3:
    case 4:
    case 5:
      return t("sidebar.dc_information_value.pull");
    case 1:
      return t("sidebar.dc_information_value.cut");
    case 2:
      return t("sidebar.dc_information_value.hand_hole");
    default:
      return "N/A";
  }
}
