<template>
  <v-row class="ma-0">
    <v-col class="pa-4">
      <v-card class="translucent" :elevation="0" rounded="0">
        <div class="mb-1 subtitle" :class="faultUnitClass">
          <v-icon v-if="faultUnitDataLength > 0" color="#B42318">
            mdi-alert-outline
          </v-icon>
          {{ i18n.t("report.unit.fault_unit") }}
          {{ faultUnitDataLength }}{{ i18n.t("report.unit.count") }}
        </div>
        <v-data-table
          v-model:page="faultUnitPage"
          density="comfortable"
          :headers="headers"
          :items="viewdata[0]"
          class="elevation-1 fault-unit"
          :class="faultUnitClass"
          :items-per-page="itemsPerPage"
        >
          <template #[`item`]="{ item }">
            <tr>
              <td>{{ getI18nText("report_unit.process", item.process) }}</td>
              <td>
                {{
                  getI18nText("report_unit.component_note", item.component_note)
                }}
              </td>
              <td>
                {{ getI18nText("report_unit.measurement", item.measurement) }}
              </td>
              <td>
                {{
                  getI18nText(
                    "report_unit.advice.fault_unit",
                    item.process,
                    item.component_note,
                    item.measurement
                  )
                }}
              </td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
        <div class="flex">
          <div class="float-left mt-3">
            <v-pagination
              v-model="faultUnitPage"
              :length="faultUnitPageCount"
              size="x-small"
              total-visible="4"
              variant="text"
            ></v-pagination>
          </div>
          <div class="float-right mt-6 mr-2">
            {{ faultUnitPageDataLength }}/ {{ faultUnitDataLength }}
          </div>
        </div>
      </v-card>
      <v-card class="translucent" :elevation="0" rounded="0">
        <div class="mb-1 subtitle" :class="repairUnitClass">
          {{ i18n.t("report.unit.repair_unit") }}
          {{ repairUnitDataLength }}{{ i18n.t("report.unit.count") }}
        </div>
        <v-data-table
          v-model:page="repairUnitPage"
          density="comfortable"
          :headers="headers"
          :items="viewdata[1]"
          class="elevation-1 repair-unit"
          :class="repairUnitClass"
          :items-per-page="itemsPerPage"
        >
          <template #[`item`]="{ item }">
            <tr>
              <td>{{ getI18nText("report_unit.process", item.process) }}</td>
              <td>
                {{
                  getI18nText("report_unit.component_note", item.component_note)
                }}
              </td>
              <td>
                {{ getI18nText("report_unit.measurement", item.measurement) }}
              </td>
              <td>
                {{
                  getI18nText(
                    "report_unit.advice.repair_unit",
                    item.process,
                    item.component_note,
                    item.measurement
                  )
                }}
              </td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
        <div class="flex">
          <div class="float-left mt-3">
            <v-pagination
              v-model="repairUnitPage"
              :length="repairUnitPageCount"
              size="x-small"
              total-visible="4"
              variant="text"
            ></v-pagination>
          </div>
          <div class="float-right mt-6 mr-2">
            {{ repairUnitPageDataLength }}/ {{ repairUnitDataLength }}
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script setup>
import { computed, ref, unref } from "vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  }
});

const i18n = useI18n();

const faultUnitPage = ref(1);
const repairUnitPage = ref(1);
const itemsPerPage = ref(5);
const headers = ref([
  {
    title: i18n.t("report.unit.process"),
    key: "process",
    width: "13%"
  },
  {
    title: i18n.t("report.unit.component_note"),
    key: "component_note",
    width: "13%"
  },
  {
    title: i18n.t("report.unit.measurement"),
    key: "measurement",
    width: "13%"
  },
  { title: i18n.t("report.unit.advice"), key: "advice", width: "61%" }
]);

const faultUnitArray = computed(() => {
  return prop.viewdata.length < 1 ? [] : prop.viewdata[0];
});
const faultUnitDataLength = computed(() => {
  return unref(faultUnitArray).length;
});
const faultUnitPageCount = computed(() => {
  if (prop.viewdata[0]) {
    return Math.ceil(prop.viewdata[0].length / unref(itemsPerPage));
  } else {
    return 0;
  }
});
const faultUnitPageDataLength = computed(() => {
  if (unref(faultUnitDataLength) <= 5 * unref(faultUnitPage)) {
    return unref(faultUnitDataLength);
  } else {
    return 5 * unref(faultUnitPage);
  }
});
const faultUnitClass = computed(() => {
  return {
    "no-value": unref(faultUnitDataLength) === 0
  };
});
const repairUnitArray = computed(() => {
  return prop.viewdata.length < 2 ? [] : prop.viewdata[1];
});
const repairUnitDataLength = computed(() => {
  return unref(repairUnitArray).length;
});
const repairUnitPageCount = computed(() => {
  if (prop.viewdata[1]) {
    return Math.ceil(prop.viewdata[1].length / unref(itemsPerPage));
  } else {
    return 0;
  }
});
const repairUnitPageDataLength = computed(() => {
  if (unref(repairUnitDataLength) <= 5 * unref(repairUnitPage)) {
    return unref(repairUnitDataLength);
  } else {
    return 5 * unref(repairUnitPage);
  }
});
const repairUnitClass = computed(() => {
  return {
    "no-value": unref(repairUnitDataLength) === 0
  };
});

/**
 * i18nの翻訳文を取得する (その際エラーは握りつぶす)
 * @param name i18n.t()に渡す引数
 * @param indexes i18n.t()で取得したobjectにアクセスするためのキー (複数指定可能)
 * @return {VueI18n.TranslateResult|string}
 */
function getI18nText(name, ...indexes) {
  try {
    let data = i18n.tm(name);
    for (const key of indexes) {
      data = data[key];
    }
    return i18n.rt(data);
  } catch (e) {
    console.log({
      message: "failed to translate text in getI18nText()",
      err: e,
      name,
      indexes
    });
    return "";
  }
}
</script>

<style>
.subtitle {
  color: rgba(180, 35, 24, 1);
  font-weight: bold;
  font-size: 18px !important;
}
.subtitle.no-value {
  color: #027a48;
}

.fault-unit .v-data-table__th {
  background-color: rgba(254, 205, 202, 1);
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700 !important;
}
.fault-unit.no-value .v-data-table__th {
  background-color: #a6f4c5;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700 !important;
}
.fault-unit table tbody td {
  background-color: rgba(254, 243, 242, 1);
  font-size: 12px !important;
}
.fault-unit.no-value table tbody td {
  background-color: #ecfdf3;
}

.repair-unit .v-data-table__th {
  background-color: rgba(254, 223, 137, 1);
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700 !important;
}
.repair-unit.no-value .v-data-table__th {
  background-color: #a6f4c5;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700 !important;
}
.repair-unit table tbody td {
  background-color: rgba(255, 250, 235, 1);
  font-size: 12px !important;
}
.repair-unit.no-value table tbody td {
  background-color: #ecfdf3;
}
.v-data-table.fault-unit {
  white-space: pre-line;
  border: solid #ceced1;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.v-data-table.repair-unit {
  white-space: pre-line;
  border: solid #ceced1;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.flex {
  display: flex;
  flex-direction: row-reverse;
}
.v-pagination__item,
.v-pagination__prev,
.v-pagination__next {
  box-shadow: none !important;
  border: solid 1px #ceced1;
  border-radius: 4px;
}
.v-pagination .v-btn__content {
  font-size: 1rem;
  font-weight: 400;
}
</style>
