<template>
  <v-container class="pa-4" fluid>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{
            i18n.t("user.title")
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="px-0 py-4" xl="12" lg="12" md="12" sm="12" cols="12">
        <v-card class="translucent" min-height="100px" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{ i18n.t("user.account") }}</v-toolbar-title>
          </v-toolbar>
          <v-row class="px-4 py-0 ma-0">
            <v-col class="pa-0">
              <v-avatar :image="userPicture" size="36" class="mr-4" />
              {{ familyName }} {{ givenName }}
            </v-col>
            <v-btn @click="logout()">
              {{ i18n.t("user.logout") }}
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="px-0 pb-4 pt-0" xl="12" lg="12" md="12" sm="12" cols="12">
        <v-card class="translucent" min-height="240px" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("user.display_setting")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-row class="px-4 py-0 ma-0">
            <v-col class="pa-0 px-4">
              <v-toolbar-title class="text-subtitle-1 font-weight-bold">
                {{ i18n.t("user.unit") }}</v-toolbar-title
              >
              <v-radio-group v-model="dispunit" inline class="mt-2 px-4">
                <v-radio
                  color="primary"
                  :label="i18n.t('user.meter')"
                  value="m"
                ></v-radio>
                <v-radio
                  color="primary"
                  :label="i18n.t('user.inch')"
                  value="in"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="px-4 py-0 mx-0 mb-6">
            <v-col class="pa-0 px-4">
              <v-toolbar-title class="text-subtitle-1 font-weight-bold">
                {{ i18n.t("user.display_mode") }}</v-toolbar-title
              >
              <v-switch
                v-model="darkmode"
                class="mt-2 px-4"
                :label="i18n.t('user.dark-mode')"
                color="yellow"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="px-4 py-0 ma-0">
            <v-col class="pa-0 px-4">
              <v-toolbar-title class="text-subtitle-1 font-weight-bold">
                {{ i18n.t("user.data_acquisition") }}</v-toolbar-title
              >
              <v-radio-group v-model="dataperiod" inline class="mt-2 px-4">
                <v-radio
                  color="primary"
                  :label="i18n.t('user.one-week')"
                  value="1w"
                ></v-radio>
                <v-radio
                  color="primary"
                  :label="i18n.t('user.one-month')"
                  value="1m"
                ></v-radio>
                <v-radio
                  color="primary"
                  :label="i18n.t('user.three-month')"
                  value="3m"
                ></v-radio>
                <v-radio
                  color="primary"
                  :label="i18n.t('user.six-month')"
                  value="6m"
                ></v-radio
                ><br />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="px-4 py-0 ma-0">
            <v-col style="max-width: 200px" class="pa-0 px-4">
              <v-radio-group v-model="dataperiod" inline class="mt-6 px-4">
                <v-radio
                  color="primary"
                  :label="i18n.t('user.custom')"
                  value="cu"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col style="min-width: 200px" cols="3">
              <v-menu
                v-model="fromMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                location="bottom"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="fromDate"
                    v-bind="props"
                    color="primary"
                    :label="i18n.t('user.from')"
                    width="200px"
                    clearable
                    :disabled="disableCustomDate"
                    variant="underlined"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDateTime"
                  color="primary"
                  hide-header
                  @update:model-value="setFromToDate('fromDate')"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col style="min-width: 200px" cols="3">
              <v-menu
                v-model="toMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                location="bottom"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="toDate"
                    v-bind="props"
                    color="primary"
                    :label="i18n.t('user.to')"
                    readonly
                    clearable
                    :disabled="disableCustomDate"
                    variant="underlined"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDateTime"
                  color="primary"
                  hide-header
                  @update:model-value="setFromToDate('toDate')"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                :disabled="disableReloadBtn"
                class="ml-12 mb-4"
                @click="reloadData"
                >{{ i18n.t("user.reload-button") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="px-4 py-0 mx-0 mb-6">
            <v-col class="pa-0 px-4 pb-8" cols="4">
              <v-toolbar-title class="text-subtitle-1 font-weight-bold">
                {{ i18n.t("user.language") }}</v-toolbar-title
              >
              <v-select
                v-model="langName"
                color="primary"
                :items="langOptions"
                variant="outlined"
                density="compact"
                class="pt-2"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0"> </v-row>
    <div class="setting-update">
      <FlashToast :position="'top-right'"></FlashToast>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AppUser"
};
</script>
<script setup>
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, inject, onMounted, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { logoutRedirect } from "@/auth";
import { getLocale, getLangOptions, connectToAPI } from "@/helpers/util";
import { usePreference } from "@/stores/preference";
import { useUserinfo } from "@/stores/userinfo";

/**
 * @typedef StoreSchemePreference
 * @property {"en" | "ja"} language
 * @property {boolean} darkmode
 * @property {string} dateFrom
 * @property {string} dateTo
 * @property {"1w" | "1m" | "3m" | "6m" | "cu"} period
 * @property {boolean} unitInch
 */

/**
 * @typedef PayloadApiUpdateUserPreference
 * @property {string} language
 * @property {boolean} dark_mode
 * @property {string} date_from
 * @property {string} date_to
 * @property {string} period
 * @property {boolean} unit_inch
 */

const toast = inject("toast");

const i18n = useI18n();
const preferenceStore = usePreference();
const userinfoStore = useUserinfo();

const {
  language: spLanguage,
  darkmode: spDarkmode,
  dateFrom: spDateFrom,
  dateTo: spDateTo,
  period: spPeriod,
  unitInch: spUnitInch
} = storeToRefs(preferenceStore);
const { userPicture, familyName, givenName } = storeToRefs(userinfoStore);
const dataperiod = ref(null);
const fromDate = ref(null);
const toDate = ref(null);
const fromDateTime = ref(undefined);
const toDateTime = ref(undefined);
const fromMenu = ref(false);
const toMenu = ref(false);

const dispunit = computed({
  get() {
    return unref(spUnitInch) ? "in" : "m";
  },
  /**
   * @param {string} newValue
   */
  set(newValue) {
    const flag = newValue === "in";
    const preference = getObjectPreference();
    preference.unitInch = flag;
    preferenceStore.initialize(preference);
    callApiUpdateUserPreference(preference);
  }
});
const darkmode = computed({
  get() {
    return unref(spDarkmode);
  },
  /**
   * @param {boolean} value
   */
  set(value) {
    const preference = getObjectPreference();
    preference.darkmode = value;
    preferenceStore.initialize(preference);
    callApiUpdateUserPreference(preference);
  }
});
const langOptions = computed(() => {
  return getLangOptions(unref(spLanguage));
});
const langName = computed({
  get() {
    const locale = getLocale(unref(spLanguage));
    return locale.name;
  },
  /**
   * @param {string} newValue
   */
  set(newValue) {
    const locale = getLocale(newValue);
    const preference = getObjectPreference();
    preference.language = locale.code;
    preferenceStore.initialize(preference);
    callApiUpdateUserPreference(preference);
  }
});
const disableCustomDate = computed(() => {
  return unref(dataperiod) !== "cu";
});
const disableReloadBtn = computed(() => {
  if (unref(dataperiod) !== "cu") {
    return false;
  }
  return !unref(fromDate) || !unref(toDate);
});

watch(fromDate, () => {
  if (unref(fromDate)) {
    fromDateTime.value = dayjs(unref(fromDate)).toDate();
  } else {
    fromDateTime.value = undefined;
  }
});

watch(toDate, () => {
  if (unref(toDate)) {
    toDateTime.value = dayjs(unref(toDate)).toDate();
  } else {
    toDateTime.value = undefined;
  }
});

onMounted(() => {
  dataperiod.value = unref(spPeriod);
  fromDate.value = unref(spDateFrom);
  toDate.value = unref(spDateTo);
});

async function logout() {
  await logoutRedirect();
}
async function reloadData() {
  const preference = getObjectPreference();
  preference.period = unref(dataperiod);
  preference.dateFrom = unref(fromDate);
  preference.dateTo = unref(toDate);
  preferenceStore.initialize(preference);
  await callApiUpdateUserPreference(preference);
}
/**
 * datepicker操作時のメソッド
 */
function setFromToDate(type) {
  if (type === "fromDate") {
    fromDate.value = dayjs(unref(fromDateTime)).format("YYYY-MM-DD");
    fromMenu.value = false;

    //from入力時にtoがあったらチェック
    //入力したfromがtoより後ろだったらtoをclear
    if (unref(toDate)) {
      if (new Date(unref(toDate)) < new Date(unref(fromDate))) {
        toDate.value = null;
      }
    }
  }

  if (type === "toDate") {
    toDate.value = dayjs(unref(toDateTime)).format("YYYY-MM-DD");
    toMenu.value = false;

    //to入力時にfromがあったらチェック
    //入力したtoがfromより前だったらfromをclear
    if (unref(fromDate)) {
      if (new Date(unref(toDate)) < new Date(unref(fromDate))) {
        fromDate.value = null;
      }
    }
  }
}
/**
 * @return {StoreSchemePreference}
 */
function getObjectPreference() {
  return {
    language: unref(spLanguage),
    darkmode: unref(spDarkmode),
    dateFrom: unref(spDateFrom),
    dateTo: unref(spDateTo),
    period: unref(spPeriod),
    unitInch: unref(spUnitInch)
  };
}
/**
 *
 * @param {StoreSchemePreference} data
 * @return {Promise<void>}
 */
async function callApiUpdateUserPreference(data) {
  try {
    /**
     * @type {PayloadApiUpdateUserPreference}
     */
    const payload = {
      language: data.language,
      dark_mode: data.darkmode,
      date_from: data.dateFrom,
      date_to: data.dateTo,
      period: data.period,
      unit_inch: data.unitInch
    };
    await connectToAPI({
      method: "POST",
      url: "/api/user-preference",
      data: payload
    });
    toast.success({
      title: "",
      message: i18n.t("user.setting-updated"),
      delay: "3000"
    });
  } catch (e) {
    toast.error({
      title: "",
      message: "Update failed.",
      delay: "3000"
    });
  }
}
</script>
<style>
.setting-update ._vue-flash-msg-body_success,
.setting-update ._vue-flash-msg-body_error {
  position: absolute;
  top: 0;
  bottom: auto !important;
  width: 20% !important;
  right: 0% !important;
}
</style>
