<template>
  <v-app-bar color="blue-grey-darken-1">
    <v-btn
      v-if="isAuthenticated"
      icon
      class="d-lg-none"
      @click.stop="cl_sidebtn"
    >
      <v-icon size="36">mdi-filter-variant</v-icon>
    </v-btn>
    <!--v-app-bar-nav-icon  @click.stop="cl_sidebtn" class="d-lg-none"/-->
    <v-toolbar-items>
      <v-btn to="/" variant="text">
        <v-toolbar-title>
          <v-img
            src="../../src/assets/title.png"
            width="250px"
            height="40px"
            aspect-ratio="10"
          />
        </v-toolbar-title>
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="isAuthenticated" class="d-none d-md-flex">
      <v-btn
        v-if="canReadReportFlg"
        to="/report-list"
        variant="text"
        class="text-center"
        >{{ i18n.t("report.report_list.title") }}</v-btn
      >
      <v-menu
        :open-on-hover="true"
        :close-on-content-click="true"
        location="bottom"
        class="text-center"
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" variant="text">
            {{ i18n.t("others.title.dashboard") }}<v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list bg-color="blue-grey-lighten-1">
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/productivity"
              variant="flat"
              >{{ i18n.t("others.title.productivity_dashboard") }}</v-btn
            >
          </v-list-item>
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/condition"
              variant="flat"
              >{{ i18n.t("others.title.condition_dashboard") }}</v-btn
            >
          </v-list-item>
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/production"
              variant="flat"
              >{{ i18n.t("others.title.production_dashboard") }}</v-btn
            >
          </v-list-item>
          <v-list-item
            v-if="canReadManualAdjustmentAmountTargetFlg"
            class="px-0"
          >
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/manualadjustmentamount"
              variant="flat"
              >{{ i18n.t("others.title.manual_adjustment_amount") }}</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn to="/list" variant="text" class="text-center">{{
        i18n.t("others.title.order_list")
      }}</v-btn>
      <v-menu
        :open-on-hover="true"
        :close-on-content-click="true"
        location="bottom"
        class="text-center"
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" variant="text">
            {{ i18n.t("others.title.timeseries")
            }}<v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list bg-color="blue-grey-lighten-1">
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              min-width="145px"
              to="/history"
              variant="flat"
              >{{ i18n.t("others.title.history") }}</v-btn
            >
          </v-list-item>
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              min-width="145px"
              to="/realtime"
              variant="flat"
              >{{ i18n.t("others.title.realtime") }}</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        :open-on-hover="true"
        :close-on-content-click="true"
        location="bottom"
        class="text-center"
      >
        <template #activator="{ props }">
          <v-btn v-bind="props" variant="text">
            {{ i18n.t("others.title.analysis_tab")
            }}<v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list bg-color="blue-grey-lighten-1">
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/erroranalysis"
              variant="flat"
              >{{ i18n.t("others.title.error_analysis") }}</v-btn
            >
          </v-list-item>
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/productiontime"
              variant="flat"
              >{{ i18n.t("others.title.production_time") }}</v-btn
            >
          </v-list-item>
          <v-list-item class="px-0">
            <v-btn
              block
              color="transparent"
              class="mx-auto"
              to="/stopfactor"
              variant="flat"
              >{{ i18n.t("others.title.stop_factor") }}</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu location="bottom">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon to="/user">
            <v-avatar :image="userPicture" size="36" />
          </v-btn>
        </template>
      </v-menu>
    </v-toolbar-items>
    <v-menu v-if="isAuthenticated" location="bottom">
      <template #activator="{ props }">
        <v-app-bar-nav-icon v-bind="props" class="d-md-none" />
      </template>
      <v-list class="text-center">
        <v-list-item v-if="canReadReportFlg" to="/report-list">
          <v-list-item-title>{{
            i18n.t("report.report_list.title")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/productivity">
          <v-list-item-title>
            {{ i18n.t("others.title.productivity") }}
            <br />{{ i18n.t("others.title.dashboard") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/condition">
          <v-list-item-title>
            {{ i18n.t("others.title.condition") }}
            <br />{{ i18n.t("others.title.dashboard") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/list">
          <v-list-item-title>
            {{ i18n.t("others.title.order") }}
            <br />{{ i18n.t("others.title.list") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/production">
          <v-list-item-title>
            {{ i18n.t("others.title.production") }}
            <br />{{ i18n.t("others.title.dashboard") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/history">
          <v-list-item-title>{{
            i18n.t("others.title.history")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/realtime">
          <v-list-item-title>{{
            i18n.t("others.title.realtime")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/erroranalysis">
          <v-list-item-title>{{
            i18n.t("others.title.error_analysis")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/productiontime">
          <v-list-item-title>{{
            i18n.t("others.title.production_time")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/stopfactor">
          <v-list-item-title>{{
            i18n.t("others.title.stop_factor")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="canReadManualAdjustmentAmountTargetFlg"
          to="/manualadjustmentamount"
        >
          <v-list-item-title>{{
            i18n.t("others.title.manual_adjustment_amount")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/user">
          <v-list-item-title>{{ familyName }}{{ givenName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar"
};
</script>
<script setup>
import { storeToRefs } from "pinia";
import { computed, unref } from "vue";
import { useI18n } from "vue-i18n";

import {
  canReadManualAdjustmentAmountTarget,
  canReadReport
} from "@/helpers/util";
import { useAuthorities } from "@/stores/authorities";
import { useDrawerFlgStore } from "@/stores/drawerFlg";
import { useUserinfo } from "@/stores/userinfo";

const i18n = useI18n();
const authoritiesStore = useAuthorities();
const drawerFlgStore = useDrawerFlgStore();
const userinfoStore = useUserinfo();

const { plant: saPlant } = storeToRefs(authoritiesStore);
const { drawerFlg } = storeToRefs(drawerFlgStore);
const { familyName, givenName, userPicture, isNotInitialized } =
  storeToRefs(userinfoStore);

const isAuthenticated = computed(() => {
  return !unref(isNotInitialized);
});
const canReadReportFlg = computed(() => {
  return canReadReport(unref(saPlant));
});
const canReadManualAdjustmentAmountTargetFlg = computed(() => {
  return canReadManualAdjustmentAmountTarget(unref(saPlant));
});

function cl_sidebtn() {
  drawerFlg.value = !unref(drawerFlg);
}
</script>
<style scoped>
.v-btn--contained {
  box-shadow: none;
}
.v-menu__content .v-btn {
  width: 100%;
}
/* ▽付きnav menuのレイアウト修正 */
.v-btn--is-elevated {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
