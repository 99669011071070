<template>
  <v-app class="bg">
    <sidebar />
    <navbar />
    <v-main>
      <router-view />
    </v-main>
    <notification @release-note-open-request="releaseNoteOpenRequestHandler" />
    <release-note
      :open-status="releaseNoteOpenStatus"
      @open-status-update-request="updateReleaseNoteStatusHandler"
    />
    <footerbar @release-note-open-request="releaseNoteOpenRequestHandler" />
  </v-app>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useLocale, useTheme } from "vuetify";

import Footerbar from "@/components/FooterBar.vue";
import Navbar from "@/components/Navbar.vue";
import Notification from "@/components/Notification.vue";
import ReleaseNote from "@/components/ReleaseNote.vue";
import Sidebar from "@/components/Sidebar.vue";
import { setChartOptions, getLocale } from "@/helpers/util";
import { useDataperiodStore } from "@/stores/dataperiod";
import { usePreference } from "@/stores/preference";

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const vuetifyLocale = useLocale();
const vuetifyTheme = useTheme();
const dataperiodStore = useDataperiodStore();
const preferenceStore = usePreference();

const { dataperiod } = storeToRefs(dataperiodStore);
const { darkmode, language, period, dateFrom, dateTo, isNotInitialized } =
  storeToRefs(preferenceStore);
const releaseNoteOpenStatus = ref(false);

const hash = computed(() => {
  return route.hash;
});

watch(darkmode, () => {
  resolveTheme();
});
watch(language, () => {
  resolveLanguage();
});
watch([dateFrom, dateTo, period], () => {
  resolveDataperiod();
});
watch(isNotInitialized, () => {
  resolveTheme();
  resolveLanguage();
  resolveDataperiod();
});
watch(hash, () => {
  if (unref(hash).length > 0) {
    router.replace({ hash: null });
  }
});

function updateReleaseNoteStatusHandler(status) {
  releaseNoteOpenStatus.value = status;
}
function releaseNoteOpenRequestHandler() {
  releaseNoteOpenStatus.value = true;
}
function resolveTheme() {
  vuetifyTheme.global.name.value = unref(darkmode) ? "dark" : "light";
  setChartOptions(unref(darkmode));
}
function resolveLanguage() {
  const locale = getLocale(unref(language));
  i18n.locale.value = locale.code;
  vuetifyLocale.current.value = locale.code;
}
function resolveDataperiod() {
  const data = {
    period: unref(period),
    fromDate: unref(period) === "cu" ? unref(dateFrom) : "",
    toDate: unref(period) === "cu" ? unref(dateTo) : ""
  };
  dataperiod.value = data;
}
</script>
<style>
/*
.bg {
    background:#f3f3f3 !important;
}
*/
/*
.bg {
    background: url("~@/assets/back.jpg") no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover!important;
    position: relative!important;
    z-index: 0!important;
    overflow: hidden!important;
}
.bg:before{
  content: '';
  background: inherit;
  filter: blur(10px) hue-rotate(20deg) saturate(60%);
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -1;
}
.translucent {
   background-color: rgba(30, 25, 23, 0.5)!important;
 }
 .transparent {
   background-color: rgba(30, 25, 23, 0)!important;
 }
.theme--light.v-card.v-card--outlined {
   border:10px solid rgba(113, 212, 230, 0.452)!important;
 }
*/
</style>
