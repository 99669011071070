import { defineStore } from "pinia";
import { ref, reactive } from "vue";

export type AuthorityPart = {
  isAll: boolean;
  targets: string[];
};

/**
 * 認可情報を保持するStore
 */
export const useAuthorities = defineStore("authorities", () => {
  const isNotInitialized = ref(true);
  const region = reactive<AuthorityPart>({ isAll: false, targets: [] });
  const customer = reactive<AuthorityPart>({ isAll: false, targets: [] });
  const plant = reactive<AuthorityPart>({ isAll: false, targets: [] });

  /**
   * Storeの初期化を行うために実行する
   */
  function initialize(props: {
    region: AuthorityPart;
    customer: AuthorityPart;
    plant: AuthorityPart;
  }) {
    isNotInitialized.value = false;
    region.isAll = props.region.isAll;
    region.targets = props.region.targets;
    customer.isAll = props.customer.isAll;
    customer.targets = props.customer.targets;
    plant.isAll = props.plant.isAll;
    plant.targets = props.plant.targets;
  }

  return {
    isNotInitialized,
    region,
    customer,
    plant,
    initialize
  };
});
