<template>
  <v-container class="pa-4" fluid>
    <alart-snack-bar :alart-obj="alartObj" />
    <loading-bar :loading="isTreeLoading" />
    <v-row class="ma-0">
      <v-col v-if="!reqParamsReady" class="pa-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{
            i18n.t("history.message")
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="px-0 py-4" xl="12" lg="12" md="12" sm="12" cols="12">
        <v-card class="translucent" height="300px" rounded="0">
          <v-row class="ma-0">
            <v-col class="ma-0 pa-0">
              <v-toolbar density="compact" class="bg-transparent" flat>
                <v-toolbar-title>{{
                  i18n.t("history.production_order")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-list
                v-model:selected="selecteditemi"
                color="primary"
                select-strategy="leaf"
                class="bg-transparent"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :title="item.name"
                  :value="i"
                ></v-list-item>
              </v-list>
            </v-col>
            <v-col class="ma-0 pt-0">
              <v-toolbar density="compact" class="bg-transparent" flat>
                <v-toolbar-title>{{
                  i18n.t("history.sensor")
                }}</v-toolbar-title>
                <v-spacer />
                <toggle-buttons
                  :contents="speedButtons"
                  default="ordertime"
                  :disabled="disabledToggle"
                  @select-button="changeToggle"
                />
              </v-toolbar>
              <v-card
                class="translucent overflow-y-auto"
                height="240px"
                flat
                rounded="0"
              >
                <v-list
                  v-model:opened="openedNodeIds"
                  :selected="selectnodes.map(node => node.id)"
                  density="compact"
                  open-strategy="multiple"
                  select-strategy="leaf"
                >
                  <tree-view :items="treedata" @select-node="getSelectNodes" />
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col class="pa-0" cols="12" style="position: relative">
        <loading-overlay :is-loading="isDataFetching || isChartUpdating" />
        <v-card class="translucent" rounded="0" :style="{ height: cardHeight }">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{ i18n.t("history.history") }}</v-toolbar-title>
            <v-spacer /><v-spacer />
            <v-switch
              v-model="separateCharts"
              color="primary"
              :label="i18n.t('history.separate_charts')"
              class="flex-grow-0 pt-6 px-4"
            />
          </v-toolbar>
          <div
            v-if="
              integrateCharts &&
              !separateCharts &&
              chartOptions.series.length > 0
            "
          >
            <highcharts
              :constructor-type="'stockChart'"
              :options="chartOptions"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-card>
          <div id="syncchart-container">
            <div v-if="separateCharts">
              <div
                v-for="(syncChartOptions, index) in syncChartArray"
                :key="index"
              >
                <highcharts
                  :constructor-type="'stockChart'"
                  :options="syncChartOptions"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppHistory"
};
</script>
<script setup>
import { Chart as highcharts } from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
//import darkUnica from "highcharts/themes/dark-unica";
import { storeToRefs } from "pinia";
import { computed, ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import TreeView from "@/components/parts/TreeView.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";
import { connectToAPI } from "@/helpers/util";
import { useFilterDataStore } from "@/stores/filterData";
import { usePlantsMasterStore } from "@/stores/plantsMaster";
import { usePreference } from "@/stores/preference";

stockInit(Highcharts);
//darkUnica(Highcharts);

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const plantsMasterStore = usePlantsMasterStore();
const preferenceStore = usePreference();

const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { plantsMaster } = storeToRefs(plantsMasterStore);
const { language } = storeToRefs(preferenceStore);
const alartObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});
//name:リスト表示用 dp_name：グラフツールチップ表示用 column_name：DB問い合わせ用 unit：グラフY軸用
const clickIdKey = ref([]);
const clickCount = ref(0);
const separateCharts = ref(false);
const integrateCharts = ref(true);
const syncChartArray = ref([]);
const items = ref([
  {
    name: i18n.t("history.volume"),
    dp_name: "Volume",
    column_name: "sum_prod_volume",
    data_unit: "sheets",
    disabled: false,
    isProd: true
  },
  {
    name: i18n.t("history.speed"),
    dp_name: "Speed",
    column_name: "machinery_speed",
    data_unit: "sheets/min",
    isProd: true
  },
  {
    name: i18n.t("history.stop_count"),
    dp_name: "Stop Count",
    column_name: "sum_stop_count",
    data_unit: "count",
    isProd: true
  },
  {
    name: i18n.t("history.stop_time"),
    dp_name: "Stop Time",
    column_name: "sum_stop_time_ms",
    data_unit: "min",
    isProd: true
  }
]);
const selecteditemi = ref([]);
const isDataFetching = ref(false);
const isChartUpdating = ref(false);
const isTreeLoading = ref(false);
const treedata = ref([]);
const openedNodeIds = ref([]);
const selectnodes = ref([]);
const viewdata_loaded = ref({});
const chartOptions = ref({
  colors: ["#2196F3", "#00BCD4", "#009688", "#3F51B5", "#9C27B0"],
  rangeSelector: {
    allButtonsEnabled: true,
    inputEnabled: false,
    buttons: [
      { type: "minute", count: 1, text: i18n.t("history.zoom_min") },
      { type: "hour", count: 1, text: i18n.t("history.zoom_hour") },
      { type: "day", count: 1, text: i18n.t("history.zoom_day") },
      { type: "all", count: 1, text: i18n.t("history.zoom_all") }
    ]
    //selected: 3, // デフォルトで表示させる期間
  },
  tooltip: {
    shared: true
  },
  chart: {
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" }
  },
  time: { timezone: "America/New_York" },
  yAxis: [],
  xAxis: {
    ordinal: false
  },
  series: [],
  credits: { enabled: false }
});
const speedButtons = ref(["ordertime", "alltime"]);
const selectedToggle = ref("ordertime");
const disabledToggle = ref(false);

const LIMIT_DATA = 70000;
const reqParamsReady = computed(() => {
  return (
    unref(stateFilterData).from &&
    unref(stateFilterData).to &&
    unref(selectnodes).length + unref(selecteditemi).length &&
    unref(stateFilterData).selectplantid.length === 1
  );
});
const chartOptionSeries = computed(() => {
  return unref(chartOptions).series;
});
const hasSelection = computed(() => {
  return selecteditemi.value.length > 0 || selectnodes.value.length > 0;
});
const cardHeight = computed(() => {
  return hasSelection.value ? "464px" : "auto"; // 64px (toolbar) + 400px (chart)
});

watch(
  stateFilterData,
  value => {
    viewdata_loaded.value = {};
    unref(chartOptions).series = [];
    syncChartArray.value = [];
    if (value.selectplantid.length === 1) getTreeData();
    else {
      treedata.value = [];
      selectnodes.value = [];
      selecteditemi.value = [];
    }

    if (unref(reqParamsReady)) {
      fillData();
    }
  },
  {
    deep: true,
    immediate: true
  }
);
watch(
  selectnodes,
  () => {
    fillData();
  },
  {
    deep: true
  }
);
watch(
  selecteditemi,
  () => {
    fillData();
  },
  {
    deep: true
  }
);
watch(
  chartOptionSeries,
  () => {
    if (unref(separateCharts)) {
      setSyncChartOptions();
      syncChartPointers();
    }
  },
  {
    deep: true
  }
);
watch(separateCharts, () => {
  if (unref(separateCharts)) {
    setSyncChartOptions();
    syncChartPointers();
  }
});

/**
 * 画面起動時に必要なデータを取る
 */
async function getTreeData() {
  isTreeLoading.value = true;
  const reqOptions = {
    method: "GET",
    url: "/api/datapointtree",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      lang: unref(language)
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));
  treedata.value = response.data;

  //選択したプラントのタイムゾーンをhighchartにセットする
  unref(chartOptions).time.timezone = unref(plantsMaster).filter(
    value => value.plant_id_dpac == unref(stateFilterData).selectplantid[0]
  )[0]?.timezone_id;

  // 履歴画面ではトルクは実効値であり、リアルタイム画面（生値）とは異なるため、分かりやすい表記に書き換える。
  // ここで書き換える理由：APIからはマスターで定義されているテキストが返ってくる。Realtime.vueではそのテキストをそのまま表示するが、History.vueでは異なるテキストを表示するため。
  const replaceTorqueDescription = item => {
    // 所定の表記に一致している場合は書き換える
    if (item.name === i18n.t("history.torque_percent_in_master_file")) {
      item.name = i18n.t("history.effective_torque_percent");
    } else if (item.name === i18n.t("history.torque_nm_in_master_file")) {
      item.name = i18n.t("history.effective_torque_nm");
    }

    // 子要素がある場合は再帰処理で全て置き換える
    if (item.children === null || item.children === undefined) {
      return;
    }
    item.children.forEach(replaceTorqueDescription);
  };

  unref(treedata).forEach(replaceTorqueDescription);

  isTreeLoading.value = false;
}

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * 表示データをつくるメソッド
 */
async function fillData() {
  clickCount.value += 1;

  if (!unref(reqParamsReady)) {
    unref(clickIdKey).push({ id: unref(clickCount), key: "-none" });
    unref(chartOptions).series = [];
    return;
  }
  isChartUpdating.value = true;
  const startTime = Date.now();

  try {
    const processPromise = processChartData();

    // 最小表示時間を確保しつつ、processChartDataの完了を待つ
    const minLoadingTime = 500;
    await Promise.all([
      processPromise,
      new Promise(resolve => setTimeout(resolve, minLoadingTime))
    ]);
  } catch (error) {
    console.error("Error in fillData:", error);
  } finally {
    const elapsedTime = Date.now() - startTime;
    const minimumLoadingTime = 500;
    if (elapsedTime < minimumLoadingTime) {
      await wait(minimumLoadingTime - elapsedTime);
    }
    isChartUpdating.value = false;
  }
}

async function processChartData() {
  unref(chartOptions).yAxis = [];
  let selecteditems = structuredClone(toRaw(unref(selectnodes))).concat(
    unref(selecteditemi).map(j => unref(items)[j])
  );

  const reducer = (acc, current) => acc + "-" + current.column_name;
  const itemskey = selecteditems.reduce(reducer, "");
  unref(clickIdKey).push({ id: unref(clickCount), key: itemskey }); //初期化せず値を保持する
  const tempSeries = [];

  for (let i in selecteditems) {
    const leaf = selecteditems[i];
    unref(chartOptions).yAxis.push({
      title: { text: "" },
      plotLines: []
    });

    //ロード済みではなかったらDBからデータ取得
    if (!unref(viewdata_loaded)[leaf.column_name]) {
      isDataFetching.value = true;
      await getConditionValue(leaf);
      isDataFetching.value = false;
    }
    //既に同じ単位のyaxisがあるかチェック
    let use_yaxis_arraypics = unref(chartOptions).yAxis.filter(
      element => element.title.text === leaf.data_unit
    );
    let indexOfYaxis = Number(i); //なぜかiがstring
    if (!use_yaxis_arraypics.length) {
      //ない場合
      if (unref(chartOptions).yAxis[i]) {
        unref(chartOptions).yAxis[i].title = {
          text: leaf.data_unit
        };
        unref(chartOptions).yAxis[i].plotLines = [];
      }
    } else {
      indexOfYaxis = unref(chartOptions).yAxis.indexOf(use_yaxis_arraypics[0]); //use_yaxis_arraypicsは絶対１つしかはいってないので
    }

    const sel = {
      data: unref(viewdata_loaded)[leaf.column_name],
      name: leaf.dp_name,
      columnName: leaf.column_name,
      yAxis: indexOfYaxis,
      unit: leaf.data_unit,
      dataGrouping: {
        approximation: function (groupData) {
          if (groupData.hasNulls) {
            return null;
          }
          if (groupData.length > 1) {
            const absmax = groupData.reduce(function (acc, val) {
              if (Math.abs(acc) === Math.max(Math.abs(acc), Math.abs(val)))
                return acc;
              else return val;
            });
            return absmax;
          } else {
            return groupData[0];
          }
        }
      }
    };

    tempSeries.push(sel);

    //fault値表示
    if (Number(leaf.ac_fault1)) {
      unref(chartOptions).yAxis[i].plotLines.push({
        value: Number(leaf.ac_fault1),
        color:
          unref(chartOptions).colors[i % unref(chartOptions).colors.length],
        dashStyle: "shortdash",
        width: 2,
        label: {
          text: leaf.ac_fault1,
          style: {
            color:
              unref(chartOptions).colors[i % unref(chartOptions).colors.length]
          }
        }
      });
    }
  }
  renderLatestSelectedItems(tempSeries);

  //一旦非表示にしないとY軸の単位が正しく表示されない
  integrateCharts.value = false;
  integrateCharts.value = await true;

  // 非同期でチャートの表示状態を更新
  await new Promise(resolve => setTimeout(resolve, 0));
}
async function getConditionValue(leaf) {
  let i_load = 0;
  let loaddata = [];
  let tmpdata = [];
  //センサーデータの取得条件を変更できないようにする
  disabledToggle.value = true;
  //dynamoDBで一度に取り切れない時にlast_evaluated_keyというデータがresに入るのでそちらを収納。これを使うと取り切れなかったデータの続きが取得できる
  let last_evaluated_key = {};
  do {
    const reqOptions = {
      method: "GET",
      url: "/api/conditionvalue",
      params: {
        limit: LIMIT_DATA,
        offset: LIMIT_DATA * i_load,
        from: unref(stateFilterData).from,
        to: unref(stateFilterData).to,
        plant_id_dpac: unref(stateFilterData).selectplantid[0],
        city_plant_no: unref(stateFilterData).selectplant[0],
        column_name: leaf.column_name,
        is_all: unref(selectedToggle) == "alltime" ? 1 : 0,
        isProd: leaf.isProd ? 1 : 0,
        last_evaluated_key: last_evaluated_key
      }
    };
    const response = await connectToAPI(reqOptions, unref(alartObj));
    tmpdata = response.data;
    loaddata = loaddata.concat(tmpdata.value);
    i_load++;
    last_evaluated_key = tmpdata.last_evaluated_key;
  } while (tmpdata.continue);
  //APIでソートしてるけどこっちでも念の為
  loaddata = loaddata.sort((a, b) => {
    return a[0] - b[0];
  });

  unref(viewdata_loaded)[leaf.column_name] = loaddata;
  //センサーデータの取得条件を変更できるようにする
  disabledToggle.value = false;
}
function renderLatestSelectedItems(tempSeries) {
  //連続クリック時、最新のselecteditemsのみchartOptions.seriesにpushする
  const reducer = (acc, current) => acc + "-" + current.columnName;
  const resItemsKey = tempSeries.reduce(reducer, "");
  const resKeyAndSeries = { key: resItemsKey, series: tempSeries };

  const maxid = unref(clickIdKey)
    .map(el => el.id)
    .reduce((a, b) => Math.max(a, b));

  const targetKey =
    unref(clickIdKey)[unref(clickIdKey).findIndex(el => el.id === maxid)].key;

  if (targetKey === "-none") {
    unref(chartOptions).series = [];
  } else if (resKeyAndSeries.key === targetKey) {
    unref(chartOptions).series = resKeyAndSeries.series;
  }
}
function setSyncChartOptions() {
  syncChartArray.value = [];

  const machine_timezone =
    unref(plantsMaster).filter(
      value => value.plant_id_dpac == unref(stateFilterData).selectplantid[0]
    )[0]?.timezone_id ?? "Asia/Tokyo";

  //dataにelを直接入れると表示されない
  unref(chartOptions).series.map((el, index) => {
    let syncchartBase = {
      chart: {
        marginLeft: 40,
        spacingTop: 20,
        spacingBottom: 20,
        backgroundColor: "transparent",
        style: { fontFamily: "Roboto", fontSize: "12px" }
      },
      navigator: { enabled: true },
      rangeSelector: {
        enabled: true,
        allButtonsEnabled: true,
        buttons: [
          { type: "minute", count: 1, text: i18n.t("history.zoom_min") },
          { type: "hour", count: 1, text: i18n.t("history.zoom_hour") },
          { type: "day", count: 1, text: i18n.t("history.zoom_day") },
          { type: "all", count: 1, text: i18n.t("history.zoom_all") }
        ],
        inputEnabled: false
      },
      time: { timezone: machine_timezone },
      title: {
        text: structuredClone(toRaw(unref(chartOptions).series[index].name)),
        align: "left",
        margin: 0,
        x: 30,
        style: {
          fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: "normal"
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        ordinal: false,
        crosshair: true,
        events: {
          setExtremes: syncExtremes
        }
      },
      yAxis: {
        title: {
          text: structuredClone(toRaw(unref(chartOptions).series[index].unit))
        },
        plotLines: unref(chartOptions).yAxis[index].plotLines
      },
      tooltip: {
        //thisにHighcharts.Tooltipがくる
        positioner: function () {
          return {
            //x: this.chart.chartWidth - this.label.width,
            x: 40,
            y: -20
          };
        },
        borderWidth: 0,
        backgroundColor: "none",
        pointFormat: "{point.y}",
        headerFormat: "",
        shadow: false,
        style: {
          fontSize: "18px"
        }
      },
      series: [
        {
          data: structuredClone(toRaw(unref(chartOptions).series[index].data)),
          name: structuredClone(toRaw(unref(chartOptions).series[index].name)),
          fillOpacity: 0.3,
          dataGrouping: {
            approximation: function (groupData) {
              if (groupData.hasNulls) {
                return null;
              }
              if (groupData.length > 1) {
                const absmax = groupData.reduce(function (acc, val) {
                  if (Math.abs(acc) === Math.max(Math.abs(acc), Math.abs(val)))
                    return acc;
                  else return val;
                });
                return absmax;
              } else {
                return groupData[0];
              }
            }
          }
        }
      ]
    };

    if (index !== 0) {
      syncchartBase.rangeSelector.enabled = false;
      syncchartBase.navigator.enabled = false;
    }
    unref(syncChartArray).push(syncchartBase);
  });
}
/*
 *Zoom範囲をsyncさせる
 */
function syncExtremes(e) {
  //thisにHighcharts.Axisがくる
  let thisChart = this.chart;
  if (e.trigger !== "syncExtremes") {
    // Prevent feedback loop
    Highcharts.charts.forEach(chart => {
      if (chart && chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) {
          // It is null while updating
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: "syncExtremes"
          });
        }
      }
    });
  }
}
/*
 *チャートのポインタをsyncさせる
 */
function syncChartPointers() {
  ["mousemove", "touchmove", "touchstart"].forEach(function (eventType) {
    document
      .getElementById("syncchart-container")
      .addEventListener(eventType, function (e) {
        var chart, point, i, event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
          chart = Highcharts.charts[i];
          if (chart !== undefined) {
            // Find coordinates within the chart
            event = chart.pointer.normalize(e);
            // Get the hovered point
            point = chart.series[0].searchPoint(event, true);
            if (point) {
              point.highlight(e);
            }
          }
        }
      });
  });

  Highcharts.Pointer.prototype.reset = function () {
    return undefined;
  };
  /**
   * Highlight a point by showing tooltip, setting hover state and draw crosshair
   */
  //thisにHighcharts.Pointがくる
  Highcharts.Point.prototype.highlight = function (event) {
    event = this.series.chart.pointer.normalize(event);

    this.onMouseOver(); // Show the hover marker
    //   this.series.chart.tooltip.refresh(this); // Show the tooltip ここをコメントにしないとエラー
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
  };
}
/**
 * データ取得方法のボタンが変更された時に呼ばれる
 */
function changeToggle(clickedButton) {
  selectedToggle.value = clickedButton;
  //ボタン変更時にキャッシュをクリア。選択は残したまま変更後のボタンの方法でデータを再取得する要件に対応
  viewdata_loaded.value = {};
  unref(chartOptions).series = [];
  syncChartArray.value = [];
  if (unref(reqParamsReady)) {
    fillData();
  }
}
/*
 * 選択中のノード情報を取得する。
 */
function getSelectNodes(node) {
  const selectedNodeIds = unref(selectnodes).map(node => node.id);
  if (selectedNodeIds.includes(node.id)) {
    selectnodes.value = structuredClone(toRaw(unref(selectnodes))).filter(
      selectNode => selectNode.id !== node.id
    );
  } else {
    unref(selectnodes).push(node);
  }
}
</script>
