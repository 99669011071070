<template>
  <v-footer app>
    <span>&copy; 2019</span>
    <v-spacer />
    <span>
      <button ref="manual" @click="downloadManual">
        <v-icon>mdi-book-open-variant</v-icon> manual
      </button>
    </span>
    <span class="ml-4">
      <a ref="version" @click="releaseNoteOpenRequest">
        version {{ appVersion }}
      </a>
    </span>
  </v-footer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { connectToAPI } from "@/helpers/util";
import { usePreference } from "@/stores/preference";
import { useUserinfo } from "@/stores/userinfo";
import { useVersionStore } from "@/stores/version";

const emit = defineEmits(["ReleaseNoteOpenRequest"]);

const i18n = useI18n();
const preferenceStore = usePreference();
const userinfoStore = useUserinfo();
const versionStore = useVersionStore();

const { darkmode, language } = storeToRefs(preferenceStore);
const { productivity } = storeToRefs(userinfoStore);
const { appVersion } = storeToRefs(versionStore);
const manual = ref(null);
const version = ref(null);

watch(darkmode, () => {
  setManualLinkColor();
  setVersionLinkColor();
});

onMounted(() => {
  setManualLinkColor();
  setVersionLinkColor();
});

function setManualLinkColor() {
  if (unref(darkmode)) {
    unref(manual).setAttribute("style", "color:white");
  } else {
    unref(manual).setAttribute("style", "color:black");
  }
}
function setVersionLinkColor() {
  if (unref(darkmode)) {
    unref(version).setAttribute("style", "color:white");
  } else {
    unref(version).setAttribute("style", "color:black");
  }
}
function releaseNoteOpenRequest() {
  emit("ReleaseNoteOpenRequest");
}
async function downloadManual() {
  try {
    const reqOptions = {
      method: "GET",
      url: "/api/generate-manual-url",
      params: {
        lang: unref(language) ?? "en",
        productivity: unref(productivity) ?? "1"
      }
    };

    const resp = await connectToAPI(reqOptions);
    const link = resp.data.manual_url;

    const anchor = document.createElement("a");
    anchor.setAttribute("href", link);
    anchor.setAttribute("target", "_blank");
    anchor.setAttribute("rel", "noopener noreferrer");

    const mouseEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window
    });

    anchor.dispatchEvent(mouseEvent);
  } catch (e) {
    window.alert(i18n.t("others.alart.download_manual_error"));
  }
}
</script>
<style scoped>
.v-application a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}
</style>
