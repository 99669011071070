import { defineStore } from "pinia";
import { ref, computed, unref } from "vue";
import { MD5 } from "crypto-js";

/**
 * ユーザー情報を保持するStore
 */
export const useUserinfo = defineStore("userinfo", () => {
  const isNotInitialized = ref(true);
  const email = ref("");
  const familyName = ref("");
  const givenName = ref("");
  const productivity = ref(0);

  const userPicture = computed<string>(() => {
    const defaultImage =
      "https://ssl.gstatic.com/s2/profiles/images/silhouette80.png";
    const valueMail = unref(email);
    if (valueMail === "") {
      return defaultImage;
    }
    const digest = MD5(valueMail).toString();
    return `https://secure.gravatar.com/avatar/${digest}?s=480&r=pg&d=${encodeURIComponent(defaultImage)}`;
  });

  /**
   * Storeの初期化を行うために実行する
   */
  function initialize(props: {
    familyName: string;
    givenName: string;
    email: string;
    productivity: number;
  }) {
    isNotInitialized.value = false;
    familyName.value = props.familyName;
    givenName.value = props.givenName;
    email.value = props.email;
    productivity.value = props.productivity;
  }

  return {
    isNotInitialized,
    email,
    familyName,
    givenName,
    productivity,
    userPicture,
    initialize
  };
});
