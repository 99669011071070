import { defineStore } from "pinia";
import { ref } from "vue";

export const useVersionStore = defineStore("version", () => {
  const appVersion = ref(__PACKAGE_VERSION__);

  return {
    appVersion
  };
});
