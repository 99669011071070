<template>
  <v-container fluid class="pa-0">
    <alart-snack-bar :alart-obj="alartObj" />
    <v-alert v-if="showAlert" type="warning">
      {{ i18n.t("condition.message1") }} [{{ alarmCount }}]<br />
      {{ i18n.t("condition.message2") }}
    </v-alert>
    <loading-bar :loading="isTimeDataloading" />
    <v-row class="mx-2 mt-2">
      <v-col class="pa-2" cols="6">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("condition.number_of_alarms")
            }}</v-toolbar-title>
          </v-toolbar>
          <highcharts :options="chartOptions" />
        </v-card>
      </v-col>
      <v-col class="pa-2" cols="6">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <toggle-buttons
              :contents="timeButtons"
              :default="currentTimeButton"
              class="px-4"
              @select-button="changeXaxis"
            />
          </v-toolbar>
          <highcharts :options="chartOptionsBar" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-2 mt-2">
      <v-col class="px-2 pb-4 pt-0" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent mb-6" flat>
            <v-col cols="8" class="px-4">
              <v-toolbar-title>{{
                i18n.t("condition.alarm_list")
              }}</v-toolbar-title>
            </v-col>
          </v-toolbar>
          <v-data-table
            density="comfortable"
            :headers="headers"
            hover
            :items="filteredListdata"
            :items-per-page="10"
            class="bg-transparent px-6 alerm-list"
          >
            <template #[`item.alarm_data_value`]="{ item }">
              {{ item.alarm_data_value.toFixed(2) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppCondition"
};
</script>
<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import Highcharts from "highcharts";
import heatInit from "highcharts/modules/heatmap";
import { storeToRefs } from "pinia";
import { computed, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import { connectToAPI } from "@/helpers/util";
import { useAlarmdetailStore } from "@/stores/alarmdetail";
import { useFilterDataStore } from "@/stores/filterData";
import { usePreference } from "@/stores/preference";

heatInit(Highcharts);

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const alarmdetailStore = useAlarmdetailStore();
const preferenceStore = usePreference();

const { alarmdetail } = storeToRefs(alarmdetailStore);
const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { darkmode, language } = storeToRefs(preferenceStore);
const alartObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});
const currentTimeButton = ref("day");
const categorized_process_alarms = ref({});
const listdataArr = ref([]);
const filteredListdata = ref([]);
const headers = ref([
  {
    title: i18n.t("condition.date_time"),
    align: "start",
    key: "alarm_timestamp"
  },
  { title: i18n.t("condition.customer"), key: "customer" },
  { title: i18n.t("condition.city_plant_no"), key: "city_plant_no" },
  { title: i18n.t("condition.order_no"), key: "order_no" },
  { title: i18n.t("condition.alarm_type"), key: "alarm_type" },
  { title: i18n.t("condition.component_type"), key: "component_type" },
  { title: i18n.t("condition.component_note"), key: "component_note" },
  { title: i18n.t("condition.measurement"), key: "measurement" },
  {
    title: i18n.t("condition.alarm_data_value"),
    key: "alarm_data_value"
  },
  { title: i18n.t("condition.data_unit"), key: "data_unit" }
]);
const alarmCount = ref(null);
const alarmSummary = ref([]);
//const alarmDetail = ref([]);
const chartOptions = ref({
  colors: ["#f45b5b"],
  chart: {
    type: "heatmap",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    marginTop: 40,
    marginBottom: 40,
    plotBorderWidth: 0
  },
  title: {
    text: null
  },
  colorAxis: {
    min: 0,
    minColor: "#ffffff",
    maxColor: "#f45b5b",
    labels: { style: { color: "#666666" } }
  },
  legend: {
    align: "right",
    layout: "vertical",
    margin: 0,
    verticalAlign: "top",
    y: 25,
    symbolHeight: 280,
    backgroundColor: "transparent"
  },
  series: [
    {
      allowPointSelect: true,
      name: "number or alarms",
      borderWidth: 0.5,
      data: [],
      dataLabels: {
        enabled: true,
        color: "#222222"
      },
      states: {
        select: {
          color: "#7cb5ec",
          enabled: true,
          borderColor: "#7cb5ec",
          borderWidth: 3
        }
      }
    }
  ],
  tooltip: { enabled: false },
  plotOptions: {
    series: {
      events: {
        click: e => {
          //クリック前の状態のようなので、selectedがfalseのときに選択したことになる->このあと選択状態になる。わかりにくいのでisSelectingNowは今回選択したか、とする
          const isSelectingNow = !e.point.selected;
          const process = isSelectingNow ? unref(reqProcess)[e.point.x] : null;
          const category = isSelectingNow
            ? unref(reqCategory)[e.point.y]
            : null;
          selectedOriginalProcessX.value = isSelectingNow
            ? unref(originalProcessX)[e.point.x]
            : null;
          selectedOriginalCategoryY.value = isSelectingNow
            ? unref(originalCategoryY)[e.point.y]
            : null;

          //selectmeasurement,selectcomponentNote,selectalarmTypeをクリアする
          //unref(stateFilterData).selectcomponentNote = [];
          //unref(stateFilterData).selectmeasurement = [];
          //unref(stateFilterData).selectalarmType = [];

          if (isSelectingNow) {
            changeXaxis(unref(currentTimeButton));
            getAlarmDetail(process, category);
          } else {
            filteredListdata.value = [];
            listdataArr.value = [];
            //選択解除時は日に強制変更する
            changeXaxis("day");
          }
        }
      }
    }
  },
  credits: { enabled: false },
  xAxis: {
    categories: [],
    opposite: true
  },
  yAxis: {
    categories: [],
    title: null,
    reversed: true
  }
});
const chartOptionsBar = ref({
  chart: {
    type: "column",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    zoomType: "xy"
  },
  title: {
    text: ""
  },
  legend: {
    enabled: true
  },
  series: [{ data: [] }],
  time: { useUTC: false },
  plotOptions: {
    column: {
      stacking: "normal"
    }
  },
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    useHTML: true,
    labels: {
      rotation: -45,
      max: null,
      min: null
    }
  },
  yAxis: {
    max: null,
    title: { text: i18n.t("condition.number_of_alarms") }
  }
});
const isTimeDataloading = ref(false);
const showAlert = ref(false);
const selectedOriginalProcessX = ref(null);
const selectedOriginalCategoryY = ref(null);
const originalProcessX = ref([
  "Feeding",
  "Printing",
  "Slotter Creaser",
  "Dual Slotter",
  "Die Cut",
  "Folding",
  "Counter",
  "Others"
]);
const originalCategoryY = ref([
  "Motor",
  "Blower",
  "Encoder",
  "Potentiometer",
  "Temperature",
  "Vibration"
]);
const reqProcess = ref([
  "feeding",
  "printing",
  "slotter_creaser",
  "dual_slotter",
  "die_cut",
  "folding",
  "counter",
  "other"
]);
const reqCategory = ref([
  "motor",
  "blower",
  "encoder",
  "potentiometer",
  "temperature",
  "vibration"
]);
//https://api.highcharts.com/highcharts/colors ここから黄色オレンジ赤に対応する色を選択した。
const barColorIndex = ref({
  1: "#e4d354",
  2: "#f7a35c",
  3: "#f15c80"
});
const process_category = ref([]);

const timeButtons = computed(() => {
  //ヒートマップをセルをクリックしている場合＝下のリストにデータが1以上ある場合はコンポーネントボタンを表示する
  if (unref(listdataArr).length) return ["day", "week", "month", "component"];
  else return ["day", "week", "month"];
});
const REQUEST_LIMIT = 7000;
const DATA_COUNT_LIMIT = 50000;

watch(
  stateFilterData,
  async () => {
    isTimeDataloading.value = true;
    let datacount = await getAlarmCount();
    alarmCount.value = datacount;

    if (datacount < DATA_COUNT_LIMIT) {
      await getAlarmSummary();
      await getProcessCategory();
    } else {
      showAlert.value = true;
      isTimeDataloading.value = false;
      return;
    }
    //アラーム種類のフィルタは上のgetAlarmSummaryでAPIにて実施済
    renderHeatmap();
    changeXaxis(unref(currentTimeButton));
    //TODO:こっちはアラームのフィルタないからいる←APIでやるようにいずれ変更
    filterDetail();
    showAlert.value = false;
    isTimeDataloading.value = false;
  },
  {
    deep: true,
    immediate: true
  }
);

async function getProcessCategory() {
  const reqOptions = {
    method: "GET",
    url: "/api/alarms/process_category",
    params: {
      region: unref(stateFilterData).selectregion,
      customer: unref(stateFilterData).selectcustomer,
      plant_id_dpac: unref(stateFilterData).selectplantid
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));
  process_category.value = response.data;
}
async function getAlarmCount() {
  let datacount;
  const reqOptions = {
    method: "GET",
    url: "/api/alarms/count",
    params: {
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to,
      region: unref(stateFilterData).selectregion,
      customer: unref(stateFilterData).selectcustomer,
      plant_id_dpac: unref(stateFilterData).selectplantid,
      alarm_type: unref(stateFilterData).selectalarmType,
      component_note: unref(stateFilterData).selectcomponentNote,
      measurement: unref(stateFilterData).selectmeasurement
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));
  datacount = response.data.count;
  return datacount;
}
async function getAlarmSummary() {
  const reqOptions = {
    method: "GET",
    url: "/api/alarms/summary",
    params: {
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to,
      region: unref(stateFilterData).selectregion,
      customer: unref(stateFilterData).selectcustomer,
      plant_id_dpac: unref(stateFilterData).selectplantid,
      lang: unref(language),
      alarm_type: unref(stateFilterData).selectalarmType,
      component_note: unref(stateFilterData).selectcomponentNote,
      measurement: unref(stateFilterData).selectmeasurement
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));
  alarmSummary.value = response.data;
}
async function getAlarmDetail(process, category) {
  isTimeDataloading.value = true;
  listdataArr.value = [];
  let i = 0;
  let tmpdata = [];

  do {
    const reqOptions = {
      method: "GET",
      url: "/api/alarms/detail",
      params: {
        date_from: unref(stateFilterData).from,
        date_to: unref(stateFilterData).to,
        region: unref(stateFilterData).selectregion,
        customer: unref(stateFilterData).selectcustomer,
        plant_id_dpac: unref(stateFilterData).selectplantid,
        lang: unref(language),
        category: category,
        process: process,
        limit: REQUEST_LIMIT,
        offset: REQUEST_LIMIT * i
      }
    };
    const response = await connectToAPI(reqOptions, unref(alartObj));
    tmpdata = response.data;
    listdataArr.value = unref(listdataArr).concat(tmpdata.value);
    i++;
  } while (tmpdata.continue);

  alarmdetail.value = unref(listdataArr);

  filterDetail();
  isTimeDataloading.value = false;
}
/**
 * 表示データをつくるメソッド
 */
function renderHeatmap() {
  if (unref(darkmode)) {
    unref(chartOptions).colorAxis.minColor = "#424242";
    unref(chartOptions).colorAxis.labels.style.color = "#ffffff";
  }
  unref(chartOptions).xAxis.categories = unref(originalProcessX);
  unref(chartOptions).yAxis.categories = unref(originalCategoryY);

  //プロセス＞カテゴリでまとめたオブジェクトに変更
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  let process_alarms = groupBy(unref(alarmSummary), "process");

  const groupByCategory = xs => {
    return xs.reduce(function (rv, x) {
      Object.keys(rv).find(el => el === x.category)
        ? (rv[x.category] += x.count)
        : (rv[x.category] = x.count);
      return rv;
    }, {});
  };

  Object.keys(process_alarms).forEach(key => {
    let val_ary = process_alarms[key];
    process_alarms[key] = groupByCategory(val_ary);
  });
  //プロセス＞カテゴリでまとめたオブジェクト
  categorized_process_alarms.value = process_alarms;
  //ヒートマップデータ
  unref(chartOptions).series[0].data = [];

  for (let [ix, x] of unref(chartOptions).xAxis.categories.entries()) {
    for (let [iy, y] of unref(chartOptions).yAxis.categories.entries()) {
      //プロセス＞カテゴリでまとめたオブジェクトに今のプロセス、カテゴリの組み合わせがある
      if (
        unref(categorized_process_alarms) &&
        unref(categorized_process_alarms)[x] &&
        unref(categorized_process_alarms)[x][y]
      ) {
        unref(chartOptions).series[0].data.push({
          x: ix,
          y: iy,
          value: unref(categorized_process_alarms)[x][y],
          color: undefined,
          selected: false
        });
      } else {
        //プロセス＞カテゴリでまとめたオブジェクトがprocess_categoryにある→取得したデータで0だっただけ
        if (
          unref(process_category).some(
            obj => obj.category === y && obj.process === x
          )
        ) {
          unref(chartOptions).series[0].data.push({
            x: ix,
            y: iy,
            value: 0,
            color: undefined,
            selected: false
          });
        } else {
          unref(chartOptions).series[0].data.push({
            x: ix,
            y: iy,
            value: "-",
            color: "#999999",
            selected: false
          });
        }
      }
    }
  }

  unref(chartOptions).xAxis.categories = [
    i18n.t("condition.feeding"),
    i18n.t("condition.printing"),
    i18n.t("condition.slotter_creaser"),
    i18n.t("condition.dual_slotter"),
    i18n.t("condition.die_cut"),
    i18n.t("condition.folding"),
    i18n.t("condition.counter"),
    i18n.t("condition.other")
  ];

  unref(chartOptions).yAxis.categories = [
    i18n.t("condition.motor"),
    i18n.t("condition.blower"),
    i18n.t("condition.encoder"),
    i18n.t("condition.potentiometer"),
    i18n.t("condition.temperature"),
    i18n.t("condition.vibration")
  ];
}
/**
 * 棒グラフX軸変更
 */
function changeXaxis(clickedButton) {
  currentTimeButton.value = clickedButton;
  if (clickedButton !== "component") {
    unref(chartOptionsBar).xAxis.type = "datetime";
    unref(chartOptionsBar).xAxis.categories = null;
    renderTimeBar(clickedButton);
  } else {
    const groupBy = (xs, key) => {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    //棒グラフ用データ
    //日付と数のオブジェクトの配列をつくる
    //まずアラームタイプで分ける
    const obj_groupByAlarm = groupBy(unref(listdataArr), "alarm_type");
    //カラムの配列を作る
    const list_colmun = Object.keys(groupBy(unref(listdataArr), "column_name"));
    //component_type component_note measurementの配列を作る
    const list_ccm = list_colmun.map(column_name => {
      let return_name = "";
      unref(listdataArr).forEach(obj => {
        if (obj.column_name === column_name)
          return_name =
            obj.component_type +
            "<br>" +
            obj.component_note +
            "<br>" +
            obj.measurement;
      });
      return return_name;
    });
    //アラーム毎にカラム毎の数を集約する
    let series_col_alarmtype_count = [];
    Object.keys(obj_groupByAlarm).forEach(alarmType => {
      //カラムで集約
      const groupByCol = groupBy(obj_groupByAlarm[alarmType], "column_name");
      Object.keys(groupByCol).forEach(column => {
        //すでにseries_col_alarmtype_countに対象のアラームのオブジェクトが有るかどうか
        if (
          series_col_alarmtype_count.filter(
            obj_series => obj_series.name === alarmType
          ).length
        ) {
          //いるならそこのdataに追加
          series_col_alarmtype_count
            .filter(obj_series => obj_series.name === alarmType)[0]
            .data.push({
              x: list_colmun.indexOf(column),
              y: groupByCol[column] ? groupByCol[column].length : 0,
              name: column
            });
        } else {
          //いないならそのアラームタイプのデータから作る
          series_col_alarmtype_count.push({
            name: alarmType,
            data: [
              {
                x: list_colmun.indexOf(column),
                y: groupByCol[column] ? groupByCol[column].length : 0,
                name: column
              }
            ],
            color: unref(barColorIndex)[Number(alarmType.slice(0, 1))],
            legendIndex: Number(alarmType.slice(0, 1))
          });
        }
      });
    });
    unref(chartOptionsBar).series = series_col_alarmtype_count;
    unref(chartOptionsBar).xAxis.type = "category";
    unref(chartOptionsBar).xAxis.categories = list_ccm;
  }
}
/**
 * 棒グラフ描画
 */
function renderTimeBar(clickedButton) {
  let timescale_type = "";

  //切替ボタンクリック時
  if (clickedButton !== "component") {
    unref(chartOptionsBar).xAxis.type = "datetime";
    unref(chartOptionsBar).xAxis.categories = null;
    timescale_type = clickedButton;
  }

  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  //棒グラフ用データ
  //日付と数のオブジェクトの配列をつくる
  //まずアラームタイプで分ける
  const typeAlarms = groupBy(unref(alarmSummary), "alarm_type");
  let series = [];
  //各アラームタイプで日付まとめ実施

  Object.keys(typeAlarms).forEach(keyAlarmType => {
    const datebycount = typeAlarms[keyAlarmType].reduce((result, current) => {
      //ヒートマップが選択されたときは、選択対象ではないカテゴリ、プロセスのデータは弾く
      if (
        unref(selectedOriginalCategoryY) &&
        unref(selectedOriginalCategoryY) != current.category
      ) {
        return result;
      }
      if (
        unref(selectedOriginalProcessX) &&
        unref(selectedOriginalProcessX) != current.process
      ) {
        return result;
      }

      let currentdate = null;
      if (timescale_type === "day") {
        currentdate = dayjs(current.alarm_date).toDate();
      } else if (timescale_type === "week") {
        currentdate = dayjs(current.week_start).toDate();
      } else {
        currentdate = dayjs(current.alarm_date).date(1).toDate();
      }
      //どういう日付でまとめるか←必要性がなぞ
      //const printdate =
      //  currentdate.getFullYear() +
      //  "-" +
      //  (currentdate.getMonth() + 1) +
      //  "-" +
      //  currentdate.getDate();
      const element = result.find(p => p.x.getTime() === currentdate.getTime());
      if (element) {
        element.y += current.count;
      } else {
        result.push({
          x: currentdate,
          y: current.count
        });
      }
      return result;
    }, []);
    let sorteddatebycount = datebycount.sort(
      (a, b) => a.x.getTime() - b.x.getTime()
    );

    //フィルタの日付指定されている場合は、サマリーデータがその日付から始めるか確認。そうでない場合は0データを入れる
    //alarmSummaryが1つ以上あるならする
    let filterFromDate = "";
    if (timescale_type === "day") {
      filterFromDate = dayjs(unref(stateFilterData).from).toDate();
    } else if (timescale_type === "week") {
      filterFromDate = dayjs(unref(stateFilterData).from)
        .subtract((dayjs(unref(stateFilterData).from).day() - 1) % 7, "day")
        .toDate();
    } else {
      filterFromDate = dayjs(unref(stateFilterData).from).date(1).toDate();
    }
    if (
      unref(stateFilterData).from &&
      sorteddatebycount[0] &&
      sorteddatebycount[0].x > filterFromDate
    ) {
      sorteddatebycount.unshift({
        x: filterFromDate,
        y: 0
      });
    }
    //フィルタの日付指定されている場合は、サマリーデータがその日付から終わっているか確認。そうでない場合は0データを入れる
    //alarmSummaryが1つ以上あるならする
    let filterToDate = "";
    if (timescale_type === "day") {
      filterToDate = dayjs(unref(stateFilterData).to).toDate();
    } else if (timescale_type === "week") {
      filterToDate = dayjs(unref(stateFilterData).to)
        .subtract((dayjs(unref(stateFilterData).to).day() - 1) % 7, "day")
        .toDate();
    } else {
      filterToDate = dayjs(unref(stateFilterData).to).date(1).toDate();
    }
    if (
      unref(stateFilterData).to &&
      sorteddatebycount[0] &&
      sorteddatebycount[sorteddatebycount.length - 1].x < filterToDate
    ) {
      sorteddatebycount.push({
        x: filterToDate,
        y: 0
      });
    }
    series.push({
      data: sorteddatebycount,
      name: keyAlarmType,
      borderWidth: 0,
      legendIndex: Number(keyAlarmType.slice(0, 1)),
      index: Number(keyAlarmType.slice(0, 1)),
      color: unref(barColorIndex)[Number(keyAlarmType.slice(0, 1))]
    });
  });

  unref(chartOptionsBar).series = series;
}
function filterDetail() {
  const funcFilter = item => {
    return (
      (unref(stateFilterData).selectalarmType.length === 0 ||
        unref(stateFilterData).selectalarmType.indexOf(item.alarm_type) >= 0) &&
      (unref(stateFilterData).selectcomponentNote.length === 0 ||
        unref(stateFilterData).selectcomponentNote.indexOf(
          item.component_note
        ) >= 0) &&
      (unref(stateFilterData).selectmeasurement.length === 0 ||
        unref(stateFilterData).selectmeasurement.indexOf(item.measurement) >= 0)
    );
  };
  filteredListdata.value = unref(listdataArr).filter(funcFilter);
}
</script>

<style scoped>
:deep(.alerm-list.v-data-table table thead tr th) {
  font-size: 12px;
  font-weight: 700;
}
</style>
