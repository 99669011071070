<template>
  <v-row class="ma-0">
    <v-col class="pa-4">
      <div class="mb-1 subtitle">
        {{ i18n.t("report.frequent_stops.organized_result") }}
      </div>
      <v-data-table
        density="comfortable"
        :headers="organizedResultHeaders"
        hover
        :items="viewdata[0]"
        class="elevation-1 pattern"
      >
        <template #[`item`]="{ item }">
          <tr>
            <th class="pattern-th">
              {{ i18n.rt(i18n.tm("frequentstops.pattern")[item.pattern]) }}
            </th>
            <td class="text-end">{{ item.ratio.toFixed(1) }}%</td>
            <td class="text-end">
              {{ item.order }}
            </td>
          </tr>
        </template>
        <template #bottom></template>
      </v-data-table>
      <div class="mb-1 mt-8 subtitle">
        {{ i18n.t("report.frequent_stops.advice_for_improving_productivity") }}
      </div>
      <v-data-table
        density="comfortable"
        :headers="improvingProductivityHeaders"
        hover
        :items="viewdata[1]"
        class="elevation-1 hint"
      >
        <template #headers></template>
        <template #[`item`]="{ item }">
          <tr>
            <td>
              {{ i18n.rt(i18n.tm("frequentstops.pattern_hint")[item.pattern]) }}
            </td>
          </tr>
        </template>
        <template #bottom></template>
      </v-data-table>
    </v-col>
    <v-divider vertical class="ma-2"></v-divider>
    <v-col class="pa-4">
      <v-col class="pt-0">
        <div class="mb-1 subtitle">
          {{
            i18n.t(
              "report.frequent_stops.production_status_with_significant_changes"
            )
          }}
        </div>
        <v-data-table
          density="comfortable"
          :headers="productionStatusHeaders"
          :items="viewdata[2]"
          class="elevation-1 status"
        >
          <template #[`item`]="{ item }">
            <tr>
              <td>
                {{ i18n.rt(i18n.tm("frequentstops.status")[item.status]) }}
              </td>
              <td>{{ item.date }}</td>
              <td>
                {{
                  i18n.rt(i18n.tm("frequentstops.status_advice")[item.status])
                }}
              </td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
      </v-col>
    </v-col>
  </v-row>
</template>
<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  }
});

const i18n = useI18n();

const organizedResultHeaders = ref([
  { title: "", value: "pattern", width: "55%" },
  {
    title: i18n.t("report.frequent_stops.ratio"),
    value: "ratio",
    width: "22.5%"
  },
  {
    title: i18n.t("report.frequent_stops.number_of_orders"),
    value: "order",
    width: "22.5%"
  }
]);
const productionStatusHeaders = ref([
  {
    title: i18n.t("report.frequent_stops.status"),
    value: "status",
    width: "20%"
  },
  {
    title: i18n.t("report.frequent_stops.date_and_time"),
    value: "date",
    width: "20%"
  },
  {
    title: i18n.t("report.frequent_stops.advice"),
    value: "status",
    width: "60%"
  }
]);
const improvingProductivityHeaders = ref([
  {
    title: i18n.t("report.frequent_stops.number_of_orders"),
    value: "hint"
  }
]);
</script>

<style scoped>
.subtitle {
  font-weight: bold;
  font-size: 18px !important;
  color: #6b6d75;
}
:deep(.pattern.v-data-table table thead tr th:nth-of-type(n + 2)) {
  background-color: #f3f3f4f4;
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
:deep(.pattern.v-data-table table thead tr th:nth-of-type(-n + 2)) {
  background-color: #f3f3f4f4;
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
.pattern-th {
  font-weight: normal;
  font-size: 12px !important;
  color: #080b1999;
  background-color: #f3f3f4;
  border: solid #ceced1;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.pattern.v-data-table tbody td {
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px !important;
}
.pattern.v-data-table {
  white-space: pre-line;
  border: solid #ceced1;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
}
:deep(.status.v-data-table table thead tr th) {
  background-color: #f3f3f4f4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
.v-data-table.status {
  white-space: pre-line;
  border: solid #ceced1;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.status table tbody td {
  background-color: rgb(255, 255, 255);
  font-size: 12px !important;
}
.hint table tbody td {
  font-size: 12px !important;
}
.v-data-table.hint {
  white-space: pre-line;
  border: solid #ceced1;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
}
</style>
