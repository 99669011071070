<template>
  <v-snackbar
    v-model="isShowRef"
    timeout="10000"
    location="top right"
    :color="props.type"
    :multi-line="props.title !== ''"
  >
    <v-row align="center">
      <v-col class="icon">
        <v-icon size="large">{{ prefixIcon }}</v-icon>
      </v-col>
      <v-col cols="auto">
        <div>
          <strong>{{ props.title }}</strong>
        </div>
        <div>{{ props.message }}</div>
      </v-col>
    </v-row>
    <template #actions>
      <v-btn variant="text" @click="isShowRef = false">
        <v-icon size="x-large">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, type SetupContext } from "vue";

type Emits = {
  "update:modelValue": (val: boolean) => void;
};

type Props = {
  modelValue: boolean;
  title: string;
  message: string;
  type: string;
};

export default defineComponent({
  name: "AlertSnackBar",

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "error"
    }
  },

  emits: ["update:modelValue"],

  setup(props: Props, context: SetupContext<Emits>) {
    const prefixIcon = computed(() => {
      if (props.type == "error") return "mdi-alert-octagon-outline";
      else if (props.type == "warning") return "mdi-alert-outline";
      else if (props.type == "success") return "mdi-checkbox-marked-circle";
      else return "";
    });

    const isShowRef = ref<boolean>(false);

    //親からtrueをもらったときにスナック・バーを表示させる
    watch(
      () => props.modelValue,
      (val: boolean) => {
        isShowRef.value = val;
      }
    );

    //スナックバーが時間経過で消えたときにv-model経由でそのフラグを返す
    watch(
      () => isShowRef.value,
      (val: boolean) => {
        context.emit("update:modelValue", val);
      }
    );

    return { props, prefixIcon, isShowRef };
  }
});
</script>
<style scoped>
.icon {
  flex-grow: 0;
}
</style>
