<template>
  <v-container class="pa-3" fluid>
    <loading-bar :loading="isLoading" />
    <v-alert v-if="errored" type="warning">
      {{ i18n.t("report.common.error_msg") }}
      <v-btn color="black" @click="clickReRequestButton">{{
        i18n.t("report.common.re_request")
      }}</v-btn>
    </v-alert>

    <v-row>
      <v-col cols="12" class="report-title-container">
        <div class="report-title">
          <v-select
            v-model="plant_id_dpac"
            density="comfortable"
            :items="plant_select_items"
            item-title="text"
            item-value="value"
            variant="solo"
            @update:model-value="changePlant"
          />
        </div>
        <div class="report-title report-title-fix-vertical">
          <h2>
            <template v-if="isJa">{{ titleJa }}</template>
            <template v-else>{{ titleEn }}</template>
          </h2>
        </div>
        <div class="report-title report-title-fix-vertical">
          <v-btn
            color="grey-lighten-4"
            :disabled="isLatestMonth"
            @click="clickLatestMonthButton"
            >{{ i18n.t("report.common.latest") }}</v-btn
          >
          <v-btn
            color="grey-lighten-4"
            :disabled="isFirstMonth"
            class="btn-pagination"
            @click="clickPreviousMonthButton"
          >
            <v-icon size="x-large">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            color="grey-lighten-4"
            :disabled="hasNotNextMonth"
            class="btn-pagination"
            @click="clickNextMonthButton"
          >
            <v-icon size="x-large">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-spacer />
    </v-row>

    <v-card class="translucent" rounded="0">
      <div class="pl-4 pt-3 summary">{{ i18n.t("report.summary.title") }}</div>
      <summary-table :viewdata="summary" />
    </v-card>
    <v-card class="translucent mt-7" rounded="0">
      <div class="pl-4 pt-3 summary">{{ i18n.t("report.unit.title") }}</div>
      <unit-table :viewdata="unit" />
      <v-divider class="ma-2"></v-divider>
      <stop-factor-table :viewdata="stopFactor" />
    </v-card>
    <v-card class="translucent mt-7" rounded="0">
      <div class="pl-4 pt-3 summary">
        {{ i18n.t("report.frequent_stops.title") }}
      </div>
      <frequent-stops-table :viewdata="frequentStops" />
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AppReport"
};
</script>
<script setup>
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, onMounted, onBeforeUnmount, ref, unref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import SummaryTable from "@/components/report/SummaryTable.vue";
import UnitTable from "@/components/report/UnitTable.vue";
import StopFactorTable from "@/components/report/StopFactorTable.vue";
import FrequentStopsTable from "@/components/report/FrequentStopsTable.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import { connectToAPI } from "@/helpers/util";
import { useDrawerFlgStore } from "@/stores/drawerFlg";
import { usePreference } from "@/stores/preference";

import ReportTargetPlants from "@/assets/report_target_plants.json";

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const drawerFlgStore = useDrawerFlgStore();
const preferenceStore = usePreference();

const { drawerFlg } = storeToRefs(drawerFlgStore);
const { darkmode, language } = storeToRefs(preferenceStore);
const prevDarkMode = ref(false);
const plant_id_dpac = ref(null);
const summary = ref([]);
const unit = ref([]);
const stopFactor = ref([]);
const frequentStops = ref([]);
const errored = ref(false);
const yyyymm = ref(null);
const valid_plants = ref([]);
const valid_yyyymm_list = ref([]);
const isLoading = ref(true);

/**
 * 現在表示しているレポートの月を示すDayjsオブジェクト
 * @return {dayjs.Dayjs}
 */
const dayjsObject = computed(() => {
  return dayjs(unref(yyyymm));
});
/**
 * API /api/reportを叩く際のQueryStringを生成する
 * @return {{}|{plant_id_dpac: String, yyyymm: String}}
 */
const createGetReportQuery = computed(() => {
  if (unref(plant_id_dpac) != null && unref(yyyymm) != null) {
    return {
      plant_id_dpac: unref(plant_id_dpac),
      yyyymm: unref(yyyymm)
    };
  } else {
    // plant_id_dpacとyyyymmがnullの時には
    return {};
  }
});
/**
 * プラント選択用にselect boxの要素を算出する
 * @return {{text: string, value: string}[]}
 */
const plant_select_items = computed(() => {
  // 定義された5プラントの情報を抽出
  const targetPlantData = unref(valid_plants).filter(item =>
    ReportTargetPlants.includes(item.plant_id_dpac)
  );
  return targetPlantData.map(item => {
    return {
      value: item.plant_id_dpac,
      text: `${item.customer_name} ${item.plant_id_dashboard}`
    };
  });
});
/**
 * ロケールが日本か確認する
 * @return {boolean}
 */
const isJa = computed(() => {
  return unref(language) === "ja";
});
/**
 * 日本語用のレポートタイトルを算出する
 * @return {string}
 */
const titleJa = computed(() => {
  const year = unref(dayjsObject).year().toString();
  const month = (unref(dayjsObject).month() + 1).toString().padStart(2);
  return `${year}年 ${month}月 レポート`;
});
/**
 * 英語用のレポートタイトルを算出する
 * @return {string}
 */
const titleEn = computed(() => {
  const year = unref(dayjsObject).year();
  const month = (unref(dayjsObject).month() + 1).toString();
  const listMonth = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };
  return `${listMonth[month]} ${year} Report`;
});
/**
 * 現在表示している月のインデックス (全てのレポートの月における)
 * @return {number}
 */
const indexValidYyyyMmList = computed(() => {
  return unref(valid_yyyymm_list).findIndex(item => item === unref(yyyymm));
});
/**
 * 今表示しているレポートが最新の月かどうかを判定する
 * @return {boolean}
 */
const isLatestMonth = computed(() => {
  // 有効な月を持たない場合は最新の月とする (最新ボタンをdisabledに渡している真偽値なので)
  if (unref(valid_yyyymm_list).length === 0) {
    return true;
  }
  return (
    unref(valid_yyyymm_list)[unref(valid_yyyymm_list).length - 1] ===
    unref(yyyymm)
  );
});
/**
 * 今表示しているレポートより未来のレポートが存在しないかを確認する
 * @return {boolean}
 */
const hasNotNextMonth = computed(() => {
  // 有効な月の一覧に現在表示している月が含まれないときは、未来のレポートは存在しないとする
  // プラントを切り替えたとき、表示する月は維持するためレポートが存在しないこともそうていされるため
  if (unref(indexValidYyyyMmList) === -1) {
    return true;
  }
  return unref(isLatestMonth);
});
/**
 * 今表示しているレポートより
 * @return {boolean}
 */
const isFirstMonth = computed(() => {
  // 有効な月の一覧に現在表示している月が含まれないときは、未来のレポートは存在しないとする
  // プラントを切り替えたとき、表示する月は維持するためレポートが存在しないこともそうていされるため
  // 有効な月を持たない場合もTrueとし、ボタンを押せないようにしている
  if (
    unref(indexValidYyyyMmList) === -1 ||
    unref(valid_yyyymm_list).length === 0
  ) {
    return true;
  }
  return unref(valid_yyyymm_list)[0] === unref(yyyymm);
});

onMounted(async () => {
  // フィルタを閉じる
  drawerFlg.value = false;

  // 強制的にDarkModeを解除
  prevDarkMode.value = unref(darkmode);
  darkmode.value = false;

  // QueryStringから表示するレポートを指定する
  loadQueryString();

  // レポートを取得する
  await getReportAllData();
});

onBeforeUnmount(() => {
  // 他画面に遷移時にフィルタを復活
  drawerFlg.value = true;

  // DarkModeの設定をレストア
  darkmode.value = unref(prevDarkMode);
});

/**
 * 表示しているレポートをクリアする
 * @param isAll {boolean} 全てのデータをクリアするかを示すフラグ
 */
function clearData(isAll) {
  summary.value = [];
  unit.value = [];
  stopFactor.value = [];
  frequentStops.value = [];

  // 引数でtrueを指定したとき有効なプラント一覧や月の一覧も削除する
  if (isAll) {
    valid_plants.value = [];
    plant_id_dpac.value = null;
  }
}
/**
 * レポートを取得する
 * @return {Promise<void>}
 */
async function getReportAllData() {
  // 初手でローディングを表示させる
  isLoading.value = true;
  const reqOptions = {
    method: "GET",
    url: "/api/report",
    params: unref(createGetReportQuery)
  };
  try {
    const response = await connectToAPI(reqOptions);
    const report = response.data.report;
    if (report != null) {
      // summary用データ
      // v-data-table用に配列に変換
      const summaryArray = [];
      if (report.summary != null) {
        summaryArray.push(report.summary);
      }
      summary.value = summaryArray;
      // unit用データ
      // v-data-table用に配列に変換
      const faultUnitArray = report["fault_unit"] || [];
      const repairUnitArray = report["repair_unit"] || [];
      unit.value = [faultUnitArray, repairUnitArray];
      // stop factor用データ
      // v-data-table用に配列に変換
      const stopFactorArray = [];
      if (report["stop_factor_error"] != null) {
        stopFactorArray.push(report["stop_factor_error"]);
      }
      if (report["stop_factor_unit"] != null) {
        stopFactorArray.push(report["stop_factor_unit"]);
      }
      stopFactor.value = stopFactorArray;
      // frequentStops用データ
      // v-data-table用に配列に変換
      const frequentStopsArray = [];
      // 生産パターン用
      if (report?.production_pattern?.pattern != null) {
        frequentStopsArray.push(report["production_pattern"]["pattern"]);
      }
      // 生産パターン-ヒント用
      if (report?.production_pattern?.hint != null) {
        frequentStopsArray.push(report["production_pattern"]["hint"]);
      }
      // 生産ステータス用
      if (report["production_status"] != null) {
        frequentStopsArray.push(report["production_status"]);
      }
      frequentStops.value = frequentStopsArray;
    } else {
      summary.value = [];
      unit.value = [];
      stopFactor.value = [];
      frequentStops.value = [];
    }

    valid_plants.value = response.data.valid_plants;
    valid_yyyymm_list.value = response.data.valid_yyyymm_list;
    yyyymm.value = response.data.yyyymm;
    plant_id_dpac.value = response.data.plant_id_dpac;

    errored.value = false;
  } catch (e) {
    errored.value = true;
    clearData(false);
  } finally {
    // 何があってもローディングを終了させる
    isLoading.value = false;
  }
}
/**
 * 最新ボタンを押したときの処理
 * @return {Promise<void>}
 */
async function clickLatestMonthButton() {
  yyyymm.value = unref(valid_yyyymm_list)[unref(valid_yyyymm_list).length - 1];
  await updateQueryString();
  await getReportAllData();
}
/**
 * 右矢印のボタンを押した時の処理
 * @return {Promise<void>}
 */
async function clickNextMonthButton() {
  const index = unref(valid_yyyymm_list).findIndex(
    item => item === unref(yyyymm)
  );
  yyyymm.value = unref(valid_yyyymm_list)[index + 1];
  await updateQueryString();
  await getReportAllData();
}
/**
 * 左矢印のボタンを押した時の処理
 * @return {Promise<void>}
 */
async function clickPreviousMonthButton() {
  const index = unref(valid_yyyymm_list).findIndex(
    item => item === unref(yyyymm)
  );
  yyyymm.value = unref(valid_yyyymm_list)[index - 1];
  await updateQueryString();
  await getReportAllData();
}
/**
 * エラー時にレポートを再取得するためのボタンを押した際の処理
 * @return {Promise<void>}
 */
async function clickReRequestButton() {
  await getReportAllData();
}
/**
 * 表示するプラントを切り替えた時の処理
 * @return {Promise<void>}
 */
async function changePlant() {
  await updateQueryString();
  await getReportAllData();
}
/**
 * QueryStringParametersから必要な値を読み込む
 */
function loadQueryString() {
  const query = route.query;
  if (query?.plant_id_dpac != null) {
    plant_id_dpac.value = query?.plant_id_dpac;
  }
  if (query?.yyyymm != null) {
    const date = dayjs(query?.yyyymm);
    if (date.isValid()) {
      yyyymm.value = date.format("YYYYMM");
    }
  }
}
/**
 * QueryStringParametersを更新する
 */
async function updateQueryString() {
  const query = Object.assign({}, route.query);
  query.plant_id_dpac = unref(plant_id_dpac);
  query.yyyymm = unref(yyyymm);
  await router.push({ query: query });
}
</script>
<style scoped>
.summary {
  font-size: 20px !important;
  font-weight: bold;
  color: #212330;
}
div.report-title-container {
  display: flex;
  align-items: flex-start;
}
div.report-title {
  display: inline-block;
  margin-right: 0.5rem;
}
div.report-title.report-title-fix-vertical {
  margin-top: 8px;
}
button.v-btn {
  margin-right: 0.5rem;
}
button.v-btn.btn-pagination {
  min-width: 36px;
  width: 36px;
}
</style>
