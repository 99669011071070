<template>
  <v-row>
    <v-col class="pa-7">
      <div class="mb-1 subtitle">
        {{ i18n.t("report.stop_factor.stop_factor_error") }}
      </div>
      <v-data-table
        density="comfortable"
        :headers="errorHeaders"
        hover
        :items="viewdata[0]"
        class="elevation-1"
      >
        <template #[`item`]="{ item }">
          <tr>
            <th class="rank-th">{{ item.rank }}.</th>
            <th class="errorcode-th">
              <p
                v-for="line in errorSplit(
                  'stopfactor_error.meaning',
                  item.errors
                )"
                :key="line"
                class="errorcode-th"
              >
                {{ line }}
              </p>
            </th>
            <td class="text-end">
              {{ item.error_count }}
            </td>
            <td class="text-end">
              {{ item.stop_time.toFixed(1) }}
            </td>
            <td>
              {{ causeOfOccurrence(item.errors) }}
            </td>
            <td>
              {{ errorCounterMeasure(item.errors) }}
            </td>
          </tr>
        </template>
        <template #bottom></template>
      </v-data-table>
      <div id="stop-factor-unit" class="mb-1 subtitle">
        {{ i18n.t("report.stop_factor.stop_factor_unit") }}
      </div>
      <v-data-table
        density="comfortable"
        :headers="unitHeaders"
        hover
        :items="convertUnitData(viewdata[1])"
        class="elevation-1"
      >
        <template #[`item`]="{ item }">
          <tr>
            <th class="rank-th">{{ item.rank }}.</th>
            <th class="errorcode-th">{{ item.unit }}</th>
            <td>{{ item.errors }}</td>
          </tr>
        </template>
        <template #bottom></template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  }
});

const i18n = useI18n();

const errorHeaders = ref([
  { title: "", value: "rank", width: "1%" },
  { title: "", value: "errors", width: "25%" },
  {
    title: i18n.t("report.stop_factor.error_count"),
    value: "erro_count",
    width: "7%"
  },
  {
    title: i18n.t("report.stop_factor.stop_time"),
    value: "stop_time",
    width: "7%"
  },
  {
    title: i18n.t("report.stop_factor.cause_of_occurrence"),
    value: "cause_of_occurrence",
    width: "25%"
  },
  {
    title: i18n.t("report.stop_factor.countermeasure"),
    value: "countermeasure",
    width: "35%"
  }
]);
const unitHeaders = ref([
  { title: "", value: "rank", width: "1%" },
  { title: "", value: "unit", width: "25%" },
  {
    title: i18n.t("report.stop_factor.check_meaning"),
    value: "unit",
    width: "74%"
  }
]);

function convertUnitData(data) {
  if (typeof data !== "undefined") {
    let obj = {};
    let convertErrors = "";
    const convertData = [];
    for (const element of data) {
      obj.unit = i18n.rt(i18n.tm("stopfactor_unit.unit")[element["unit"]]);
      obj.rank = element["rank"];
      for (const error of element["errors"]) {
        convertErrors +=
          i18n.rt(i18n.tm("stopfactor_unit.error_code")[error]) + "、";
      }
      // 最後のカンマを削除
      obj.errors = convertErrors.slice(0, -1);
      convertData.push(obj);
      // 初期化
      obj = {};
      convertErrors = "";
    }
    return convertData;
  }
}
/**
 * "停止要因エラーの対処"のエラー名をカンマSplitする
 */
function errorSplit(name, errors) {
  try {
    const text = i18n.rt(i18n.tm(name)[errors]);
    if (!text) {
      return [];
    }
    return text.split(",").map(item => item.trim());
  } catch (error) {
    console.error(
      `StopFactorTable Error occurred at errorSplit. name: ${name} errors: ${errors}  error:`,
      error
    );
    return [i18n.t("report.stop_factor.undefined_error")];
  }
}

function causeOfOccurrence(errors) {
  try {
    return i18n.rt(i18n.tm("stopfactor_error.cause_of_occurrence")[errors]);
  } catch (error) {
    console.error(
      `StopFactorTable Error occurred at causeOfOccurrence. errors: ${errors}  error:`,
      error
    );
    return "";
  }
}

function errorCounterMeasure(errors) {
  try {
    return i18n.rt(i18n.tm("stopfactor_error.countermeasure")[errors]);
  } catch (error) {
    console.error(
      `StopFactorTable Error occurred at errorCounterMeasure. errors: ${errors}  error:`,
      error
    );
    return "";
  }
}
</script>

<style scoped>
.subtitle {
  font-weight: bold;
  font-size: 18px !important;
  color: #6b6d75;
}
:deep(.v-data-table table thead tr th:nth-of-type(n + 2)) {
  background-color: #f3f3f4f4;
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 700;
}
:deep(.v-data-table table thead tr th:nth-of-type(-n + 2)) {
  background-color: #f3f3f4f4;
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
}
:deep(.v-data-table table thead tr th:nth-of-type(-n + 1)) {
  background-color: #f3f3f4f4;
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 1px;
}
.rank-th {
  font-weight: normal;
  font-size: 12px !important;
  color: #080b1999;
  background-color: #f3f3f4;
  border: solid #ceced1;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.errorcode-th {
  font-weight: normal;
  font-size: 12px !important;
  color: #080b1999;
  background-color: #f3f3f4;
  border: solid #ceced1;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.v-data-table tbody td {
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px !important;
}
.v-data-table {
  white-space: pre-line;
  border: solid #ceced1;
  border-left-width: 0px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 0px;
}

.v-application th.errorcode-th p.errorcode-th {
  margin-bottom: 0;
}

#stop-factor-unit {
  margin-top: 2.5rem;
}
</style>
