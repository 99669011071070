<template>
  <loading-overlay
    :is-loading="isdataloading"
    text-key="manual_adjustment_amount.csv_uploading"
  />
  <v-row>
    <v-col cols="12">
      <v-dialog v-model="innerOpenStatus" class="custom-dialog">
        <v-card :title="i18n.t('manual_adjustment_amount.csv_upload_title')">
          <v-card-text class="mt-4 mb-n4">
            {{ i18n.t("manual_adjustment_amount.file") }}
          </v-card-text>
          <v-card-text>
            <p>
              <v-file-input
                v-model="csvFile"
                label="ファイルを選択"
                accept=".csv"
                prepend-icon=""
                @change="loadManualFiles"
              ></v-file-input>
            </p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn variant="outlined" @click="innerOpenStatus = false">{{
              i18n.t("manual_adjustment_amount.cancel")
            }}</v-btn>
            <v-btn
              variant="flat"
              :color="!isDisableButton ? 'primary' : undefined"
              :disabled="isDisableButton"
              @click="uploadToleranceCsv()"
              >{{ i18n.t("manual_adjustment_amount.upload") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog></v-col
    >
  </v-row>
</template>
<script setup>
import { useI18n } from "vue-i18n";

import { computed, ref, watch, unref } from "vue";
import { connectToAPI } from "@/helpers/util";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";

const i18n = useI18n();

const alertObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});

const prop = defineProps({
  showCsvUploadDialog: Boolean
});
const emit = defineEmits([
  "OpenStatusUpdateRequest",
  "RefreshGraphRequest",
  "ApiResultRequest"
]);

const innerOpenStatus = computed({
  get() {
    return prop.showCsvUploadDialog;
  },
  set(value) {
    emit("OpenStatusUpdateRequest", value);
  }
});

const isdataloading = ref(false);
const csvFile = ref([]);
const csvFileData = ref("");
const isDisableButton = ref(true);

watch(csvFile, () => {
  if (!innerOpenStatus.value) {
    isDisableButton.value = true;
  }
  if (csvFile.value.length == 0 && innerOpenStatus.value) {
    isDisableButton.value = true;
  }
});

watch(innerOpenStatus, () => {
  if (!innerOpenStatus.value) {
    csvFile.value = [];
  }
});

/*
 * ファイルを展開する
 */
function loadManualFiles(event) {
  const elm = event.target;
  if (!(elm instanceof HTMLInputElement)) return;
  if (elm.files == null || elm.files.length == 0) return;
  for (const file of elm.files) {
    const reader = new FileReader();
    reader.onload = () => {
      const csv = reader.result;
      csvFileData.value = csv;
    };
    reader.readAsText(file);
  }
  isDisableButton.value = false;
}
/*
 * アップロードするCSVの列数チェック用
 */
function checkColumnsCount(defaultColumnCount) {
  // windoowsの改行コードだった場合の対応
  const lines = csvFileData.value.replace(/\r\n/g, "\n").split("\n");

  // csvの最後のが空の場合は削除
  if (lines[lines.length - 1] === "") {
    lines.pop();
  }

  // CSVの列数が規定の列数か確認する
  // 現在の規定のCSV列数は4列（plant_id_dpac,process,component_note,tolerance）
  const columnCounts = lines.map(line => line.split(",").length);
  for (let i = 0; i < columnCounts.length; i++) {
    if (columnCounts[i] !== defaultColumnCount) {
      const apiResult = {
        snackBarType: "error",
        snackBarTitle: i18n.rt(
          i18n.tm("manual_adjustment_amount.csv_upload_fail")
        ),
        snackBarMessage: i18n.rt(
          i18n.tm("manual_adjustment_amount.csv_upload_check_error_msg")
        ),
        popSnackBar: true
      };
      emit("ApiResultRequest", apiResult);
      return false;
    }
  }
  return true;
}
/*
 * 閾値用CSVをアップロードするAPI
 */
async function postUploadToleranceCsv() {
  const reqOptions = {
    method: "POST",
    url: "/api/manualadjustmentamount/datapoint-tolerance",
    data: {
      csv: csvFileData.value
    }
  };

  const response = await connectToAPI(reqOptions, unref(alertObj));
  return response.data;
}
/*
 * 閾値用CSVをアップロードする
 */
async function uploadToleranceCsv() {
  isdataloading.value = true;
  try {
    // 現在の規定のCSV列数は4列（plant_id_dpac,process,component_note,tolerance）
    const columnsCount = 4;
    if (checkColumnsCount(columnsCount)) {
      await postUploadToleranceCsv();

      innerOpenStatus.value = false;

      // 親コンポーネントでグラフを再描画
      emit("RefreshGraphRequest", true);
    }
  } catch (error) {
    // 親コンポーネントでスナックバーの表示
    const apiResult = {
      snackBarType: "error",
      snackBarTitle: i18n.rt(
        i18n.tm("manual_adjustment_amount.csv_upload_fail")
      ),
      snackBarMessage:
        error.response !== undefined
          ? error.response.data.message
          : i18n.rt(i18n.tm("manual_adjustment_amount.csv_upload_fail_msg")),
      popSnackBar: true
    };
    emit("ApiResultRequest", apiResult);
  } finally {
    isdataloading.value = false;
  }
}
</script>
<style scoped>
.custom-dialog {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .custom-dialog {
    width: 90%;
  }
}
</style>
