import axios from "axios";
import type { RouteLocationNormalized, RouteLocation } from "vue-router";

import {
  msalClient,
  loginRedirect,
  getTokenSilent,
  logoutRedirect
} from "./aad_b2c";

import type { ValidLanguage, ValidPeriod } from "@/stores/preference";
import { useAuthorities } from "@/stores/authorities";
import { usePreference } from "@/stores/preference";
import { useUserinfo } from "@/stores/userinfo";

type AuthorityPart = {
  is_all: boolean;
  targets: string[];
};

type ApiResponseGetUserSettings = {
  user_managements: {
    user_id: string;
    family_name: string;
    given_name: string;
    productivity: number;
    authority: {
      region: AuthorityPart;
      customer: AuthorityPart;
      plant: AuthorityPart;
    };
  };
  user_preferences: {
    user_id: string;
    language: ValidLanguage;
    dark_mode: boolean;
    date_from: string;
    date_to: string;
    period: ValidPeriod;
    unit_inch: boolean;
  };
};

export async function isAuthenticated(): Promise<boolean> {
  // authGuardは各Viewに入る前に実行されるので、ここで初期化処理をいれている
  // 初期化後に実行しても問題ないことは確認済み
  await msalClient.initialize();
  await msalClient.handleRedirectPromise();

  const accounts = msalClient.getAllAccounts();
  return accounts.length > 0;
}

async function callGetUserSettings(): Promise<ApiResponseGetUserSettings> {
  const { accessToken } = await getTokenSilent();

  const resp = await axios<ApiResponseGetUserSettings>({
    method: "GET",
    url: "/api/getUserSettings",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  return resp.data;
}

/**
 * 何かを見る権限がないことを確認する
 * @param authority
 */
function invalidAuthority(authority: AuthorityPart): boolean {
  if (authority.is_all) {
    return false;
  }
  return authority.targets.length === 0;
}

export async function authGuard(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _to: RouteLocationNormalized
): Promise<boolean | RouteLocation> {
  if (await isAuthenticated()) {
    const storeAuthorities = useAuthorities();
    const storePreference = usePreference();
    const storeUserinfo = useUserinfo();

    if (
      storeAuthorities.isNotInitialized ||
      storePreference.isNotInitialized ||
      storeUserinfo.isNotInitialized
    ) {
      try {
        const resp = await callGetUserSettings();

        if (
          invalidAuthority(resp.user_managements.authority.region) ||
          invalidAuthority(resp.user_managements.authority.customer) ||
          invalidAuthority(resp.user_managements.authority.plant)
        ) {
          alert(
            "CMSにおいてアクセス権が設定されていません。ログアウトします。"
          );
          await logoutRedirect();
          return false;
        }

        storeAuthorities.initialize({
          region: {
            isAll: resp.user_managements.authority.region.is_all,
            targets: resp.user_managements.authority.region.targets
          },
          customer: {
            isAll: resp.user_managements.authority.customer.is_all,
            targets: resp.user_managements.authority.customer.targets
          },
          plant: {
            isAll: resp.user_managements.authority.plant.is_all,
            targets: resp.user_managements.authority.plant.targets
          }
        });

        storeUserinfo.initialize({
          familyName: resp.user_managements.family_name,
          givenName: resp.user_managements.given_name,
          email: resp.user_managements.user_id,
          productivity: resp.user_managements.productivity
        });
        storePreference.initialize({
          language: resp.user_preferences.language,
          darkmode: resp.user_preferences.dark_mode,
          dateFrom: resp.user_preferences.date_from,
          dateTo: resp.user_preferences.date_to,
          period: resp.user_preferences.period,
          unitInch: resp.user_preferences.unit_inch
        });
      } catch (e) {
        // DynamoDBにユーザー情報が登録されない場合に 401を返すのでaxiosが例外を投げる
        console.error("failed to call API getUserSettings", e);
        alert("CMSのユーザー情報の取得に失敗しました。ログアウトします。");
        await logoutRedirect();
        return false;
      }
    }

    return true;
  }
  await loginRedirect();
  return false;
}
