<template>
  <v-container fluid>
    <v-row justify="center" class="card-wrapper">
      <menu-card
        v-for="menuobj in menuobjlist"
        :key="menuobj.title"
        class="menucard"
        :linkto="menuobj.linkto"
        :icon="menuobj.icon"
        :title="menuobj.title"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppTop"
};
</script>
<script setup>
import { storeToRefs } from "pinia";
import { computed, onMounted, onBeforeUnmount, ref, unref } from "vue";
import { useI18n } from "vue-i18n";

import MenuCard from "@/components/parts/MenuCard.vue";
import {
  canReadManualAdjustmentAmountTarget,
  canReadReport
} from "@/helpers/util";
import { useAuthorities } from "@/stores/authorities";
import { useDrawerFlgStore } from "@/stores/drawerFlg";

import pdIcon from "@/assets/icon-01-pd.png";
import cdIcon from "@/assets/icon-02-cd.png";
import olIcon from "@/assets/icon-03-ol.png";
import productionIcon from "@/assets/icon-04-production.png";
import historyIcon from "@/assets/icon-05-history.png";
import analysisIcon from "@/assets/icon-06-analysis.png";

const i18n = useI18n();
const authoritiesStore = useAuthorities();
const drawerFlgStore = useDrawerFlgStore();

const { plant: saPlant } = storeToRefs(authoritiesStore);
const { drawerFlg } = storeToRefs(drawerFlgStore);
const reportCard = ref({
  linkto: "/report",
  icon: olIcon,
  title: i18n.t("others.title.report")
});
const manualAdjustmentAmountCard = ref({
  linkto: "/manualadjustmentamount",
  icon: productionIcon,
  title: i18n.t("others.title.manual_adjustment_amount")
});
const basicCards = ref([
  {
    linkto: "/productivity",
    icon: pdIcon,
    title: i18n.t("others.title.productivity_dashboard")
  },
  {
    linkto: "/condition",
    icon: cdIcon,
    title: i18n.t("others.title.condition_dashboard")
  },
  {
    linkto: "/list",
    icon: olIcon,
    title: i18n.t("others.title.order_list")
  },
  {
    linkto: "/production",
    icon: productionIcon,
    title: i18n.t("others.title.production_dashboard")
  },
  {
    linkto: "/history",
    icon: historyIcon,
    title: i18n.t("others.title.history")
  },
  {
    linkto: "/erroranalysis",
    icon: analysisIcon,
    title: i18n.t("others.title.error_analysis")
  },
  {
    linkto: "/productiontime",
    icon: analysisIcon,
    title: i18n.t("others.title.production_time")
  },
  {
    linkto: "/stopfactor",
    icon: analysisIcon,
    title: i18n.t("others.title.stop_factor")
  },
  {
    linkto: "/realtime",
    icon: historyIcon,
    title: i18n.t("others.title.realtime")
  }
]);

/**
 * レポート画面が見える権限がある場合は先頭に、
 * 手動調整値画面が見える権限がある場合は最後尾に追加する
 */
const menuobjlist = computed(() => {
  const cards = [];

  if (unref(canReadReportFlg)) {
    cards.push(unref(reportCard));
  }

  if (unref(canReadManualAdjustmentAmountTargetFlg)) {
    cards.push(...unref(basicCards), unref(manualAdjustmentAmountCard));
  } else {
    cards.push(...unref(basicCards));
  }

  return cards;
});
const canReadReportFlg = computed(() => {
  return canReadReport(unref(saPlant));
});
const canReadManualAdjustmentAmountTargetFlg = computed(() => {
  return canReadManualAdjustmentAmountTarget(unref(saPlant));
});

onMounted(async () => {
  // この画面ではフィルタ閉じてたいので
  drawerFlg.value = false;
});

/**
 * 遷移するときにフィルタ復活させる
 */
onBeforeUnmount(() => {
  drawerFlg.value = true;
});
</script>
<style scoped>
@media (max-width: 200px) {
  .menucard {
    min-width: 200px;
    margin-bottom: 16px;
  }
}
@media (min-width: 200px) and (max-width: 585px) {
  .menucard {
    min-width: -webkit-fill-available;
    margin: 0 0 16px 0;
    padding: 0;
  }
  .card-wrapper {
    width: 100%;
    margin: auto;
  }
  .container {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 0px;
  }
}

@media (min-width: 585px) and (max-width: 890px) {
  .menucard {
    margin: 8px;
    min-width: 45%;
    padding: 0;
  }

  .container {
    padding: 8px 0 0 0;
  }
}

@media (min-width: 890px) {
  .menucard {
    max-width: 31.5%;
    margin: 8px;
    padding: 0;
  }

  .container {
    padding-top: 8px;
  }
}
</style>
