<template>
  <highcharts :options="chartOptions" />
</template>

<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useSelectedGraphStyle } from "@/composables/useSelectedGraphStyle";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  }
});

const emit = defineEmits(["clickOrderScatterPlot"]);

const i18n = useI18n();
const selectedGraphStyle = useSelectedGraphStyle();

const chartOptions = ref({
  chart: {
    type: "scatter",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "400px",
    zoomType: "xy"
  },
  time: { useUTC: false },
  title: { text: "" },
  legend: { enabled: false },
  series: [
    {
      data: [],
      turboThreshold: 0 // データポイントの数に関係なく全てのポイントを描画
    }
  ],
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    },
    allowDecimals: true,
    gridLineWidth: 1
  },
  yAxis: {
    max: null,
    min: 0,
    title: {
      text: null
    },
    allowDecimals: true
  },
  tooltip: {
    formatter: function () {
      return (
        this.point.tooltip_name_order_id +
        " : " +
        this.point.order_id +
        "<br>" +
        this.point.tooltip_name_order_no +
        " : " +
        this.point.order_no +
        "<br>" +
        this.point.tooltip_name_term +
        " : " +
        this.point.prod_start_time_local +
        " - " +
        this.point.prod_end_time_local +
        "<br>" +
        this.point.tooltip_name_y +
        " : " +
        "<b>" +
        this.point.y.toFixed(2) +
        "</b>"
      );
    }
  },
  plotOptions: {
    scatter: {
      cursor: "pointer",
      color: "#2caffe",
      marker: {
        radius: 3.5,
        symbol: "circle",
        states: {
          hover: { enabled: true },
          select: {
            enabled: true,
            fillColor: null,
            lineColor: selectedGraphStyle.borderColor.value,
            lineWidth: selectedGraphStyle.borderWidth,
            radius: selectedGraphStyle.plotRadius
          }
        }
      }
    },
    series: {
      allowPointSelect: true,
      events: {
        click: e => {
          onBarClick(e);
        }
      }
    }
  }
});

watch(
  () => prop.viewdata,
  () => {
    renderChart();
  }
);

function renderChart() {
  unref(chartOptions).yAxis.title.text = i18n.t(
    "manual_adjustment_amount.order_scatter_plot_yaxis_label"
  );

  const data = prop.viewdata.map(el => {
    return {
      x: dayjs(el.prod_end_time_local).valueOf(),
      y: el["manual_adjustment_amount"],
      order_id: el["order_id"],
      order_no: el["order_no"],
      prod_start_time_local: el["prod_start_time_local"],
      prod_end_time_local: el["prod_end_time_local"],
      tooltip_name_y: i18n.t(
        "manual_adjustment_amount.manual_adjustment_amount"
      ),
      tooltip_name_order_id: i18n.t("manual_adjustment_amount.order_id"),
      tooltip_name_order_no: i18n.t("manual_adjustment_amount.order_no"),
      tooltip_name_term: i18n.t("manual_adjustment_amount.term")
    };
  });

  unref(chartOptions).series[0].data = data;
}

function onBarClick(event) {
  const orderInfoStatus = true;
  const targetOrderId = event.point.order_id;

  // オーダー情報（テーブル、折れ線、エラーテーブル）を表示するためのステータス、選択したオーダーのオーダーIDを親コンポーネントに送る
  emit("clickOrderScatterPlot", orderInfoStatus, targetOrderId);
}
</script>
