<template>
  <div class="notification">
    <v-alert
      v-model="isShowAlert"
      type="info"
      density="compact"
      prominent
      closable
      style="
        top: 85%;
        left: 25%;
        position: fixed;
        z-index: 10;
        margin: auto;
        width: 50%;
      "
    >
      <v-row align="center">
        <v-col>
          {{ i18n.t("others.notification.message") }}
        </v-col>
        <v-col cols="auto">
          <v-btn variant="outlined" @click="releaseNoteOpenRequest"
            ><v-icon size="x-large">mdi-script-text-outline</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "AppNotification"
};
</script>
<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { isAuthenticated } from "@/auth/authGuard";

const emit = defineEmits(["ReleaseNoteOpenRequest"]);

const i18n = useI18n();

const isShowAlert = ref(false);

onMounted(async () => {
  // 通知画面を表示するか
  if (await checkNotificationOpen()) {
    // 通知画面表示判断後にローカルストレージにバージョンを保存する
    localStorage.setItem("cms_app_version", __PACKAGE_VERSION__);
  }
});

function releaseNoteOpenRequest() {
  emit("ReleaseNoteOpenRequest");
}
async function checkNotificationOpen() {
  if (
    localStorage.getItem("cms_app_version") !== __PACKAGE_VERSION__ &&
    (await isAuthenticated())
  ) {
    isShowAlert.value = true;
    return true;
  } else {
    isShowAlert.value = false;
    return false;
  }
}
</script>
<style>
.notification .v-alert__content a {
  color: #ffffff;
}
@media screen and (max-width: 450px) {
  .notification .v-alert {
    left: 0 !important;
    width: 100% !important;
    font-size: 12px;
  }
}
</style>
