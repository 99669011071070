<template>
  <v-container class="pa-0" fluid>
    <loading-bar :loading="isLoading || isDataLoading" />
    <donut-cards :viewdata="viewDonutData" :colors="plantColors" />
    <v-divider class="mt-5"></v-divider>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-toolbar class="bg-transparent">
          <toggle-buttons
            :contents="timeButtons"
            :default="'day'"
            class="px-4"
            @select-button="changeTimescale"
          />
          <v-spacer />
          <toggle-buttons
            :contents="volumeButtons"
            :default="'volume'"
            class="px-4"
            @select-button="changeVolumetype"
          />
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-4 pb-2" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar
            density="compact"
            class="bg-transparent text-body-2 font-weight-medium"
            flat
          >
            <toggle-buttons
              ref="graphtype"
              :contents="stackButtons"
              :default="'stack'"
              class="px-4"
              @select-button="changeGraphtype"
            />
            <v-spacer />
            {{ i18n.t("others.switch.stack_label") }}
            <v-switch
              v-model="showStackLabels"
              color="primary"
              inset
              class="flex-grow-0 pt-6 pl-3 pr-6"
            />
          </v-toolbar>
          <highcharts :options="chartOptions" />
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="Object.keys(chartOptionsSingles).length > 1"
      class="ma-0 px-2 pb-2"
    >
      <v-col
        v-for="(chartOptionsSingle, plant) in chartOptionsSingles"
        :key="plant"
        class="pa-2"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        cols="12"
      >
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{ plant }}</v-toolbar-title>
          </v-toolbar>
          <highcharts :options="chartOptionsSingle" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppProduction"
};

const defaultColors = [
  "#E1C74F",
  "#AF577D",
  "#90C0D2",
  "#9EAF5B",
  "#4C6169",
  "#DC8548",
  "#6E4A5E",
  "#3D7487",
  "#727D59",
  "#2D424B"
];
</script>
<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { storeToRefs } from "pinia";
import { ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import LoadingBar from "@/components/parts/LoadingBar.vue";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import DonutCards from "@/components/parts/ProductionDonutCards.vue";
import { convertUnit, calculateAverageByPeriod } from "@/helpers/util";
import { useFilterDataStore } from "@/stores/filterData";
import { useInitialDataStore } from "@/stores/initialData";
import { usePreference } from "@/stores/preference";
import { useDataperiodStore } from "@/stores/dataperiod";

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const initialDataStore = useInitialDataStore();
const preferenceStore = usePreference();
const dataperiodStore = useDataperiodStore();

const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { productiondata, isLoading: isDataLoading } =
  storeToRefs(initialDataStore);
const { unitInch } = storeToRefs(preferenceStore);
const { dataperiod: storePeriod } = storeToRefs(dataperiodStore);

const graphtype = ref(null);
const selectAll = ref(false);
const daycount = ref(0);
const prodSum = ref(0);
const currentTimescale = ref(undefined);
const currentGraphtype = ref("stack");
const viewdata = ref({});
const viewDonutData = ref({});
const switchTarget = ref("volume");
const isLoading = ref(true);
const AVERAGE_LINE_COLOR = "#f7a35c";
const chartOptions = ref({
  chart: {
    type: "column",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "600px",
    zoomType: "xy"
  },
  title: {
    text: ""
  },
  legend: {
    enabled: true
  },
  series: [{ data: [] }],
  plotOptions: {
    column: {
      stacking: "normal"
    }
  },
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    }
  },
  yAxis: {
    stackLabels: { enabled: false },
    title: { text: i18n.t("others.unit.sheets") },
    plotLines: [
      {
        color: AVERAGE_LINE_COLOR,
        width: 2,
        value: null,
        dashStyle: "Solid",
        zIndex: 4
      }
    ]
  }
});
/**
 * 個別用
 */
const chartOptionsSingles = ref({});
const plantColors = ref({});
const showStackLabels = ref(false);

const timeButtons = ["day", "week", "month", "all"];
const volumeButtons = ["order", "volume", "volume_size"];
const stackButtons = ["normal", "stack", "ratio"];

watch(
  stateFilterData,
  () => {
    if (unref(productiondata).length === 0) {
      return;
    }
    isLoading.value = false;
    fillData();
    renderChart(unref(currentTimescale));
  },
  {
    deep: true,
    immediate: false
  }
);

watch(
  productiondata,
  () => {
    if (unref(storePeriod).fromDate === "") {
      return;
    }
    setUp();
  },
  {
    deep: true
  }
);

watch(showStackLabels, () => {
  updateChartStackingOptions();
});

/**
 * 表示データをつくるメソッド
 */
async function fillData() {
  const nextDateOf = yyyymmdd =>
    dayjs(yyyymmdd).add(1, "days").format("YYYY-MM-DD");
  const funcFilter = item => {
    return (
      (unref(stateFilterData).selectregion.length === 0 ||
        unref(stateFilterData).selectregion.indexOf(item.region) >= 0) &&
      (unref(stateFilterData).selectcustomer.length === 0 ||
        unref(stateFilterData).selectcustomer.indexOf(item.customer) >= 0) &&
      (unref(stateFilterData).selectplant.length === 0 ||
        unref(stateFilterData).selectplant.indexOf(item.city_plant_no) >= 0) &&
      (!unref(stateFilterData).to ||
        dayjs(item.dt).isBefore(nextDateOf(unref(stateFilterData).to))) &&
      //item.dtも日付しかなくてフィルタと同じ日付はアフターfalseになっちゃうので1分後で判定する
      (!unref(stateFilterData).from ||
        dayjs(item.dt + " 00:00:01").isAfter(unref(stateFilterData).from))
    );
  };

  let viewdataAry = structuredClone(toRaw(unref(productiondata))).filter(
    funcFilter
  );

  //セグメントでまとめたオブジェクトに変更
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  viewdata.value = groupBy(viewdataAry, "city_plant_no");
  viewDonutData.value = groupBy(viewdataAry, "plant_id_dpac");
}

let globalColorIndex = 0;
function assignColorsToAllPlants(allViewdata) {
  const allPlants = new Set();
  Object.values(allViewdata).forEach(data => {
    data.forEach(item => allPlants.add(item.city_plant_no));
  });
  const plantColorMap = { ...unref(plantColors) }; // 既存の色の割り当てを保持
  const plantArray = Array.from(allPlants);

  plantArray.forEach(plant => {
    if (!(plant in plantColorMap)) {
      let color;
      if (Object.keys(plantColorMap).length < defaultColors.length) {
        // まだ使用されていない色がある場合、それを使用
        color = defaultColors.find(
          c => !Object.values(plantColorMap).includes(c)
        );
      } else {
        // すべての色が使用されている場合、循環的に割り当て
        color = defaultColors[globalColorIndex % defaultColors.length];
        globalColorIndex++;
      }
      plantColorMap[plant] = color;
    }
  });

  return plantColorMap;
}

function renderChart(timescale) {
  let viewdataOptional = {};
  let graphRange = { maxX: null, maxY: null, minX: null };
  unref(chartOptions).xAxis.type = "datetime";
  currentTimescale.value = timescale;

  if (unref(selectAll)) {
    showAllByPlant();
    return;
  }

  Object.keys(unref(viewdata)).forEach(key => {
    let ary = unref(viewdata)[key];
    //for中対象のデータ
    let current = { x: new Date(), y: null };
    //グラフにだす配列。カレントの配列
    let plantTimeAry = [];
    daycount.value = 1;
    prodSum.value = 0;

    Object.keys(ary).forEach(plantdataindex => {
      let value = ary[plantdataindex];
      let columnValue = "";

      if (unref(switchTarget) === "order") {
        columnValue = value["order_count"];
      } else if (unref(switchTarget) === "volume") {
        columnValue = value["sum"];
      } else if (unref(switchTarget) === "volume_size") {
        columnValue = convertUnit(value["vxs"], unref(unitInch), false);
      }

      if (timescale === "day") {
        //Xが日。特に処理はしない
        current.x = new Date(value["dt"]);
        current.y = columnValue;
        plantTimeAry.push(Object.assign({}, current));
      } else if (timescale === "week") {
        //Xが週
        let targetX = new Date(value["week_start"]);
        plantTimeAry = getSum(current, value, plantTimeAry, targetX);
      } else if (timescale === "month") {
        //Xが月
        let targetX = new Date(
          new Date(value["dt"]).getFullYear(),
          new Date(value["dt"]).getMonth()
        );
        plantTimeAry = getSum(current, value, plantTimeAry, targetX);
      }
      //XYの最大最小を記録しておく
      if (!graphRange.maxX || graphRange.maxX < current.x)
        graphRange.maxX = current.x;
      if (!graphRange.minX || graphRange.minX > current.x)
        graphRange.minX = current.x;
      if (!graphRange.maxY || graphRange.maxY < current.y)
        graphRange.maxY = current.y;
    });

    //Xが日付以外（週、月）の時
    if (timescale !== "day") {
      //最後のcurrent入れる
      plantTimeAry.push(current);
      //["",""]を除く
      plantTimeAry.shift();
    }

    viewdataOptional[key] = plantTimeAry;
  });

  //chartoptionのseriesに入れる
  let viewdataSet = [];
  const allPlantColors = assignColorsToAllPlants(viewdata.value);
  plantColors.value = { ...plantColors.value };
  Object.keys(viewdataOptional).forEach(plant => {
    let color = allPlantColors[plant];
    viewdataSet.push({
      name: plant,
      data: viewdataOptional[plant],
      borderWidth: 0,
      color: color
    });

    if (!(plant in unref(plantColors))) {
      unref(plantColors)[plant] = color;
    }
  });

  // plantが1つの場合のみ平均線を追加
  const singlePlant = viewdataSet.length === 1;
  const fromDate = dayjs(
    unref(stateFilterData).from ?? storePeriod.value.fromDate
  );
  const toDate = dayjs(unref(stateFilterData).to ?? storePeriod.value.toDate);

  const average = singlePlant
    ? calculateAverageByPeriod(viewdataSet[0].data, timescale, fromDate, toDate)
    : null;

  const { yAxis } = unref(chartOptions);
  yAxis.plotLines = [
    {
      ...yAxis.plotLines[0],
      value: average
    }
  ];

  unref(chartOptions).series = viewdataSet;
  unref(chartOptions).legend.enabled = true;

  //chartOptionsを元手にchartOptionsSinglesにコピーで作成
  // 工場毎の棒グラフの描画処理
  chartOptionsSingles.value = {};
  Object.keys(viewdataOptional).forEach((plantCityNo, index) => {
    let chartOptionsSingle = structuredClone(toRaw(unref(chartOptions)));
    chartOptionsSingle.chart.height = "300px";

    // この工場のデータのみを取得
    const plantData = viewdataSet[index].data;
    chartOptionsSingle.series = [
      {
        name: plantCityNo,
        data: plantData,
        borderWidth: 0,
        color: viewdataSet[index].color
      }
    ];

    const fromDate = dayjs(
      unref(stateFilterData).from ?? storePeriod.value.fromDate
    );
    const toDate = dayjs(unref(stateFilterData).to ?? storePeriod.value.toDate);
    const average = calculateAverageByPeriod(
      plantData,
      timescale,
      fromDate,
      toDate
    );
    chartOptionsSingle.yAxis.plotLines = [
      {
        color: AVERAGE_LINE_COLOR,
        dashStyle: "Solid",
        value: average,
        width: 2,
        zIndex: 5
      }
    ];

    chartOptionsSingle.plotOptions.column.stacking = "";
    chartOptionsSingle.legend.enabled = false;
    chartOptionsSingle.yAxis.max = graphRange.maxY;
    chartOptionsSingle.xAxis.max = graphRange.maxX.getTime();
    chartOptionsSingle.xAxis.min = graphRange.minX.getTime();
    unref(chartOptionsSingles)[plantCityNo] = chartOptionsSingle;
  });
}

// 日・週・月・全期間の切り替えボタン押下時に呼ばれる
function changeTimescale(clickedButton) {
  if (clickedButton === "all") {
    selectAll.value = true;
  } else {
    selectAll.value = false;
  }
  controlGraphtypeButtons();
  renderChart(clickedButton);
}

function changeGraphtype(clickedButton) {
  currentGraphtype.value = clickedButton;
  controlGraphtypeButtons();
}

function changeVolumetype(clickedButton) {
  let volumeType = clickedButton;

  switchTarget.value = volumeType;
  if (volumeType === "order") {
    unref(chartOptions).yAxis.title.text = i18n.t("others.unit.order_counts");
  } else if (volumeType === "volume") {
    unref(chartOptions).yAxis.title.text = i18n.t("others.unit.sheets");
  } else if (volumeType === "volume_size") {
    unref(chartOptions).yAxis.title.text = unref(unitInch) ? "in²" : "㎡";
  }
  controlGraphtypeButtons();
  renderChart(unref(currentTimescale));
}

function getSum(current, value, plantTimeAry, targetX) {
  let columnValue = "";

  if (unref(switchTarget) === "order") {
    columnValue = value["order_count"];
  } else if (unref(switchTarget) === "volume") {
    columnValue = value["sum"];
  } else if (unref(switchTarget) === "volume_size") {
    columnValue = convertUnit(value["vxs"], unref(unitInch), false);
  }

  if (current.x.getTime() !== targetX.getTime()) {
    plantTimeAry.push(Object.assign({}, current));
    current.x = targetX;
    current.y = columnValue;
  } else {
    current.y += columnValue;
  }
  return plantTimeAry;
}

function showAllByPlant() {
  let plantTotalAry = [];

  Object.values(unref(viewdata)).forEach(ary => {
    let current = {
      name: ary[0].city_plant_no,
      y: null
    };
    Object.keys(ary).forEach(plantdataindex => {
      let value = ary[plantdataindex];
      if (unref(switchTarget) === "order") {
        current.y += value.order_count;
      } else if (unref(switchTarget) === "volume") {
        current.y += value.sum;
      } else if (unref(switchTarget) === "volume_size") {
        current.y += convertUnit(value.vxs, unref(unitInch), false);
      }
    });

    plantTotalAry.push(current);
  });
  unref(chartOptions).xAxis.type = "category";
  unref(chartOptions).legend.enabled = false;
  unref(chartOptions).series = [{ data: plantTotalAry }];

  // 平均線のグラフを非表示
  const { yAxis } = unref(chartOptions);
  yAxis.plotLines = [
    {
      ...yAxis.plotLines[0],
      value: null
    }
  ];

  // 工場毎のグラフを非表示
  chartOptionsSingles.value = {};
}

function controlGraphtypeButtons() {
  const normalButton = unref(graphtype).$el.childNodes[0].children[0];
  const stackButton = unref(graphtype).$el.childNodes[0].children[1];
  const ratioButton = unref(graphtype).$el.childNodes[0].children[2];

  if (unref(selectAll)) {
    //NORMALボタンのみ表示、選択
    stackButton.setAttribute("style", "display:none;");
    ratioButton.setAttribute("style", "display:none;");
    normalButton.classList.add("active");
    unref(chartOptions).plotOptions.column.stacking = "";
    currentGraphtype.value = "normal";
  } else {
    //全ボタンを表示する
    stackButton.setAttribute("style", "display:inline-flex");
    ratioButton.setAttribute("style", "display:inline-flex");
    normalButton.classList.remove("active");
    stackButton.classList.remove("active");
    ratioButton.classList.remove("active");

    switch (currentGraphtype.value) {
      case "normal":
        normalButton.classList.add("active");
        break;
      case "stack":
        stackButton.classList.add("active");
        break;
      case "ratio":
        ratioButton.classList.add("active");
        break;
    }
  }

  updateChartStackingOptions();
}

function updateChartStackingOptions() {
  switch (currentGraphtype.value) {
    case "normal":
      unref(chartOptions).plotOptions.column.stacking = "";
      unref(chartOptions).yAxis.stackLabels.enabled = false;
      break;
    case "stack":
      unref(chartOptions).plotOptions.column.stacking = "normal";
      unref(chartOptions).yAxis.stackLabels.enabled = showStackLabels.value;
      break;
    case "ratio":
      unref(chartOptions).plotOptions.column.stacking = "percent";
      unref(chartOptions).yAxis.stackLabels.enabled = false;
      break;
  }
}

function setUp() {
  isLoading.value = false;
  fillData();
  const timescale = "day";
  renderChart(timescale);
}

if (unref(productiondata).length !== 0) {
  setUp();
}
</script>
