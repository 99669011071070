<template>
  <div>
    <v-row class="mx-2 mt-2 mb-0">
      <v-col class="pa-2" xl="3" lg="3" md="3" sm="6" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("productivity.order")
            }}</v-toolbar-title>
          </v-toolbar>
          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="orderDonut"
          />
        </v-card>
      </v-col>

      <v-col class="pa-2" xl="3" lg="3" md="3" sm="6" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("productivity.volume")
            }}</v-toolbar-title>
          </v-toolbar>
          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="volDonut"
          />
        </v-card>
      </v-col>

      <v-col class="pa-2" xl="3" lg="3" md="3" sm="6" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("productivity.volume_size")
            }}</v-toolbar-title>
          </v-toolbar>
          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="volsizeDonut"
          />
        </v-card>
      </v-col>
      <v-col
        v-if="productivity"
        class="pa-2"
        xl="3"
        lg="3"
        md="3"
        sm="6"
        cols="12"
      >
        <v-card class="translucent" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{
              i18n.t("productivity.productivity_per_order")
            }}</v-toolbar-title>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn v-bind="props" density="comfortable" icon variant="text">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>
                <v-img :src="helpimgPath" aspect-ratio="1.1" width="500px" />
              </span>
            </v-tooltip>
          </v-toolbar>

          <highcharts
            v-if="Object.keys(viewdata).length > 0"
            :options="prodDonut"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { Chart as highcharts } from "highcharts-vue";
import { storeToRefs } from "pinia";
import { computed, ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { convertUnit } from "@/helpers/util";
import { usePreference } from "@/stores/preference";
import { useUserinfo } from "@/stores/userinfo";

import helpimgEn from "@/assets/translation/img/en/productivity.png";
import helpimgJa from "@/assets/translation/img/ja/productivity.png";

const prop = defineProps({
  viewdata: {
    type: Object,
    default: function () {
      return {};
    }
  }
});

const i18n = useI18n();
const preferenceStore = usePreference();
const userinfoStore = useUserinfo();

const { language, unitInch } = storeToRefs(preferenceStore);
const { productivity } = storeToRefs(userinfoStore);
const donutsObjs = ref({
  Order: {
    donutData: {}
  },
  Volume: {
    donutData: {}
  },
  "Volume x Size": {
    donutData: {}
  },
  Productivity: {
    donutData: {}
  }
});
//Setting for Highcharts
const orderDonut = ref({});
const volDonut = ref({});
const volsizeDonut = ref({});
const prodDonut = ref({});
const defaultOptions = ref({
  chart: {
    className: "donutchart",
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    height: "300px",
    backgroundColor: "transparent"
  },
  title: {
    useHTML: true,
    text: "",
    align: "center",
    verticalAlign: "middle",
    y: 30,
    style: { fontFamily: "Roboto", fontSize: "28px" }
  },
  subtitle: {
    useHTML: true,
    fontFamily: "Roboto",
    text: "",
    align: "center",
    verticalAlign: "middle",
    fontSize: "14px",
    y: -20,
    style: { fontFamily: "Roboto" }
  },
  tooltip: {
    pointFormat: "{point.y:,.0f}"
  },
  credits: {
    text: ""
  },
  accessibility: {
    point: {
      valueSuffix: "%"
    }
  },
  plotOptions: {
    pie: {
      borderWidth: "0",
      colors: ["#4a71f4", "#87c03a", "#daa02a", "#da3c41"],
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: "allow",
        verticalAlign: "middle",
        x: 0,
        y: -5,
        useHTML: true,
        format: "{point.y:,.0f}<br>{point.percentage:.0f} %",
        distance: -20,
        style: {
          fontFamily: "Roboto",
          fontSize: "67%",
          fontWeight: "none",
          color: "white",
          textOutline: "none"
        }
      },
      startAngle: 0, //円弧設定
      endAngle: 360,
      center: ["50%", "53%"], //円位置
      size: "110%"
    }
  },
  series: [
    {
      type: "pie",
      name: "",
      innerSize: "65%", //中心の空洞
      data: []
    }
  ]
});

const orderTotal = computed(() => {
  return unref(donutsObjs).Order.donutData.datasets[0].data.reduce(
    function (a, x) {
      return a + x;
    }
  );
});
const volTotal = computed(() => {
  return unref(donutsObjs).Volume.donutData.datasets[0].data.reduce(
    function (a, x) {
      return a + x;
    }
  );
});
const volsizeTotal = computed(() => {
  let volsizedata = unref(donutsObjs)["Volume x Size"].donutData.datasets[0];
  const volsizeTotal = volsizedata.data.reduce(function (a, x) {
    return a + x;
  });
  const fixedTotal = convertUnit(volsizeTotal, unref(unitInch), true);
  return fixedTotal;
});
const prodTotal = computed(() => {
  return unref(donutsObjs).Productivity.donutData.datasets[0].data[0];
});
const helpimgPath = computed(() => {
  if (unref(language) === "ja") {
    return helpimgJa;
  } else {
    return helpimgEn;
  }
});

watch(
  () => prop.viewdata,
  value => {
    if (Object.keys(value).length > 0) {
      createDonutData();
      pushData();
    }
  },
  {
    immediate: true
  }
);

function createDonutData() {
  let viewA = prop.viewdata["A"] ? prop.viewdata["A"] : [];
  let viewB = prop.viewdata["B"] ? prop.viewdata["B"] : [];
  let viewC = prop.viewdata["C"] ? prop.viewdata["C"] : [];
  let viewD = prop.viewdata["D"] ? prop.viewdata["D"] : [];

  let countA = viewA.length;
  let countB = viewB.length;
  let countC = viewC.length;
  let countD = viewD.length;

  let notStoppedCountA = viewA.filter(el => el["stop_time"] < 360).length;
  let notStoppedCountB = viewB.filter(el => el["stop_time"] < 360).length;
  let notStoppedCountC = viewC.filter(el => el["stop_time"] < 360).length;
  let notStoppedCountD = viewD.filter(el => el["stop_time"] < 360).length;

  const funcSumsht = (accumulator, currentValue) => {
    return accumulator + parseInt(currentValue["prod_volume"]);
  };
  let sumshtA = viewA.reduce(funcSumsht, 0);
  let sumshtB = viewB.reduce(funcSumsht, 0);
  let sumshtC = viewC.reduce(funcSumsht, 0);
  let sumshtD = viewD.reduce(funcSumsht, 0);

  const funcSumproductivity = (accumulator, currentValue) => {
    if (currentValue["stop_time"] < 360) {
      return accumulator + parseFloat(currentValue["productivity"]);
    } else {
      return accumulator;
    }
  };
  let sumpA = viewA.reduce(funcSumproductivity, 0);
  let sumpB = viewB.reduce(funcSumproductivity, 0);
  let sumpC = viewC.reduce(funcSumproductivity, 0);
  let sumpD = viewD.reduce(funcSumproductivity, 0);

  const funcSumshtxs = (accumulator, currentValue) => {
    return accumulator + parseInt(currentValue["vxs"]);
  };
  let sumshtxsA = viewA.reduce(funcSumshtxs, 0);
  let sumshtxsB = viewB.reduce(funcSumshtxs, 0);
  let sumshtxsC = viewC.reduce(funcSumshtxs, 0);
  let sumshtxsD = viewD.reduce(funcSumshtxs, 0);

  unref(donutsObjs)["Order"].donutData = {
    datasets: [
      {
        data: [countA, countB, countC, countD]
      }
    ],
    labels: ["A", "B", "C", "D"]
  };
  unref(donutsObjs)["Volume"].donutData = {
    datasets: [
      {
        data: [sumshtA, sumshtB, sumshtC, sumshtD]
      }
    ],
    labels: ["A", "B", "C", "D"]
  };
  unref(donutsObjs)["Volume x Size"].donutData = {
    datasets: [
      {
        data: [sumshtxsA, sumshtxsB, sumshtxsC, sumshtxsD]
      }
    ],
    labels: ["A", "B", "C", "D"]
  };

  let aveProductivity =
    Math.round(
      ((sumpA + sumpB + sumpC + sumpD) /
        (notStoppedCountA +
          notStoppedCountB +
          notStoppedCountC +
          notStoppedCountD)) *
        1000
    ) / 10;
  unref(donutsObjs)["Productivity"].donutData = {
    datasets: [
      {
        data: [aveProductivity, 100 - aveProductivity]
      }
    ],
    labels: []
  };
}
function pushData() {
  orderDonut.value = structuredClone(toRaw(unref(defaultOptions)));
  volDonut.value = structuredClone(toRaw(unref(defaultOptions)));
  volsizeDonut.value = structuredClone(toRaw(unref(defaultOptions)));
  prodDonut.value = structuredClone(toRaw(unref(defaultOptions)));

  unref(orderDonut).title.text = unref(orderTotal).toLocaleString();
  unref(volDonut).title.text = unref(volTotal).toLocaleString();
  unref(volsizeDonut).title.text = unref(volsizeTotal).toLocaleString();
  unref(prodDonut).title.text = unref(prodTotal).toLocaleString();

  unref(orderDonut).subtitle.text = i18n.t("productivity.orderdonut_subtitle");
  unref(volDonut).subtitle.text = i18n.t("productivity.voldonut_subtitle");
  unref(prodDonut).subtitle.text = i18n.t("productivity.proddonut_subtitle");
  if (unref(unitInch)) {
    unref(volsizeDonut).subtitle.text = i18n.t(
      "productivity.volsizedonut_subtitle_in"
    );
  } else {
    unref(volsizeDonut).subtitle.text = i18n.t(
      "productivity.volsizedonut_subtitle_m"
    );
  }

  unref(prodDonut).plotOptions.pie.colors = ["#4a71f4", "#d8d8d8"];
  unref(prodDonut).plotOptions.pie.dataLabels.enabled = false;

  if (unref(productivity)) {
    //有料会員の場合内訳も表示する
    let orderarr = [
      ["A", unref(donutsObjs).Order.donutData.datasets[0].data[0]],
      ["B", unref(donutsObjs).Order.donutData.datasets[0].data[1]],
      ["C", unref(donutsObjs).Order.donutData.datasets[0].data[2]],
      ["D", unref(donutsObjs).Order.donutData.datasets[0].data[3]]
    ];
    orderarr.forEach(el => unref(orderDonut).series[0].data.push(el));

    let volarr = [
      ["A", unref(donutsObjs).Volume.donutData.datasets[0].data[0]],
      ["B", unref(donutsObjs).Volume.donutData.datasets[0].data[1]],
      ["C", unref(donutsObjs).Volume.donutData.datasets[0].data[2]],
      ["D", unref(donutsObjs).Volume.donutData.datasets[0].data[3]]
    ];
    volarr.forEach(el => unref(volDonut).series[0].data.push(el));

    let volsizearr = [];
    volsizearr = [
      [
        "A",
        convertUnit(
          unref(donutsObjs)["Volume x Size"].donutData.datasets[0].data[0],
          unref(unitInch),
          true
        )
      ],
      [
        "B",
        convertUnit(
          unref(donutsObjs)["Volume x Size"].donutData.datasets[0].data[1],
          unref(unitInch),
          true
        )
      ],
      [
        "C",
        convertUnit(
          unref(donutsObjs)["Volume x Size"].donutData.datasets[0].data[2],
          unref(unitInch),
          true
        )
      ],
      [
        "D",
        convertUnit(
          unref(donutsObjs)["Volume x Size"].donutData.datasets[0].data[3],
          unref(unitInch),
          true
        )
      ]
    ];
    volsizearr.forEach(el => unref(volsizeDonut).series[0].data.push(el));

    let prodarr = [
      ["", unref(donutsObjs).Productivity.donutData.datasets[0].data[0]],
      ["", unref(donutsObjs).Productivity.donutData.datasets[0].data[1]]
    ];
    prodarr.forEach(el => unref(prodDonut).series[0].data.push(el));
  } else {
    //有料会員でない場合は合計値のみ表示する
    unref(orderDonut).series = [];
    unref(orderDonut).chart.height = "100px";
    unref(orderDonut).title.y = 10;
    unref(orderDonut).subtitle.y = -40;

    unref(volDonut).series = [];
    unref(volDonut).chart.height = "100px";
    unref(volDonut).title.y = 10;
    unref(volDonut).subtitle.y = -40;

    unref(volsizeDonut).series = [];
    unref(volsizeDonut).chart.height = "100px";
    unref(volsizeDonut).title.y = 10;
    unref(volsizeDonut).subtitle.y = -40;

    unref(prodDonut).series[0].data.push(["", null]);
  }
}
</script>
<style>
.donutchart .highcharts-data-label span {
  text-align: center;
}
</style>
