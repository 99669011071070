<template>
  <v-row class="ma-2">
    <!-- オーダー情報テーブル -->
    <v-col class="pa-2" xl="3" lg="3" md="4" sm="12" cols="12">
      <v-card class="translucent overflow-y-auto" height="640px" rounded="0">
        <v-toolbar density="compact" class="bg-transparent" flat>
          <v-toolbar-title>
            {{ i18n.t("productivity.order_no") }}:{{ orderNumber }}
          </v-toolbar-title>
        </v-toolbar>
        <v-table density="compact" hover class="bg-transparent">
          <tbody>
            <tr v-for="obj in formattedViewData" :key="obj.key">
              <td>{{ obj.label }}</td>
              <td>{{ obj.displayValue }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-card>
    </v-col>

    <!-- オーダープロダクションチャートとエラーテーブル -->
    <v-col class="pa-2" xl="9" lg="9" md="8" sm="12" cols="12">
      <v-card class="translucent" rounded="0">
        <v-toolbar density="compact" class="bg-transparent" flat>
          <v-toolbar-title>
            {{ i18n.t("productivity.order_no") }}:{{ orderNumber }}
          </v-toolbar-title>
        </v-toolbar>
        <loading-overlay
          :is-loading="orderInfoLoadingStatus"
          text-key="productivity.timeseries_loading"
        />
        <highcharts :options="chartOptions" />
        <v-data-table
          v-model:expanded="expanded"
          :headers="tableHeader"
          :items="tableErrorData"
          :items-per-page="10"
          class="bg-transparent px-6 error-table"
          density="compact"
          hover
          show-expand
          item-value="id"
          @update:expanded="if (expanded.length > 1) expanded.shift();"
        >
          <template #expanded-row="{ item, columns }">
            <tr class="expanded">
              <td :colspan="columns.length">
                <v-data-table
                  :headers="tableHeadersErrorDetail"
                  hover
                  :items="item.err_list"
                  item-value="id"
                  density="compact"
                  class="rounded"
                ></v-data-table>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import Highcharts from "highcharts";
import { computed, ref, watch } from "vue";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  viewData: {
    type: Array,
    default: function () {
      return [];
    }
  },
  tableErrorData: {
    type: Array,
    default: function () {
      return [];
    }
  },
  orderProdData: {
    type: Array,
    default: function () {
      return [];
    }
  },
  orderInfoLoadingStatus: {
    type: Boolean,
    default: false
  }
});

const i18n = useI18n();
const expanded = ref([]);
const tableHeader = ref([
  {
    title: i18n.t("productivity.error_date_time"),
    align: "start",
    key: "t"
  },
  { title: i18n.t("productivity.error"), align: "left", key: "err" }
]);
const tableHeadersErrorDetail = ref([
  { title: i18n.t("productivity.error"), key: "err" },
  { title: i18n.t("productivity.meaning"), key: "meaning" },
  {
    title: i18n.t("productivity.detect_condition"),
    key: "detect_condition"
  },
  { title: i18n.t("productivity.countermeasure"), key: "countermeasure" },
  {
    title: i18n.t("productivity.cause"),
    key: "cause_of_occurrence"
  }
]);
const chartOptions = ref({
  chart: {
    type: "area",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "400px",
    zoomType: "xy"
  },
  time: { useUTC: false },
  title: { text: "" },
  legend: { enabled: false },
  series: [{ data: [] }],
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    },
    plotLines: []
  },
  yAxis: {
    max: null,
    min: 0,
    title: { text: i18n.t("others.unit.sheets") }
  },
  tooltip: {
    formatter: function () {
      if (this.x) {
        return `${dayjs(this.point.viewx).format("YYYY-MM-DD HH:mm:ss")}<br /><b>${this.y}</b>`;
      } else {
        return this.key;
      }
    }
  },
  plotOptions: {
    area: {
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, Highcharts.getOptions().colors[0]],
          [
            1,
            new Highcharts.Color(Highcharts.getOptions().colors[0])
              .setOpacity(0)
              .get("rgba")
          ]
        ]
      },
      threshold: null
    },
    series: {
      turboThreshold: 0
    }
  }
});

const orderNumber = computed(() => prop.viewData?.[1]?.val || "");
const formattedViewData = computed(() =>
  prop.viewData.map(obj => ({
    ...obj,
    displayValue: obj.dig
      ? Math.round(obj.val * obj.dig * 10) / 10 + obj.unit
      : obj.val
  }))
);

watch(
  () => prop.orderProdData,
  () => {
    renderChart();
  }
);

function renderChart() {
  if (prop.orderProdData.length > 0) {
    chartOptions.value.series[0].data = prop.orderProdData;
    chartOptions.value.xAxis.plotLines = prop.tableErrorData.map(obj => ({
      color: "#FF0000",
      value: new Date(obj.t),
      width: 2,
      tmp: obj.err
    }));
  } else {
    chartOptions.value.series[0].data = [];
  }
}
</script>

<style scoped>
.expanded {
  vertical-align: top;
  box-shadow: inset 0px 0px 10px -5px rgba(50, 50, 50, 1);
}
.v-data-table {
  white-space: pre-line;
}
:deep(.error-table.v-data-table table thead tr th) {
  font-size: 12px;
  font-weight: 700;
}
</style>
