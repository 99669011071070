<template>
  <v-container class="pa-0" fluid>
    <loading-bar :loading="isLoading" />
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-toolbar class="bg-transparent" elevation="4">
          <toggle-buttons
            :contents="timeButtons"
            :default="'day'"
            class="px-4"
            @select-button="changeTimescale"
          />
          <v-spacer />
          <toggle-buttons
            :contents="volumeButtons"
            :default="'stop_factor_count'"
            class="px-4"
            @select-button="changeVolumetype"
          />
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row v-if="showTopMessage" class="ma-0">
      <v-col class="pa-4 pb-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{
            i18n.t("stopfactor.message")
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-4 pb-2" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar
            density="compact"
            class="bg-transparent text-body-2 font-weight-medium"
            flat
          >
            <toggle-buttons
              ref="graphtype"
              :contents="stackButtons"
              :default="'stack'"
              class="px-4"
              @select-button="changeGraphtype"
            />
            <v-spacer />
            {{ i18n.t("others.switch.stack_label") }}
            <v-switch
              v-model="chartOptions.yAxis.stackLabels.enabled"
              color="primary"
              inset
              class="flex-grow-0 pt-6 pl-3 pr-6"
            />
          </v-toolbar>
          <highcharts :options="chartOptions" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { storeToRefs } from "pinia";
import { ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import LoadingBar from "@/components/parts/LoadingBar.vue";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import { useFilterDataStore } from "@/stores/filterData";
import { useInitialDataStore } from "@/stores/initialData";

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const initialDataStore = useInitialDataStore();

const { filterData: stopfactorFilterData } = storeToRefs(filterDataStore);
const { stopfactordata } = storeToRefs(initialDataStore);
const graphtype = ref(null);
const selectAll = ref(false);
const daycount = ref(0);
const prodSum = ref(0);
const currentTimescale = ref(undefined);
const currentGraphtype = ref("stack");
const viewdata = ref({});
const switchTarget = ref("stop_factor_count");
const isLoading = ref(true);
const clearAllData = ref(false);
const showTopMessage = ref(false);
const chartOptions = ref({
  chart: {
    type: "column",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "600px",
    zoomType: "xy"
  },
  title: {
    text: ""
  },
  legend: {
    enabled: true
  },
  series: [{ data: [] }],
  tooltip: {
    //thisにポイントデータがくる
    formatter: function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.y.toFixed()
      );
    },
    shared: true
  },
  plotOptions: {
    column: {
      stacking: "stack",
      pointIntervalUnit: "day"
    }
  },
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    }
  },
  yAxis: {
    stackLabels: { enabled: false },
    title: { text: i18n.t("others.unit.counts") }
  }
});
const colorsPlant = ref({
  "Peripheral equipment2": "#CDD9DD",
  "Peripheral equipment1": "#E6ECEE",
  Palletizer: "#CDD9DD",
  "Tying Machine": "#B4C6CC",
  "AUX. conveyor": "#B4C6CC",
  Dryer: "#9BB3BB",
  Grinder: "#82A0AA",
  "Quality Inspection Device": "#82A0AA",
  "Auto Feeder": "#9BB3BB",
  CNC: "#2D424B",
  PLC: "#3D7487",
  "Drive System": "#6E4A5E",
  Counter: "#AF577D",
  Folding: "#E1C74F",
  "Die Cut": "#DC884B",
  DSL: "#727D59",
  "Slotter Creaser": "#4C6169",
  Printing: "#9EAF5B",
  Feeding: "#99C0D2"
});
const stopfactorLabelUsed = ref([]);
const stopfactorLabelTranlation = ref({
  "Peripheral equipment2": i18n.t("stopfactor.peripheral_equipment2"),
  "Peripheral equipment1": i18n.t("stopfactor.peripheral_equipment1"),
  Palletizer: i18n.t("stopfactor.palletizer"),
  "Tying Machine": i18n.t("stopfactor.tying_machine"),
  "AUX. conveyor": i18n.t("stopfactor.aux_conveyor"),
  Dryer: i18n.t("stopfactor.dryer"),
  Grinder: i18n.t("stopfactor.grinder"),
  "Quality Inspection Device": i18n.t("stopfactor.quality_inspection_device"),
  "Auto Feeder": i18n.t("stopfactor.auto_feeder"),
  CNC: i18n.t("stopfactor.cnc"),
  PLC: i18n.t("stopfactor.plc"),
  "Drive System": i18n.t("stopfactor.drive_system"),
  Counter: i18n.t("stopfactor.counter"),
  Folding: i18n.t("stopfactor.folding"),
  "Die Cut": i18n.t("stopfactor.die_cut"),
  DSL: i18n.t("stopfactor.dsl"),
  "Slotter Creaser": i18n.t("stopfactor.slotter_creaser"),
  Printing: i18n.t("stopfactor.printing"),
  Feeding: i18n.t("stopfactor.feeding")
});
const legendSortNumber = ref({
  "Peripheral equipment2": 19,
  "Peripheral equipment1": 18,
  Palletizer: 17,
  "Tying Machine": 16,
  "AUX. conveyor": 15,
  Dryer: 14,
  Grinder: 13,
  "Quality Inspection Device": 12,
  "Auto Feeder": 11,
  CNC: 10,
  PLC: 9,
  "Drive System": 8,
  Counter: 7,
  Folding: 6,
  "Die Cut": 5,
  DSL: 4,
  "Slotter Creaser": 3,
  Printing: 2,
  Feeding: 1
});

const timeButtons = ["day", "week", "month"];
const volumeButtons = ["stop_factor_count", "stop_factor_time"];
const stackButtons = ["stack", "ratio"];

watch(
  stopfactorFilterData,
  value => {
    if (value.selectplantid.length !== 1 || !value.from || !value.to) {
      clearAllData.value = true;
      showTopMessage.value = true;
    } else {
      clearAllData.value = false;
      showTopMessage.value = false;
    }
    isLoading.value = false;
    fillData();
    renderChart(unref(currentTimescale));
  },
  {
    deep: true,
    immediate: false
  }
);
watch(
  stopfactordata,
  () => {
    setUp();
  },
  {
    deep: true
  }
);

/**
 * 表示データをつくるメソッド
 */
async function fillData() {
  const nextDateOf = yyyymmdd =>
    dayjs(yyyymmdd).add(1, "days").format("YYYY-MM-DD");
  const funcFilter = item => {
    return (
      (unref(stopfactorFilterData).selectregion.length === 0 ||
        unref(stopfactorFilterData).selectregion.indexOf(item.region) >= 0) &&
      (unref(stopfactorFilterData).selectplant.length === 0 ||
        unref(stopfactorFilterData).selectplant.indexOf(item.city_plant_no) >=
          0) &&
      (!unref(stopfactorFilterData).to ||
        dayjs(item.dt).isBefore(nextDateOf(unref(stopfactorFilterData).to))) &&
      //item.dtも日付しかなくてフィルタと同じ日付はアフターfalseになっちゃうので1分後で判定する
      (!unref(stopfactorFilterData).from ||
        dayjs(item.dt + " 00:00:01").isAfter(unref(stopfactorFilterData).from))
    );
  };

  let viewdataAry = structuredClone(toRaw(unref(stopfactordata))).filter(
    funcFilter
  );

  //データで使用されているラベルのみ抽出
  stopfactorLabelUsed.value = [];
  for (let element of viewdataAry) {
    for (let key in element["stop_factor_count"]) {
      if (!unref(stopfactorLabelUsed).includes(key)) {
        unref(stopfactorLabelUsed).push(key);
      }
    }
  }
  let legendSortNumberTemp = unref(legendSortNumber);
  unref(stopfactorLabelUsed).sort(function (first, second) {
    if (legendSortNumberTemp[first] > legendSortNumberTemp[second]) {
      return -1;
    } else if (legendSortNumberTemp[first] < legendSortNumberTemp[second]) {
      return 1;
    } else {
      return 0;
    }
  });

  //セグメントでまとめたオブジェクトに変更
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  //複数のプラントを選べば描画しないとした
  if (unref(clearAllData)) {
    viewdata.value = [];
  } else {
    viewdata.value = groupBy(viewdataAry, "city_plant_no");
  }
}
async function renderChart(timescale) {
  let viewdataOptional = {};
  let graphRange = { maxX: null, maxY: null, minX: null };
  unref(chartOptions).xAxis.type = "datetime";
  currentTimescale.value = timescale;

  let stopfactorData = [];

  Object.values(unref(viewdata)).forEach(ary => {
    for (let element of unref(stopfactorLabelUsed)) {
      //for中対象のデータ
      let current = { x: new Date(), y: null };
      //グラフにだす配列。カレントの配列
      let plantTimeAry = [];
      daycount.value = 1;
      prodSum.value = 0;

      Object.values(ary).forEach(value => {
        let columnValue = value[unref(switchTarget)][element];

        if (timescale === "day") {
          //Xが日。特に処理はしない
          current.x = new Date(value["dt"]);
          current.y = columnValue;
          //時系列データの表示間隔を指定
          unref(chartOptions).plotOptions.column.pointIntervalUnit = "day";
          plantTimeAry.push(Object.assign({}, current));
        } else if (timescale === "week") {
          //Xが週
          let targetX = new Date(value["week_start"]);
          //時系列データの表示間隔を指定
          unref(chartOptions).plotOptions.column.pointIntervalUnit = "week";
          plantTimeAry = getSum(current, value, plantTimeAry, targetX, element);
        } else if (timescale === "month") {
          //Xが月
          let targetX = new Date(
            new Date(value["dt"]).getFullYear() +
              "-" +
              ("00" + (new Date(value["dt"]).getMonth() + 1)).slice(-2) +
              "-01"
          );
          //時系列データの表示間隔を指定
          unref(chartOptions).plotOptions.column.pointIntervalUnit = "month";
          plantTimeAry = getSum(current, value, plantTimeAry, targetX, element);
        }
        //XYの最大最小を記録しておく
        if (!graphRange.maxX || graphRange.maxX < current.x)
          graphRange.maxX = current.x;
        if (!graphRange.minX || graphRange.minX > current.x)
          graphRange.minX = current.x;
        if (!graphRange.maxY || graphRange.maxY < current.y)
          graphRange.maxY = current.y;
      });

      //Xが日付以外（週、月）の時
      if (timescale !== "day") {
        //最後のcurrent入れる
        plantTimeAry.push(current);
        //["",""]を除く
        plantTimeAry.shift();
      }

      viewdataOptional[element] = plantTimeAry;
    }
    stopfactorData.push(viewdataOptional);
  });

  //chartoptionのseriesに入れる
  let viewdataSet = [];
  stopfactorData.forEach(elment => {
    Object.keys(elment).forEach(label => {
      viewdataSet.push({
        name: unref(stopfactorLabelTranlation)[label],
        data: viewdataOptional[label],
        borderWidth: 0,
        color: unref(colorsPlant)[label],
        legendIndex: unref(legendSortNumber)[label]
      });
    });
  });

  unref(chartOptions).series = viewdataSet;
  unref(chartOptions).legend.enabled = true;
}
function changeTimescale(clickedButton) {
  if (clickedButton === "all") {
    selectAll.value = true;
  } else {
    selectAll.value = false;
  }
  controlGraphtypeButtons();
  renderChart(clickedButton);
}
function changeGraphtype(clickedButton) {
  currentGraphtype.value = clickedButton;
  controlGraphtypeButtons();
  changeTooltipFormat();
}
function changeVolumetype(clickedButton) {
  let volumeType = clickedButton;

  switchTarget.value = volumeType;
  if (volumeType === "stop_factor_count") {
    unref(chartOptions).yAxis.title.text = i18n.t("others.unit.counts");
  } else if (volumeType === "stop_factor_time") {
    unref(chartOptions).yAxis.title.text = i18n.t("others.unit.minutes");
  }
  changeTooltipFormat();
  controlGraphtypeButtons();
  renderChart(unref(currentTimescale));
}
function getSum(current, value, plantTimeAry, targetX, element) {
  let columnValue = "";

  columnValue = value[unref(switchTarget)][element];
  //定義されていない発生部位の場合は0とする
  if (typeof columnValue === "undefined") {
    columnValue = 0;
  }
  if (current.x.getTime() !== targetX.getTime()) {
    plantTimeAry.push(Object.assign({}, current));
    current.x = targetX;
    current.y = columnValue;
  } else {
    current.y += columnValue;
  }
  return plantTimeAry;
}
function controlGraphtypeButtons() {
  const stackButton = unref(graphtype).$el.childNodes[0].children[0];
  const ratioButton = unref(graphtype).$el.childNodes[0].children[1];

  //全ボタンを表示する
  stackButton.setAttribute("style", "display:inline-flex");
  ratioButton.setAttribute("style", "display:inline-flex");
  stackButton.classList.remove("active");
  ratioButton.classList.remove("active");

  switch (unref(currentGraphtype)) {
    case "stack":
      unref(chartOptions).plotOptions.column.stacking = "stack";
      stackButton.classList.add("active");
      break;

    case "ratio":
      unref(chartOptions).plotOptions.column.stacking = "percent";
      ratioButton.classList.add("active");
      break;
  }
}
function changeTooltipFormat() {
  if (
    unref(currentGraphtype) === "stack" &&
    unref(switchTarget) === "stop_factor_count"
  ) {
    //thisにポイントデータがくる
    unref(chartOptions).tooltip.formatter = function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.y.toFixed()
      );
    };
  } else if (
    unref(currentGraphtype) === "ratio" &&
    unref(switchTarget) === "stop_factor_count"
  ) {
    //thisにポイントデータがくる
    unref(chartOptions).tooltip.formatter = function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.percentage.toFixed(1) +
        "%"
      );
    };
  } else if (
    unref(currentGraphtype) === "stack" &&
    unref(switchTarget) === "stop_factor_time"
  ) {
    //thisにポイントデータがくる
    unref(chartOptions).tooltip.formatter = function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.y.toFixed(1)
      );
    };
  } else if (
    unref(currentGraphtype) === "ratio" &&
    unref(switchTarget) === "stop_factor_time"
  ) {
    //thisにポイントデータがくる
    unref(chartOptions).tooltip.formatter = function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.percentage.toFixed(1) +
        "%"
      );
    };
  }
}
function setUp() {
  isLoading.value = false;
  fillData();
  const timescale = "day";
  renderChart(timescale);
}

if (unref(stopfactordata).length !== 0) {
  //フィルターが選択されていないときはデータ非表示にする
  if (
    unref(stopfactorFilterData).selectplantid.length !== 1 ||
    !unref(stopfactorFilterData).from ||
    !unref(stopfactorFilterData).to
  ) {
    clearAllData.value = true;
    showTopMessage.value = true;
  }
  setUp();
}
</script>
