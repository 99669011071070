import { defineStore } from "pinia";
import { ref } from "vue";

/**
 * drawerで取得する初期データ
 */
export const useInitialDataStore = defineStore("initialData", () => {
  const firstdata = ref([]);
  const productiondata = ref([]);
  const productiontimedata = ref([]);
  const stopfactordata = ref([]);
  const isLoading = ref(false);

  return {
    firstdata,
    productiondata,
    productiontimedata,
    stopfactordata,
    isLoading
  };
});
