<template>
  <v-container class="pa-4" fluid>
    <alart-snack-bar :alart-obj="alartObj" />
    <loading-bar :loading="isTreeLoading" />
    <v-row class="ma-0">
      <v-col v-if="!hasSelection" class="pa-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{
            i18n.t("others.message.realtime")
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="px-0 py-4" xl="12" lg="12" md="12" sm="12" cols="12">
        <v-card class="translucent" height="300px" rounded="0">
          <v-row class="ma-0">
            <v-col class="ma-0 pt-0">
              <v-toolbar density="compact" class="bg-transparent" flat>
                <v-toolbar-title>{{
                  i18n.t("history.sensor")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card
                class="translucent overflow-y-auto"
                height="240px"
                flat
                rounded="0"
              >
                <v-list
                  v-model:opened="openedNodeIds"
                  :selected="selectnodes.map(node => node.id)"
                  density="compact"
                  open-strategy="multiple"
                  select-strategy="leaf"
                >
                  <tree-view :items="treedata" @select-node="getSelectNodes" />
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col class="pa-0" cols="12" style="position: relative">
        <loading-overlay :is-loading="isdataloding" />
        <v-card class="translucent" rounded="0" :style="{ height: cardHeight }">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>{{ i18n.t("history.history") }}</v-toolbar-title>
            <v-spacer />
            <v-spacer />
            <v-switch
              v-model="separateCharts"
              color="primary"
              :label="i18n.t('history.separate_charts')"
              class="flex-grow-0 pt-6 px-4"
            />
          </v-toolbar>
          <div
            v-if="
              integrateCharts &&
              !separateCharts &&
              chartOptions.series.length > 0
            "
          >
            <highcharts
              :constructor-type="'stockChart'"
              :options="chartOptions"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-card>
          <div id="syncchart-container">
            <div v-if="separateCharts">
              <div
                v-for="(syncChartOptions, index) in syncChartArray"
                :key="index"
              >
                <highcharts
                  :constructor-type="'stockChart'"
                  :options="syncChartOptions"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RealTime"
};
</script>
<script setup>
import { Chart as highcharts } from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import TreeView from "@/components/parts/TreeView.vue";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";
import { connectToAPI } from "@/helpers/util";
import { useFilterDataStore } from "@/stores/filterData";
import { usePlantsMasterStore } from "@/stores/plantsMaster";
import { usePreference } from "@/stores/preference";

stockInit(Highcharts);

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const plantsMasterStore = usePlantsMasterStore();
const preferenceStore = usePreference();

const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { plantsMaster } = storeToRefs(plantsMasterStore);
const { language } = storeToRefs(preferenceStore);
const alartObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});
//name:リスト表示用 dp_name：グラフツールチップ表示用 column_name：DB問い合わせ用 unit：グラフY軸用
const separateCharts = ref(false);
const integrateCharts = ref(true);
const syncChartArray = ref([]);
const isdataloding = ref(false);
const isTreeLoading = ref(false);
const treedata = ref([]);
const openedNodeIds = ref([]);
const selectnodes = ref([]);
const chartOptions = ref({
  colors: ["#2196F3", "#00BCD4", "#009688", "#3F51B5", "#9C27B0"],
  rangeSelector: {
    enabled: true,
    allButtonsEnabled: true,
    inputEnabled: false,
    buttons: [
      { type: "minute", count: 1, text: i18n.t("history.zoom_min") },
      { type: "hour", count: 1, text: i18n.t("history.zoom_hour") },
      { type: "day", count: 1, text: i18n.t("history.zoom_day") },
      { type: "all", count: 1, text: i18n.t("history.zoom_all") }
    ]
    //selected: 3, // デフォルトで表示させる期間
  },
  tooltip: {
    shared: true
  },
  chart: {
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" }
  },
  yAxis: [],
  xAxis: {
    ordinal: false
  },
  time: { timezone: "America/New_York" },
  series: [],
  credits: { enabled: false }
});
const timerID = ref("");
const dataGotTime = ref(undefined);
const activeItemArr = ref([]);

const chartOptionSeries = computed(() => {
  return unref(chartOptions).series;
});
const hasSelection = computed(() => {
  return selectnodes.value.length > 0;
});
const cardHeight = computed(() => {
  return hasSelection.value ? "464px" : "auto"; // 64px (toolbar) + 400px (chart)
});
const extraTime = 30000;
const interval = 60000;

watch(
  stateFilterData,
  value => {
    clearInterval(unref(timerID));
    unref(chartOptions).series = [];
    unref(chartOptions).yAxis = [];
    activeItemArr.value = [];
    dataGotTime.value = undefined;

    if (value.selectplantid.length === 1) getTreeData();
    else treedata.value = [];
  },
  {
    deep: true,
    immediate: true
  }
);
watch(
  selectnodes,
  async value => {
    const currentItemArr = []; //クリック後にアクティブな項目
    const currentColNames = [];

    value.forEach(el => {
      currentItemArr.push(el.dp_name);
      currentColNames.push(el.column_name);
    });

    if (unref(activeItemArr).length === 0) {
      //クリック前にいずれの項目もアクティブでなかった場合
      activeItemArr.value = currentItemArr;
      await getFirstdata();
    } else {
      unref(activeItemArr).forEach((item, index) => {
        if (!currentItemArr.includes(item)) {
          //非アクティブになった項目と不要なY軸を削除する
          unref(chartOptions).series.splice(index, 1);

          const nodeUnits = [];
          structuredClone(toRaw(unref(selectnodes))).forEach(node => {
            nodeUnits.push(node.data_unit);
          });

          unref(chartOptions).yAxis = unref(chartOptions).yAxis.filter(y =>
            nodeUnits.includes(y.title.text)
          );

          //yAxisとseries.yAxisのインデックスを一致させる
          unref(chartOptions).yAxis.map((y, index) => {
            unref(chartOptions).series.map(el => {
              if (y.title.text === el.unit) {
                el.yAxis = index;
              }
            });
          });
          return;
        }
      });

      currentItemArr.forEach(async item => {
        if (!unref(activeItemArr).includes(item)) {
          await getFirstdata();
          return;
        }
      });
      activeItemArr.value = currentItemArr;
    }

    clearInterval(unref(timerID));

    if (currentItemArr.length === 0) {
      dataGotTime.value = undefined;
      return;
    }

    timerID.value = setInterval(async () => {
      const currentTime = new Date().getTime();
      let addedData = {};
      const reqOptions = {
        method: "GET",
        url: "/api/realtimevalue",
        params: {
          plant_id_dpac: unref(stateFilterData).selectplantid[0],
          column_name: currentColNames, //配列
          from: unref(dataGotTime) - extraTime //unixtime ms
        }
      };

      const response = await connectToAPI(reqOptions, unref(alartObj));
      addedData = response.data;

      dataGotTime.value = currentTime;

      currentColNames.forEach((item, index) => {
        let newdata = structuredClone(
          toRaw(unref(chartOptions).series[index].data)
        ).concat(addedData[item]);

        //APIでソートしてるけどこっちでも念の為
        newdata = newdata.sort((a, b) => {
          return a[0] - b[0];
        });

        unref(chartOptions).series[index].data = newdata;

        //syncchartも更新する
        if (unref(separateCharts)) {
          unref(syncChartArray)[index].series[0].data = structuredClone(
            toRaw(unref(chartOptions).series[index].data)
          );
        }
      });
    }, interval);
  },
  {
    deep: true
  }
);
watch(
  chartOptionSeries,
  () => {
    if (unref(separateCharts)) {
      setSyncChartOptions();
      syncChartPointers();
    }
  },
  {
    deep: true
  }
);
watch(separateCharts, () => {
  if (unref(separateCharts)) {
    setSyncChartOptions();
    syncChartPointers();
  }
});

onBeforeUnmount(() => {
  clearInterval(unref(timerID));
});

/**
 * 画面起動時に必要なデータを取る
 */
async function getTreeData() {
  isTreeLoading.value = true;
  const reqOptions = {
    method: "GET",
    url: "/api/datapointtree",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      lang: unref(language)
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));
  treedata.value = response.data;

  //選択したプラントのタイムゾーンをhighchartにセットする
  unref(chartOptions).time.timezone = unref(plantsMaster).filter(
    value => value.plant_id_dpac == unref(stateFilterData).selectplantid[0]
  )[0].timezone_id;

  isTreeLoading.value = false;
}
/**
 * 表示データをつくるメソッド
 */
async function getFirstdata() {
  isdataloding.value = true;

  let i = unref(selectnodes).length - 1;
  let leaf = structuredClone(toRaw(unref(selectnodes)[i]));
  let loaddata = [];
  let targetYindex = 0;
  const currentTime = new Date().getTime();

  const reqOptions = {
    method: "GET",
    url: "/api/realtimevalue",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      column_name: [leaf.column_name]
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));
  loaddata =
    Object.keys(response.data).length === 0
      ? []
      : Object.values(response.data)[0];

  if (loaddata) {
    //APIでソートしてるけどこっちでも念の為
    loaddata = loaddata.sort((a, b) => {
      return a[0] - b[0];
    });
  }
  //最初に項目がアクティブになった時のみdataGotTimeを更新する
  if (!unref(dataGotTime)) {
    dataGotTime.value = currentTime;
  }

  //既に同じ単位のyaxisがあるかチェック
  let use_yaxis_arraypics = unref(chartOptions).yAxis.filter(
    element => element.title.text === leaf.data_unit
  );

  if (!use_yaxis_arraypics.length) {
    //初期状態または同じ単位のYがない場合
    unref(chartOptions).yAxis.push({
      title: { text: leaf.data_unit },
      plotLines: []
    });

    targetYindex = unref(chartOptions).yAxis.length - 1;
  } else {
    //leafと同じ単位のY軸が既にある場合
    targetYindex = unref(chartOptions).yAxis.indexOf(use_yaxis_arraypics[0]); //use_yaxis_arraypicsは絶対１つしかはいってないので
  }

  let sel = {
    data: loaddata,
    name: leaf.dp_name,
    yAxis: targetYindex,
    unit: leaf.data_unit,
    dataGrouping: {
      approximation: function (groupData) {
        if (groupData.hasNulls) {
          return null;
        }
        if (groupData.length > 1) {
          const absmax = groupData.reduce(function (acc, val) {
            if (Math.abs(acc) === Math.max(Math.abs(acc), Math.abs(val)))
              return acc;
            else return val;
          });
          return absmax;
        } else {
          return groupData[0];
        }
      }
    }
  };

  unref(chartOptions).series.push(sel);

  //閾値表示
  if (Number(leaf.ac_fault1)) {
    unref(chartOptions).yAxis[targetYindex].plotLines.push({
      value: Number(leaf.ac_fault1),
      color: unref(chartOptions).colors[i % unref(chartOptions).colors.length],
      dashStyle: "shortdash",
      width: 2,
      label: {
        text: leaf.ac_fault1,
        style: {
          color:
            unref(chartOptions).colors[i % unref(chartOptions).colors.length]
        }
      }
    });
  }
  //一旦非表示にしないとY軸の単位が正しく表示されない
  integrateCharts.value = false;
  integrateCharts.value = await true;

  isdataloding.value = false;
}
function setSyncChartOptions() {
  syncChartArray.value = [];

  const machine_timezone =
    unref(plantsMaster).filter(
      value => value.plant_id_dpac == unref(stateFilterData).selectplantid[0]
    )[0]?.timezone_id ?? "Asia/Tokyo";

  //dataにelを直接入れると表示されない
  unref(chartOptions).series.map((el, index) => {
    let syncchartBase = {
      chart: {
        marginLeft: 40,
        spacingTop: 20,
        spacingBottom: 20,
        backgroundColor: "transparent",
        style: { fontFamily: "Roboto", fontSize: "12px" }
      },
      navigator: { enabled: true },
      rangeSelector: {
        enabled: true,
        allButtonsEnabled: true,
        buttons: [
          { type: "minute", count: 1, text: i18n.t("history.zoom_min") },
          { type: "hour", count: 1, text: i18n.t("history.zoom_hour") },
          { type: "day", count: 1, text: i18n.t("history.zoom_day") },
          { type: "all", count: 1, text: i18n.t("history.zoom_all") }
        ],
        inputEnabled: false
      },
      title: {
        text: structuredClone(toRaw(unref(chartOptions).series[index].name)),
        align: "left",
        margin: 0,
        x: 30,
        style: {
          fontFamily: "Roboto",
          fontSize: "20px",
          fontWeight: "normal"
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        ordinal: false,
        crosshair: true,
        events: {
          setExtremes: syncExtremes
        }
      },
      yAxis: {
        title: {
          text: structuredClone(toRaw(unref(chartOptions).series[index].unit))
        }
      },
      time: { timezone: machine_timezone },
      tooltip: {
        //thisにHighcharts.Tooltipがくる
        positioner: function () {
          return {
            //x: this.chart.chartWidth - this.label.width,
            x: 40,
            y: -20
          };
        },
        borderWidth: 0,
        backgroundColor: "none",
        pointFormat: "{point.y}",
        headerFormat: "",
        shadow: false,
        style: {
          fontSize: "18px"
        }
      },
      series: [
        {
          data: structuredClone(toRaw(unref(chartOptions).series[index].data)),
          name: structuredClone(toRaw(unref(chartOptions).series[index].name)),
          fillOpacity: 0.3,
          dataGrouping: {
            approximation: function (groupData) {
              if (groupData.hasNulls) {
                return null;
              }
              if (groupData.length > 1) {
                const absmax = groupData.reduce(function (acc, val) {
                  if (Math.abs(acc) === Math.max(Math.abs(acc), Math.abs(val)))
                    return acc;
                  else return val;
                });
                return absmax;
              } else {
                return groupData[0];
              }
            }
          }
        }
      ]
    };

    if (index !== 0) {
      syncchartBase.rangeSelector.enabled = false;
      syncchartBase.navigator.enabled = false;
    }
    unref(syncChartArray).push(syncchartBase);
  });
}
/*
 *Zoom範囲をsyncさせる
 */
function syncExtremes(e) {
  //thisにHighcharts.Axisがくる
  let thisChart = this.chart;
  if (e.trigger !== "syncExtremes") {
    // Prevent feedback loop
    Highcharts.charts.forEach(chart => {
      if (chart && chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) {
          // It is null while updating
          chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
            trigger: "syncExtremes"
          });
        }
      }
    });
  }
}
/*
 *チャートのポインタをsyncさせる
 */
function syncChartPointers() {
  ["mousemove", "touchmove", "touchstart"].forEach(function (eventType) {
    document
      .getElementById("syncchart-container")
      .addEventListener(eventType, function (e) {
        var chart, point, i, event;

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
          chart = Highcharts.charts[i];
          if (chart !== undefined) {
            // Find coordinates within the chart
            event = chart.pointer.normalize(e);
            // Get the hovered point
            point = chart.series[0].searchPoint(event, true);
            if (point) {
              point.highlight(e);
            }
          }
        }
      });
  });

  Highcharts.Pointer.prototype.reset = function () {
    return undefined;
  };
  /**
   * Highlight a point by showing tooltip, setting hover state and draw crosshair
   */
  //thisにHighcharts.Pointがくる
  Highcharts.Point.prototype.highlight = function (event) {
    event = this.series.chart.pointer.normalize(event);

    this.onMouseOver(); // Show the hover marker
    //   this.series.chart.tooltip.refresh(this); // Show the tooltip ここをコメントにしないとエラー
    this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
  };
}
/*
 * 選択中のノード情報を取得する。
 */
function getSelectNodes(node) {
  const selectedNodeIds = unref(selectnodes).map(node => node.id);
  if (selectedNodeIds.includes(node.id)) {
    selectnodes.value = structuredClone(toRaw(unref(selectnodes))).filter(
      selectNode => selectNode.id !== node.id
    );
  } else {
    unref(selectnodes).push(node);
  }
}
</script>
<style>
.setting-update ._vue-flash-msg-body_success,
.setting-update ._vue-flash-msg-body_error {
  position: absolute;
  top: 0;
  bottom: auto !important;
  width: 20% !important;
  right: 0% !important;
}
</style>
