import {
  PublicClientApplication,
  type AuthenticationResult
} from "@azure/msal-browser";

function getClientId(): string {
  return import.meta.env.VITE_AAD_B2C_CLIENT_ID;
}

function getTenantName(): string {
  return import.meta.env.VITE_AAD_B2C_TENANE_NAME;
}

function getUserFlowName(): string {
  return import.meta.env.VITE_AAD_B2C_USER_FLOW_NAME;
}

/**
 * Azure AD B2Cのクライアント
 */
export const msalClient = new PublicClientApplication({
  auth: {
    clientId: getClientId(),
    authority: `https://${getTenantName()}.b2clogin.com/${getTenantName()}.onmicrosoft.com/${getUserFlowName()}`,
    knownAuthorities: [`${getTenantName()}.b2clogin.com`],
    redirectUri: `${window.location.origin}/`,
    navigateToLoginRequestUrl: true
  }
});

/**
 * Azure AD B2Cのログインページにリダイレクトする
 */
export async function loginRedirect(): Promise<void> {
  await msalClient.loginRedirect({
    scopes: [getClientId()]
  });
}

/**
 * Azure AD B2Cのトークンを取得する
 */
export async function getTokenSilent(): Promise<AuthenticationResult> {
  const accounts = msalClient.getAllAccounts();
  return msalClient.acquireTokenSilent({
    account: accounts[0],
    scopes: [getClientId()]
  });
}

/**
 * リダイレクトしてログアウトする
 */
export async function logoutRedirect(): Promise<void> {
  const { idToken, account } = await getTokenSilent();
  await msalClient.logoutRedirect({
    idTokenHint: idToken,
    account: account,
    postLogoutRedirectUri: "/"
  });
}
