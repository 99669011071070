import { computed, type ComputedRef } from "vue";
import { storeToRefs } from "pinia";
import { usePreference } from "@/stores/preference";

type SelectedGraphStyle = {
  isDarkMode: ComputedRef<boolean>;
  borderColor: ComputedRef<string>;
  borderWidth: number;
  plotRadius: number;
};

export function useSelectedGraphStyle(): SelectedGraphStyle {
  const preferenceStore = usePreference();
  const { darkmode } = storeToRefs(preferenceStore);

  const isDarkMode = computed(() => darkmode.value);
  const borderColor = computed(() => (isDarkMode.value ? "#FFF" : "#000"));
  const borderWidth = 3;
  const plotRadius = 10;

  return {
    isDarkMode,
    borderColor,
    borderWidth,
    plotRadius
  };
}
