import { defineStore } from "pinia";
import { ref } from "vue";

export const usePlantsMasterStore = defineStore("plantsMaster", () => {
  const plantsMaster = ref([]);

  return {
    plantsMaster
  };
});
