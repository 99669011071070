<template>
  <v-container class="pa-0" fluid>
    <alart-snack-bar :alart-obj="alertObj" />
    <alert-snack-bar-multi
      v-model="snackbar.popSnackBar"
      :type="snackbar.snackBarType"
      :title="snackbar.snackBarTitle"
      :message="snackbar.snackBarMessage"
    />
    <v-alert v-if="errored" type="warning">
      {{ i18n.t("productiontime.error_msg") }}
    </v-alert>
    <loading-bar :loading="isLoading" />
    <v-row class="ma-0">
      <v-col class="pa-0" cols="12">
        <v-toolbar class="bg-transparent" elevation="4">
          <toggle-buttons
            :contents="timeButtons"
            :default="'day'"
            class="px-4"
            @select-button="changeTimescale"
          />
          <v-spacer />
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn v-bind="props" icon density="comfortable" variant="text">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </template>
            <span>
              <v-img :src="helpimgPath" width="500px" />
            </span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row v-if="showTopMessage" class="ma-0">
      <v-col class="pa-4 pb-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{
            i18n.t("productiontime.message")
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-4 pb-2" cols="12">
        <v-card class="translucent" rounded="0">
          <v-toolbar
            density="compact"
            class="bg-transparent text-body-2 font-weight-medium"
            flat
          >
            <ToggleButtons
              ref="graphtype"
              :contents="stackButtons"
              :default="'stack'"
              class="px-4"
              @select-button="changeGraphtype"
            />
            <v-spacer />
            {{ i18n.t("others.switch.stack_label") }}
            <v-switch
              v-model="chartOptions.yAxis.stackLabels.enabled"
              color="primary"
              inset
              class="flex-grow-0 pt-6 pl-3 pr-6"
            />
          </v-toolbar>
          <highcharts :options="chartOptions" />
        </v-card>
      </v-col>
      <!-- オーダー情報散布図 -->
      <v-col v-if="isShowOrderScatterPlot" cols="12">
        <v-card rounded="0" height="100%">
          <loading-overlay :is-loading="isOverlayVisible" />
          <v-toolbar density="compact" flat class="bg-transparent">
            <v-toolbar-title>
              {{ i18n.t("productiontime.order_scatter_plot") }}
            </v-toolbar-title>
          </v-toolbar>
          <order-scatter-plot
            :view-data="scatterPlotDisp"
            :hidden-series-data="hiddenSeriesData"
            @click-order-scatter-plot="clickOrderScatterPlot"
          />
        </v-card>
      </v-col>
      <!-- オーダー情報テーブル -->
      <v-col v-if="isShowOrderInfo" cols="12">
        <v-card rounded="0" height="100%">
          <v-row class="ma-2">
            <order-info
              :view-data="orderInfoDisp"
              :table-error-data="tableErrorDataDisp"
              :order-prod-data="orderProdDisp"
              :order-info-loading-status="isOrderInfoLoading"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { storeToRefs } from "pinia";
import { computed, ref, toRaw, unref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import LoadingBar from "@/components/parts/LoadingBar.vue";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";
import OrderScatterPlot from "@/components/productiontime/OrderScatterPlot.vue";
import OrderInfo from "@/components/productiontime/OrderInfo.vue";
import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import AlertSnackBarMulti from "@/components/parts/AlertSnackBarMulti.vue";
import { getProductiontimeOrder } from "@/helpers/api/getProductiontimeOrder";
import { getErrorMaster } from "@/helpers/api/getErrorMaster";
import { getErrorSeries } from "@/helpers/api/getErrorSeries";
import { getOrderProd } from "@/helpers/api/getOrderProd";
import {
  calculateAverageByPeriod,
  replaceUndefinedWithNA,
  replaceInvalidFluteWithNA,
  convertToFixedPointNumberOrNA,
  translateDcInformation
} from "@/helpers/util";
import { useFilterDataStore } from "@/stores/filterData";
import { useInitialDataStore } from "@/stores/initialData";
import { usePreference } from "@/stores/preference";
import { useErrorMasterStore } from "@/stores/errorMaster";
import { useSelectedGraphStyle } from "@/composables/useSelectedGraphStyle";

import helpimgEn from "@/assets/translation/img/en/status.png";
import helpimgJa from "@/assets/translation/img/ja/status.png";

// 平均線の設定を定数として定義
const AVERAGE_LINE_CONFIG = {
  id: "average-line",
  color: "#f7a35c",
  width: 2,
  zIndex: 5
};

const i18n = useI18n();
const filterDataStore = useFilterDataStore();
const initialDataStore = useInitialDataStore();
const preferenceStore = usePreference();
const errorMasterStore = useErrorMasterStore();

// chartOptionsで使用するため、ここで定義
const yAxisMax = computed(() =>
  unref(currentGraphtype) === "ratio" ? 100 : null
);

const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { productiontimedata } = storeToRefs(initialDataStore);
const { language, unitInch } = storeToRefs(preferenceStore);
const { errorMaster } = storeToRefs(errorMasterStore);
const alertObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});
const graphtype = ref(null);
const selectAll = ref(false);
const isShowOrderInfo = ref(false);
const isShowOrderScatterPlot = ref(false);
const isOrderScatterPlotLoading = ref(false);
const errored = ref(false);
const daycount = ref(0);
const prodSum = ref(0);
const currentTimescale = ref(undefined);
const currentGraphtype = ref("stack");
const viewdata = ref({});
const isLoading = ref(true);
const clearAllData = ref(false);
const showTopMessage = ref(false);
const hiddenSeriesData = ref([]);
let lastClickedCategoryIndex = null;
const chartOptions = ref({
  chart: {
    type: "column",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "600px",
    zoomType: "xy",
    events: {
      redraw: function () {
        // データシリーズが複数。または比率モードの場合は平均線を表示しない
        if (
          unref(currentGraphtype) === "ratio" ||
          !hasOnlyOneVisibleData(this)
        ) {
          this.yAxis[0].removePlotLine(AVERAGE_LINE_CONFIG.id);
          return;
        }

        const visibleSeries = this.series.find(s => s.visible);
        if (visibleSeries) {
          const data = visibleSeries.data
            .filter(value => typeof value.y === "number" && !isNaN(value.y))
            .map(point => ({ x: point.x, y: point.y }));

          // フィルタに日付を設定しないとグラフが表示されない画面仕様のため、フィルタの日付データのみを指定
          const fromDate = dayjs(unref(stateFilterData).from);
          const toDate = dayjs(unref(stateFilterData).to);

          const average = calculateAverageByPeriod(
            data,
            unref(currentTimescale),
            fromDate,
            toDate
          );

          // 新しい平均線を追加
          this.yAxis[0].addPlotLine({
            ...AVERAGE_LINE_CONFIG,
            value: average
          });
        }
      }
    }
  },
  title: {
    text: ""
  },
  legend: {
    enabled: true
  },
  series: [{ data: [] }],
  tooltip: {
    //thisにポイントデータがくる
    formatter: function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.y.toFixed()
      );
    },
    shared: true
  },
  plotOptions: {
    column: {
      stacking: "stack",
      pointIntervalUnit: "day"
    },
    series: {
      cursor: "pointer",
      events: {
        show: function () {
          hiddenSeriesData.value = hiddenSeriesData.value.filter(
            color => color !== this.color
          );
        },
        hide: function () {
          if (!hiddenSeriesData.value.includes(this.color)) {
            hiddenSeriesData.value.push(this.color);
          }
        }
      },
      point: {
        events: {
          click: function () {
            const clickedDate = dayjs(this.category).format("YYYY-MM-DD");
            showOrderPlot(clickedDate);

            pointClickAndUpdateStyle(this.series.chart, this.category);
            lastClickedCategoryIndex = this.category;
          }
        }
      }
    }
  },
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    }
  },
  yAxis: {
    stackLabels: {
      enabled: false,
      formatter: function () {
        return Math.round(this.total);
      },
      style: {
        fontSize: "12px"
      }
    },
    title: { text: i18n.t("others.unit.minutes") },
    plotLines: [
      {
        ...AVERAGE_LINE_CONFIG,
        value: null
      }
    ],
    labels: {
      formatter: function () {
        const isRatio = unref(currentGraphtype) === "ratio";
        return isRatio ? this.value + "%" : this.value;
      }
    },
    max: yAxisMax
  }
});
const colorsPlant = ref({
  laststoptime: "#EEEEEE",
  stoptime: "#FF8A65",
  prodtime: "#9FA8DA",
  testtime: "#C5CAE9",
  setuptime: "#C8E6C9"
});
const productiontimeLabel = ref([
  "laststoptime",
  "stoptime",
  "prodtime",
  "testtime",
  "setuptime"
]);
const productiontimeLabelUsed = ref([]);
const productiontimeLabelTranlation = ref({
  laststoptime: i18n.t("productiontime.laststoptime"),
  stoptime: i18n.t("productiontime.stoptime"),
  prodtime: i18n.t("productiontime.prodtime"),
  testtime: i18n.t("productiontime.testtime"),
  setuptime: i18n.t("productiontime.setuptime")
});
const legendSortNumber = ref({
  laststoptime: 5,
  stoptime: 4,
  prodtime: 3,
  testtime: 2,
  setuptime: 1
});
const orders = ref([]);
const orderScatterPlotData = ref([]);
const isOrderInfoLoading = ref(false);
const orderProdData = ref([]);
const tableErrorData = ref([]);
const errorMasterObj = ref(null);
const orderInfoData = ref([
  { label: i18n.t("productivity.order_id"), key: "order_id", val: null },
  { label: i18n.t("productivity.order_no"), key: "order_no", val: null },
  {
    label: i18n.t("productivity.repeat_order_no"),
    key: "repeat_order_no",
    val: null
  },
  {
    label: i18n.t("productivity.plant"),
    key: "city_plant_no",
    val: null
  },
  { label: i18n.t("productivity.volume"), key: "prod_volume", val: null },
  {
    label: "",
    key: "prod_volume_size",
    val: null
  },
  {
    label: i18n.t("productivity.loss_sheets"),
    key: "loss_volume",
    val: null
  },
  {
    label: i18n.t("productivity.loss_rate"),
    key: "loss_rate",
    val: null,
    dig: 100,
    unit: "%"
  },
  {
    label: "",
    key: "box_size_mm",
    val: null
  },
  { label: i18n.t("productivity.bundle"), key: "bundle", val: null },
  {
    label: i18n.t("productivity.sc_os_slotter_input_value"),
    key: "sc_os_slotter_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_ds_slotter_input_value"),
    key: "sc_ds_slotter_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_register_input_value"),
    key: "sc_register_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_box_depth_input_value"),
    key: "sc_box_depth_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.dc_information"),
    key: "dc_information",
    val: null
  },
  {
    label: i18n.t("productivity.flute"),
    key: "flute_str",
    val: null
  },
  {
    label: i18n.t("productivity.start_time"),
    key: "prod_start_time_local",
    val: null
  },
  {
    label: i18n.t("productivity.end_time"),
    key: "prod_end_time_local",
    val: null
  },
  {
    label: i18n.t("productivity.production_time"),
    key: "prod_time",
    val: null
  },
  { label: i18n.t("productivity.stop_time"), key: "stop_time", val: null },
  {
    label: i18n.t("productivity.stop_count"),
    key: "stop_count",
    val: null
  },
  {
    label: i18n.t("productivity.stop_count_volume"),
    key: "stop_count_per_vol",
    val: null
  },
  {
    label: i18n.t("productivity.speed_ratio"),
    key: "prod_speed_ratio",
    val: null,
    dig: 100,
    unit: "%"
  },
  {
    label: i18n.t("productivity.productivity_per_order"),
    key: "productivity",
    val: null,
    dig: 100,
    unit: "%"
  }
]);
const snackbar = ref({
  popSnackBar: false,
  snackBarType: "warning",
  snackBarTitle: i18n.t("productiontime.no_target_order"),
  snackBarMessage: ""
});

const timeButtons = ["day", "week", "month"];
const stackButtons = ["stack", "ratio"];
const helpimgPath = computed(() => {
  if (unref(language) === "ja") {
    return helpimgJa;
  } else {
    return helpimgEn;
  }
});
const isOverlayVisible = computed(() => {
  return isOrderScatterPlotLoading.value || isOrderInfoLoading.value;
});

const scatterPlotDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(orderScatterPlotData);
});
const orderInfoDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(orderInfoData);
});
const tableErrorDataDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(tableErrorData);
});
const orderProdDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(orderProdData);
});

watch(
  stateFilterData,
  (value, oldValue) => {
    if (value.selectplantid.length !== 1 || !value.from || !value.to) {
      clearAllData.value = true;
      showTopMessage.value = true;
    } else {
      clearAllData.value = false;
      showTopMessage.value = false;
    }

    const fromChanged = value.from !== oldValue.from;
    const toChanged = value.to !== oldValue.to;
    const plantIdChanged =
      value.selectplantid.length !== oldValue.selectplantid.length;

    if (fromChanged || toChanged || plantIdChanged) {
      resetBelowCharts();
    }

    isLoading.value = false;
    fillData();
    renderChart(unref(currentTimescale));
  },
  {
    deep: true,
    immediate: false
  }
);
watch(
  productiontimedata,
  () => {
    setUp();
  },
  {
    deep: true
  }
);

onMounted(() => {
  // オーダー情報テーブルの項目でメートルかインチで表示初期表示
  if (unref(unitInch)) {
    unref(orderInfoData)[5].label = i18n.t("productivity.volume_size_in");
    unref(orderInfoData)[8].label = i18n.t("productivity.box_size_in");
  } else {
    unref(orderInfoData)[5].label = i18n.t("productivity.volume_size_mm");
    unref(orderInfoData)[8].label = i18n.t("productivity.box_size_mm");
  }
});

/**
 * 表示データをつくるメソッド
 */
async function fillData() {
  const nextDateOf = yyyymmdd =>
    dayjs(yyyymmdd).add(1, "days").format("YYYY-MM-DD");
  const funcFilter = item => {
    return (
      (unref(stateFilterData).selectregion.length === 0 ||
        unref(stateFilterData).selectregion.indexOf(item.region) >= 0) &&
      (unref(stateFilterData).selectplant.length === 0 ||
        unref(stateFilterData).selectplant.indexOf(item.city_plant_no) >= 0) &&
      (!unref(stateFilterData).to ||
        dayjs(item.dt).isBefore(nextDateOf(unref(stateFilterData).to))) &&
      //item.dtも日付しかなくてフィルタと同じ日付はアフターfalseになっちゃうので1分後で判定する
      (!unref(stateFilterData).from ||
        dayjs(item.dt + " 00:00:01").isAfter(unref(stateFilterData).from))
    );
  };

  let viewdataAry = structuredClone(toRaw(unref(productiontimedata))).filter(
    funcFilter
  );

  //ステータス時間が0のものを削除
  let num = 0;
  for (let element of viewdataAry) {
    for (let key in element) {
      if (element[key] == 0) {
        delete viewdataAry[num][key];
      }
    }
    num += 1;
  }

  //データで使用されているラベルのみ抽出
  productiontimeLabelUsed.value = [];
  for (let element of viewdataAry) {
    for (let key in element) {
      if (
        !unref(productiontimeLabelUsed).includes(key) &&
        unref(productiontimeLabel).includes(key)
      ) {
        unref(productiontimeLabelUsed).push(key);
      }
    }
  }
  let legendSortNumberTemp = unref(legendSortNumber);
  unref(productiontimeLabelUsed).sort(function (first, second) {
    if (legendSortNumberTemp[first] > legendSortNumberTemp[second]) {
      return -1;
    } else if (legendSortNumberTemp[first] < legendSortNumberTemp[second]) {
      return 1;
    } else {
      return 0;
    }
  });

  //セグメントでまとめたオブジェクトに変更
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  //複数のプラントを選べば描画しないとした
  if (unref(clearAllData)) {
    viewdata.value = [];
  } else {
    viewdata.value = groupBy(viewdataAry, "city_plant_no");
  }
}

async function renderChart(timescale) {
  let viewdataOptional = {};
  let graphRange = { maxX: null, maxY: null, minX: null };
  unref(chartOptions).xAxis.type = "datetime";
  currentTimescale.value = timescale;

  let productiontimeData = [];

  Object.values(unref(viewdata)).forEach(ary => {
    let totalsByDate = {};

    // 各日付の合計を計算
    Object.values(ary).forEach(value => {
      const date = getFormattedDate(value, timescale);
      totalsByDate[date] = totalsByDate[date] || 0;
      unref(productiontimeLabelUsed).forEach(o => {
        totalsByDate[date] += value[o] || 0;
      });
    });

    for (let element of unref(productiontimeLabelUsed)) {
      //for中対象のデータ
      let current = {
        x: new Date(),
        y: null,
        rawValue: null
      };
      //グラフにだす配列。カレントの配列
      let plantTimeAry = [];
      daycount.value = 1;
      prodSum.value = 0;

      Object.values(ary).forEach(value => {
        const columnValue = value[element] || 0;
        const date = getFormattedDate(value, timescale);

        if (timescale === "day") {
          //Xが日。特に処理はしない
          current.x = new Date(value["dt"]);
          current.y = calculateYValue(
            columnValue,
            totalsByDate[date],
            unref(currentGraphtype) === "ratio"
          );

          // 生のデータ値を保存 (後での計算や表示に使用)
          current.rawValue = columnValue;

          //時系列データの表示間隔を指定
          unref(chartOptions).plotOptions.column.pointIntervalUnit = "day";
          plantTimeAry.push(Object.assign({}, current));
        } else if (timescale === "week") {
          //Xが週
          let targetX = new Date(value["week_start"]);
          //時系列データの表示間隔を指定
          unref(chartOptions).plotOptions.column.pointIntervalUnit = "week";
          plantTimeAry = getSum(
            current,
            value,
            plantTimeAry,
            targetX,
            element,
            totalsByDate[date]
          );
        } else if (timescale === "month") {
          //Xが月
          let targetX = new Date(
            new Date(value["dt"]).getFullYear() +
              "-" +
              ("00" + (new Date(value["dt"]).getMonth() + 1)).slice(-2) +
              "-01"
          );
          //時系列データの表示間隔を指定
          unref(chartOptions).plotOptions.column.pointIntervalUnit = "month";
          plantTimeAry = getSum(
            current,
            value,
            plantTimeAry,
            targetX,
            element,
            totalsByDate[date]
          );
        }
        //XYの最大最小を記録しておく
        if (!graphRange.maxX || graphRange.maxX < current.x)
          graphRange.maxX = current.x;
        if (!graphRange.minX || graphRange.minX > current.x)
          graphRange.minX = current.x;
        if (!graphRange.maxY || graphRange.maxY < current.y)
          graphRange.maxY = current.y;
      });

      //Xが日付以外（週、月）の時
      if (timescale !== "day") {
        //最後のcurrent入れる
        plantTimeAry.push(current);
        //["",""]を除く
        plantTimeAry.shift();
      }

      viewdataOptional[element] = plantTimeAry;
    }
    productiontimeData.push(viewdataOptional);
  });

  //chartoptionのseriesに入れる
  let viewdataSet = [];
  productiontimeData.forEach(elment => {
    Object.keys(elment).forEach(plant => {
      viewdataSet.push({
        name: unref(productiontimeLabelTranlation)[plant],
        data: viewdataOptional[plant],
        borderWidth: 0,
        color: unref(colorsPlant)[plant],
        legendIndex: unref(legendSortNumber)[plant]
      });
    });
  });

  unref(chartOptions).series = viewdataSet;
  unref(chartOptions).legend.enabled = true;
}

function changeTimescale(clickedButton) {
  if (clickedButton === "all") {
    selectAll.value = true;
  } else {
    selectAll.value = false;
  }
  controlGraphtypeButtons();
  renderChart(clickedButton);
}

function changeGraphtype(clickedButton) {
  currentGraphtype.value = clickedButton;
  controlGraphtypeButtons();

  //グラフのタイプでツールチップの表示を変更
  if (unref(currentGraphtype) === "stack") {
    //thisにポイントデータがくる
    unref(chartOptions).tooltip.formatter = function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].point.y.toFixed()
      );
    };
  } else if (unref(currentGraphtype) === "ratio") {
    //thisにポイントデータがくる
    unref(chartOptions).tooltip.formatter = function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].series.name +
        ":" +
        this.points[0].y.toFixed(1) +
        "%"
      );
    };
  }
  renderChart(unref(currentTimescale));
}

function getSum(current, value, plantTimeAry, targetX, element, total) {
  const columnValue = value[element] || 0;
  const isNewDate = current.x.getTime() !== targetX.getTime();
  const isRatioMode = unref(currentGraphtype) === "ratio";

  if (isNewDate) {
    handleNewDate(
      current,
      plantTimeAry,
      targetX,
      columnValue,
      total,
      isRatioMode
    );
  } else {
    updateExistingDate(current, columnValue, total, isRatioMode);
  }

  return plantTimeAry;
}

/**
 * 新しい日付のデータを処理し、plantTimeAryに追加します。
 *
 * @param {Object} current - 現在のデータポイント
 * @param {Array} plantTimeAry - データポイントを格納する配列
 * @param {Date} targetX - 新しい日付
 * @param {number} columnValue - 現在の列の値
 * @param {number} total - 合計値
 * @param {boolean} isRatioMode - 比率モードかどうか
 */
function handleNewDate(
  current,
  plantTimeAry,
  targetX,
  columnValue,
  total,
  isRatioMode
) {
  plantTimeAry.push(Object.assign({}, current));
  current.x = targetX;
  current.rawValue = columnValue;
  current.y = calculateYValue(columnValue, total, isRatioMode);
}

/**
 * 既存の日付のデータを更新します。
 *
 * @param {Object} current - 現在のデータポイント
 * @param {number} columnValue - 追加する列の値
 * @param {number} total - 合計値
 * @param {boolean} isRatioMode - 比率モードかどうか
 */
function updateExistingDate(current, columnValue, total, isRatioMode) {
  current.rawValue += columnValue;
  current.y = isRatioMode
    ? calculateRatioValue(current.rawValue, total)
    : current.y + columnValue;
}

/**
 * グラフのY軸の値を計算します。
 *
 * @param {number} value - 計算する値
 * @param {number} total - 合計値
 * @param {boolean} isRatioMode - 比率モードかどうか
 * @returns {number} 計算されたY軸の値
 */
function calculateYValue(value, total, isRatioMode) {
  return isRatioMode ? calculateRatioValue(value, total) : value;
}

/**
 * 比率値を計算します。
 *
 * @param {number} value - 計算する値
 * @param {number} total - 合計値
 * @returns {number} 計算された比率（パーセンテージ）
 */
function calculateRatioValue(value, total) {
  return (value / total) * 100;
}

/**
 * 指定された時間スケールに基づいて日付をフォーマットします。
 *
 * @param {Object} value - 日付情報を含むオブジェクト
 * @param {string} value.dt - 日付の文字列表現（YYYY-MM-DD形式）
 * @param {string} [value.week_start] - 週の開始日（週次スケールの場合に使用）
 * @param {string} timescale - 時間スケール（"day", "week", "month"のいずれか）
 * @returns {string} フォーマットされた日付文字列
 */
function getFormattedDate(value, timescale) {
  if (timescale === "day") return value["dt"];
  if (timescale === "week") return value["week_start"];
  return dayjs(value["dt"]).startOf("month").format("YYYY-MM-DD");
}

function controlGraphtypeButtons() {
  switch (unref(currentGraphtype)) {
    case "stack":
      unref(chartOptions).plotOptions.column.stacking = "stack";
      break;

    case "ratio":
      unref(chartOptions).plotOptions.column.stacking = "normal";
      break;
  }
}

/**
 * チャートに表示されているデータシリーズが1つだけであるかどうかを確認します。
 *
 * @param {Highcharts.Chart} chart - 確認対象のHighchartsチャートオブジェクト
 * @returns {boolean} 表示されているデータシリーズが1つだけで、かつそのシリーズにデータが存在する場合はtrue、それ以外はfalse
 */
function hasOnlyOneVisibleData(chart) {
  const visibleSeries = chart.series.filter(
    series => series.visible && series.data.length > 0
  );
  return visibleSeries.length === 1 && visibleSeries[0].data.length > 0;
}

/*
 * 測定装置の時系列棒グラフでクリックした日付のオーダー情報を展開
 */
async function showOrderPlot(targetDate) {
  isShowOrderInfo.value = false;
  isOrderScatterPlotLoading.value = true;
  isShowOrderScatterPlot.value = true;

  const plantIdDpac = unref(stateFilterData).selectplantid[0];
  const timeType = unref(currentTimescale);

  try {
    const data = await getProductiontimeOrder(
      plantIdDpac,
      targetDate,
      timeType
    );

    orders.value = data;
    orderScatterPlotData.value = unref(orders);

    // 正常に処理できているのでエラーアラートを無効にする（再実行時用）
    errored.value = false;
  } catch (error) {
    errored.value = true;
  } finally {
    isOrderScatterPlotLoading.value = false;
  }
}

function resetBelowCharts() {
  // オーダー散布図を非表示
  isShowOrderScatterPlot.value = false;
  // オーダー情報を非表示
  isShowOrderInfo.value = false;
}

/*
 * オーダー散布図でクリックしたオーダーをテーブル用データへ展開
 */
async function clickOrderScatterPlot(orderInfoStatus, targetOrderId) {
  isOrderInfoLoading.value = true;
  isShowOrderInfo.value = orderInfoStatus;

  // 対象のオーダー情報を抽出する関数
  const getTargetOrderInfo = (orders, targetOrderId) => {
    return unref(orders).find(order => order.order_id === targetOrderId);
  };

  // 対象の項目を変換する関数
  const transformOrderInfo = orderInfo => {
    orderInfo.repeat_order_no = replaceUndefinedWithNA(
      orderInfo.repeat_order_no
    );
    orderInfo.sc_box_depth_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_box_depth_input_value
    );
    orderInfo.sc_ds_slotter_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_ds_slotter_input_value
    );
    orderInfo.sc_os_slotter_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_os_slotter_input_value
    );
    orderInfo.sc_register_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_register_input_value
    );
    orderInfo.dc_information = translateDcInformation(
      orderInfo.dc_information,
      i18n.t
    );
    orderInfo.flute_str = replaceInvalidFluteWithNA(orderInfo.flute_str);
  };

  const targetOrderInfo = getTargetOrderInfo(orders, targetOrderId);
  // 対象の項目を変換
  if (unref(targetOrderInfo)) {
    transformOrderInfo(unref(targetOrderInfo));

    // オーダー情報テーブル用データを展開
    unref(orderInfoData).forEach(obj => {
      obj.val = unref(targetOrderInfo)[obj.key];
    });

    // エラーテーブル用データを展開
    await getErrorSeriesData(unref(targetOrderInfo.order_id));

    // オーダーの生産量折れ線グラフ用データを展開
    await getOrderProdData(unref(targetOrderInfo.order_id));
  } else {
    // オーダー情報がないというsnackbarを表示
    snackbar.value.popSnackBar = true;
  }

  isOrderInfoLoading.value = false;
}

/**
 * オーダーで発生したエラーを取得する
 */
async function getErrorSeriesData(order_id) {
  try {
    await updateErrorMaster();
    const errordata = await getErrorSeries(order_id, unref(alertObj));

    if (!errordata) return;

    updateErrorMasterObj();
    processErrorData(errordata);

    errored.value = false;
  } catch (error) {
    console.error("Error in getErrorSeriesData:", error);
    errored.value = true;
  }
}

async function updateErrorMaster() {
  if (unref(errorMaster).length === 0) {
    try {
      const newErrorMaster = await getErrorMaster(
        unref(errorMaster),
        unref(language),
        unref(alertObj)
      );

      if (newErrorMaster !== undefined) {
        errorMaster.value = newErrorMaster;
      }
    } catch (error) {
      console.error("Error updating error master:", error);
    }
  }
}

function updateErrorMasterObj() {
  if (unref(errorMasterObj)) return;

  errorMasterObj.value = unref(errorMaster)
    .filter(value => value.errorcode.length === 1)
    .reduce((obj, value) => {
      obj[value.errorcode[0].toUpperCase()] = {
        meaning: formatText(value.meaning?.[0]),
        detect_condition: formatText(value.detect_condition),
        countermeasure: formatText(value.countermeasure),
        cause_of_occurrence: formatText(value.cause_of_occurrence)
      };
      return obj;
    }, {});
}

function formatText(text) {
  return text ? text.replaceAll("<br>", "\n") : null;
}

function processErrorData(errordata) {
  tableErrorData.value = [];
  let currentGroup = { t: "", err_list: [], err_list_obj: [] };
  let id_err = 0;

  for (let obj of errordata) {
    if (currentGroup.t !== obj.t) {
      if (currentGroup.t !== "") {
        addErrorGroup(currentGroup, id_err++);
      }
      currentGroup = { t: obj.t, err_list: [], err_list_obj: [] };
    }

    currentGroup.err_list.push(obj.err);
    currentGroup.err_list_obj.push(createErrorObj(obj.err));
  }

  if (currentGroup.t !== "") {
    addErrorGroup(currentGroup, id_err);
  }
}

function createErrorObj(err) {
  const errorInfo = unref(errorMasterObj)[err] || {};
  return {
    err,
    meaning: errorInfo.meaning || null,
    countermeasure: errorInfo.countermeasure || null,
    detect_condition: errorInfo.detect_condition || null,
    cause_of_occurrence: errorInfo.cause_of_occurrence || null
  };
}

function addErrorGroup(group, id) {
  unref(tableErrorData).push({
    id,
    t: dayjs(group.t).format("YYYY-MM-DD HH:mm:ss"),
    err: group.err_list.join(","),
    err_list: group.err_list_obj
  });
}

/**
 * 散布図クリック時に生産データとって描画する
 */
async function getOrderProdData(order_id) {
  try {
    const resdata = await getOrderProd(order_id);

    if (resdata) {
      /*末尾のyが0だったら取り除く
       *オーダーが終わると機械が停止し累積生産量が0になるので、
       *グラフが急に下がることを避けるため、最後の0を省いていると推測
       */
      if (resdata.slice(-1)[0].y === 0) resdata.pop();
      //ミリ秒に変換
      resdata.forEach(element => {
        element.viewx = element.x;
        element.x = new Date(element.x);
      });
      orderProdData.value = resdata;

      // 正常に処理できているのでエラーアラートを無効にする（再実行時用）
      errored.value = false;
    }
  } catch (error) {
    errored.value = true;
  }
}

function pointClickAndUpdateStyle(chart, categoryIndex) {
  const selectedGraphStyle = useSelectedGraphStyle();

  chart.series.forEach(series => {
    series.data.forEach(point => {
      // スタイルを初期化
      if (isSameDate(point.category, lastClickedCategoryIndex)) {
        point.update({
          borderColor: null,
          borderWidth: 0
        });
      }

      // スタイルを変更する
      if (isSameDate(point.category, categoryIndex)) {
        point.update({
          borderColor: selectedGraphStyle.borderColor.value,
          borderWidth: selectedGraphStyle.borderWidth
        });
      }
    });
  });
}

function isSameDate(date1, date2) {
  return dayjs(date1).isSame(date2, "day");
}

function setUp() {
  isLoading.value = false;
  fillData();
  const timescale = "day";
  renderChart(timescale);
}

if (unref(productiontimedata).length !== 0) {
  //フィルターが選択されていないときはデータ非表示にする
  if (
    unref(stateFilterData).selectplantid.length !== 1 ||
    !unref(stateFilterData).from ||
    !unref(stateFilterData).to
  ) {
    clearAllData.value = true;
    showTopMessage.value = true;
  }
  setUp();
}
</script>
