<template>
  <v-card rounded="0">
    <v-toolbar density="compact" flat class="bg-transparent">
      <v-toolbar-title>
        {{ i18n.t("erroranalysis.transition") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col class="pt-0 pl-8">
        <toggle-buttons
          :contents="timeButtons"
          :default="'month'"
          @select-button="changeTimescale"
        />
      </v-col>
    </v-row>
    <highcharts :options="chartOptions" />
  </v-card>
</template>

<script>
export default {
  name: "TimeSeries"
};
</script>
<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { computed, ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import ToggleButtons from "@/components/parts/ToggleButtons.vue";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  },
  chartType: {
    type: String,
    default: function () {
      return "errors";
    }
  }
});

const i18n = useI18n();

const timeButtons = ref(["day", "week", "month"]);
const timescale = ref("month"); //date,week,month
const chartOptions = ref({
  chart: { type: "line", zoomtype: "xy", backgroundColor: "transparent" },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: ""
    }
  },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    }
  },
  legend: {
    enabled: true
  },
  tooltip: {
    shared: true,
    //thisにポイントデータがくる
    formatter: function () {
      return (
        dayjs(this.x).format("YYYY-MM-DD") +
        "<br>" +
        this.points[0].point.errorcode +
        "<br>" +
        this.y
      );
    }
  },
  credits: { enabled: false },
  series: []
});

const contentByType = computed(() => {
  return prop.chartType === "errors" ? "count" : "stop_duration_min";
});
const unitByType = computed(() => {
  return prop.chartType === "errors" ? "count" : "min";
});

watch(
  () => prop.viewdata,
  () => {
    renderChart();
  }
);
watch(timescale, () => {
  renderChart();
});

function changeTimescale(clickedButton) {
  if (clickedButton === "day") {
    timescale.value = "date";
  } else {
    timescale.value = clickedButton;
  }
}
function renderChart() {
  unref(chartOptions).series = [];
  let timeseriesData = [];

  const viewdata = prop.viewdata.map(el => {
    return { ...el, month: el.date.substring(0, 7) };
  });

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const dataByError = groupBy(viewdata, "errors");

  if (unref(timescale) === "date") {
    timeseriesData = Object.keys(dataByError).map(code => {
      const data = dataByError[code].map(el => {
        return {
          x: dayjs(el[unref(timescale)]).toDate(),
          y: el[unref(contentByType)],
          errorcode: code
        };
      });
      return { name: code, data: data };
    });
  } else if (unref(timescale) === "week" || unref(timescale) === "month") {
    timeseriesData = aggregateData(dataByError);
  }
  unref(chartOptions).series = timeseriesData;
  unref(chartOptions).yAxis.title.text = i18n.t(
    "erroranalysis." + unref(unitByType)
  );
}
function aggregateData(dataByError) {
  const timeseriesData = Object.keys(dataByError).map(code => {
    const data = dataByError[code].reduce((result, current) => {
      const element = result.find(p => {
        return dayjs(p.x).isSame(dayjs(current[unref(timescale)]));
      });
      if (element) {
        element.y += current[unref(contentByType)];
      } else {
        result.push({
          x: dayjs(current[unref(timescale)]).toDate(),
          y: current[unref(contentByType)],
          errorcode: code
        });
      }
      return result;
    }, []);
    return { name: code, data: data };
  });
  return timeseriesData;
}
</script>
