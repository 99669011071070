<template>
  <v-row class="ma-0">
    <v-col class="pa-4">
      <v-card class="translucent" :elevation="0" rounded="0">
        <div class="mb-1 subtitle">
          {{ i18n.t("report.summary.production_information") }}
        </div>
        <v-data-table
          density="comfortable"
          hover
          :items="summaryData"
          item-value="plant_id_dpac"
        >
          <template #headers></template>
          <template #[`item`]="{ item }">
            <tr>
              <th>{{ i18n.t("report.summary.sum_prod_volume") }}</th>
              <td class="text-right">{{ item.sum_prod_volume }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.avg_prod_volume") }}</th>
              <td class="text-right">{{ item.avg_prod_volume }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.max_prod_volume") }}</th>
              <td class="text-right">{{ item.max_prod_volume }}</td>
            </tr>
            <tr>
              <th>
                {{ i18n.t("report.summary.percentage_small_lot_orders") }}
              </th>
              <td class="text-right">{{ item.percentage_small_lot_orders }}</td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-divider vertical class="ma-2"></v-divider>
    <v-col class="pa-4">
      <v-card class="translucent" :elevation="0" rounded="0">
        <div class="mb-1 subtitle">
          {{ i18n.t("report.summary.sheet_size") }}
        </div>
        <v-data-table
          density="comfortable"
          hover
          :items="summaryData"
          item-value="plant_id_dpac"
        >
          <template #headers></template>
          <template #[`item`]="{ item }">
            <tr>
              <th>{{ i18n.t("report.summary.avg_sheet_length") }}</th>
              <td class="text-right">{{ item.avg_sheet_length }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.avg_sheet_width") }}</th>
              <td class="text-right">{{ item.avg_sheet_width }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.width_ratio") }}</th>
              <td class="text-right">
                {{ i18n.t("report.summary.min") }}：{{
                  item.min_width_ratio
                }}、{{ i18n.t("report.summary.max") }}：{{
                  item.max_width_ratio
                }}、{{ i18n.t("report.summary.avg") }}：{{
                  item.avg_width_ratio
                }}
              </td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-divider vertical class="ma-2"></v-divider>
    <v-col class="pa-4">
      <v-card class="translucent" :elevation="0" rounded="0">
        <div class="mb-1 subtitle">
          {{ i18n.t("report.summary.operation_results") }}
        </div>
        <v-data-table
          density="comfortable"
          hover
          :items="summaryData"
          item-value="plant_id_dpac"
        >
          <template #headers></template>
          <template #[`item`]="{ item }">
            <tr>
              <th>{{ i18n.t("report.summary.avg_prod_time") }}</th>
              <td class="text-right">{{ item.avg_prod_time }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.avg_stop_count") }}</th>
              <td class="text-right">{{ item.avg_stop_count }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.avg_production_speed") }}</th>
              <td class="text-right">{{ item.avg_production_speed }}</td>
            </tr>
            <tr>
              <th>{{ i18n.t("report.summary.avg_order_productivity") }}</th>
              <td class="text-right">{{ item.avg_order_productivity }}</td>
            </tr>
          </template>
          <template #bottom></template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  }
});

const i18n = useI18n();

/**
 * 値のnullチェックを行いv-data-tableに渡す値を作成する
 * * 値がnull（もしくはundefined）の場合はN/Aに変換する
 * * 値がある場合は特定のプロパティ以外は小数点第2位で四捨五入を行う
 */
const summaryData = computed(() => {
  if (prop.viewdata[0] == null) return [];
  const node = {};
  for (const [k, v] of Object.entries(prop.viewdata[0])) {
    if (v == null) {
      node[k] = "N/A";
    } else {
      if (!(k == "sum_prod_volume" || k == "max_prod_volume")) {
        node[k] = v.toLocaleString(undefined, { maximumFractionDigits: 2 });
      } else {
        node[k] = v.toLocaleString(undefined, { maximumFractionDigits: 0 });
      }
    }
  }
  return [node];
});
</script>

<style scoped>
.v-data-table tbody th {
  font-weight: normal;
  font-size: 12px !important;
  color: #080b1999;
  background-color: rgba(8, 11, 25, 0.03);
  width: 50%;
}
.v-data-table tbody td {
  border-color: #ceced1;
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  font-size: 12px !important;
}
.v-data-table {
  border: solid 1px #ceced1;
}
.subtitle {
  font-weight: bold;
  font-size: 18px !important;
  color: #6b6d75;
}
</style>
