import { defineStore } from "pinia";

export const useDataperiodStore = defineStore("dataperiod", {
  state: () => ({
    dataperiod: {
      period: "",
      fromDate: "",
      toDate: ""
    }
  }),
  actions: {
    setDataperiod(period, fromDate, toDate) {
      this.dataperiod.period = period;
      this.dataperiod.fromDate = fromDate;
      this.dataperiod.toDate = toDate;
    }
  }
});
