<!--
コンポーネントの機能
* バージョンアップによる更新内容をダイアログで表示する

コンポーネントの使い方
* 親コンポーネントからpropsとして渡したopenStatusに基づき、ダイアログの開閉状態を制御する
  * openStatusがtrueであればダイアログを表示し、falseであれば非表示にする
* 親コンポーネントでは、OpenStatusUpdateRequestイベントを検知したらpropsのopenStatusを更新すること

コンポーネントで表示されるコンテンツについて
* このコンポーネントで表示するコンテンツは、別ファイルで定義する
  * public/release_note/ 以下に、ロケール毎にテキストファイルとして配置する
  * テキストファイルは、本コンポーネントからはobjectタグを用いて参照・表示する
-->
<template>
  <div class="text-center">
    <v-dialog v-model="innerOpenStatus" width="50%" scrollable>
      <v-card>
        <v-card-title class="text-h5">
          {{ i18n.t("releasenote.title") }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-list v-model:opened="openedNote">
            <v-list-group
              v-for="(version, i) in getReleaseNote"
              :key="i"
              :value="i"
            >
              <template #activator="{ isOpen, props }">
                <v-list-item
                  v-bind="props"
                  color="primary"
                  @click="openedNote = isOpen ? [] : [i]"
                >
                  <v-list-item-title
                    >{{ version.date }} [{{
                      version.version
                    }}]</v-list-item-title
                  >
                </v-list-item>
              </template>
              <v-list-item v-for="(comment, j) in version.comments" :key="j">
                <div style="display: flex; align-items: start">
                  <v-icon size="large">mdi-circle-medium</v-icon>
                  <span class="release-note-description">
                    {{ comment }}
                  </span>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="innerOpenStatus = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, unref } from "vue";
import { useI18n } from "vue-i18n";

import { usePreference } from "@/stores/preference";

import ReleaseNoteJa from "@/assets/release_note/ja.json";
import ReleaseNoteEn from "@/assets/release_note/en.json";

const prop = defineProps({
  openStatus: Boolean // 外部からダイアログの開閉を制御するためのprops。Openであればtrue, Closeであればfalse
});
const emit = defineEmits(["OpenStatusUpdateRequest"]);

const i18n = useI18n();
const preferenceStore = usePreference();

const { language } = storeToRefs(preferenceStore);
const openedNote = ref([0]); // 最新のリリースノート

const getReleaseNote = computed(() => {
  if (unref(language) === "ja") {
    return ReleaseNoteJa;
  } else {
    return ReleaseNoteEn;
  }
});
const innerOpenStatus = computed({
  get() {
    return prop.openStatus;
  },
  set(value) {
    emit("OpenStatusUpdateRequest", value); // 親コンポーネント側へ、ステータスアップデートのリクエストを出す
  }
});
</script>
<style scoped>
.release-note-description {
  font-size: 14px;
  margin-left: 5px;
  padding-top: 4px;
  white-space: pre-wrap;
}
</style>
