<template>
  <v-container fluid class="pa-0">
    <alart-snack-bar :alart-obj="alartObj" />
    <loading-bar :loading="isLoading" />
    <donut-cards :viewdata="viewdata" />
    <v-row class="mx-4 my-2">
      <v-col class="pa-0" xl="12" lg="12" md="12" sm="12" cols="12">
        <v-card class="translucent" rounded="0">
          <v-container class="mx-0 px-4 pt-4" style="max-width: 100%">
            <v-row class="mx-0 pb-2 text-h6 font-weight-regular">
              {{ i18n.t("productivity.order_plot") }}
            </v-row>
            <v-row class="mx-0">
              <v-col cols="10" class="pa-0">
                <toggle-buttons
                  :contents="volumeButtons"
                  :default="productivity ? 'productivity_per_order' : 'volume'"
                  @select-button="changeScatter"
                />
              </v-col>
            </v-row>
          </v-container>
          <loading-overlay :is-loading="isErrorDataLoading" />
          <highcharts :options="chartOptionsPlot" />
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-2">
      <v-col class="pa-2" xl="3" lg="3" md="4" sm="12" cols="12">
        <v-card class="translucent overflow-y-auto" height="640px" rounded="0">
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>
              {{ i18n.t("productivity.order_no") }}:{{ prm_tabledata[1].val }}
            </v-toolbar-title>
          </v-toolbar>
          <v-table density="compact" hover class="bg-transparent">
            <template #default>
              <tbody>
                <tr v-for="obj in prm_tabledata" :key="obj.key">
                  <td>{{ obj.label }}</td>
                  <td v-if="obj.dig">
                    {{ Math.round(obj.val * obj.dig * 10) / 10 + obj.unit }}
                  </td>
                  <td v-else>{{ obj.val }}</td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </v-card>
      </v-col>

      <v-col class="pa-2" xl="9" lg="9" md="8" sm="12" cols="12">
        <v-card class="translucent" rounded="0">
          <loading-overlay
            :is-loading="isErrorDataLoading"
            text-key="productivity.timeseries_loading"
          />
          <v-toolbar density="compact" class="bg-transparent" flat>
            <v-toolbar-title>
              {{ i18n.t("productivity.order_no") }}:{{ prm_tabledata[1].val }}
            </v-toolbar-title>
          </v-toolbar>
          <highcharts :options="chartOptions_orderprod" />

          <v-data-table
            v-model:expanded="expanded"
            :headers="table_header"
            :items="tableerrdata"
            :items-per-page="10"
            class="bg-transparent px-6 error-table"
            density="compact"
            hover
            show-expand
            item-value="id"
            @update:expanded="if (expanded.length > 1) expanded.shift();"
          >
            <template #expanded-row="{ item, columns }">
              <tr class="expanded">
                <td :colspan="columns.length">
                  <v-data-table
                    :headers="table_headers_errordetail"
                    hover
                    :items="item.err_list"
                    item-value="id"
                    density="compact"
                    class="rounded"
                  ></v-data-table>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppProductivity"
};
</script>
<script setup>
import dayjs from "dayjs";
import Highcharts from "highcharts";
import { Chart as highcharts } from "highcharts-vue";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, toRaw, unref, watch } from "vue";
import { useI18n } from "vue-i18n";

import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import DonutCards from "@/components/parts/DonutCards.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";
import { connectToAPI, convertUnit } from "@/helpers/util";
import { useErrorMasterStore } from "@/stores/errorMaster";
import { useFilterDataStore } from "@/stores/filterData";
import { useInitialDataStore } from "@/stores/initialData";
import { usePreference } from "@/stores/preference";
import { useUserinfo } from "@/stores/userinfo";
import { useSelectedGraphStyle } from "@/composables/useSelectedGraphStyle";

const i18n = useI18n();
const errorMasterStore = useErrorMasterStore();
const filterDataStore = useFilterDataStore();
const initialDataStore = useInitialDataStore();
const preferenceStore = usePreference();
const userinfoStore = useUserinfo();
const selectedGraphStyle = useSelectedGraphStyle();

const { errorMaster } = storeToRefs(errorMasterStore);
const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const { firstdata, isLoading } = storeToRefs(initialDataStore);
const { language, unitInch } = storeToRefs(preferenceStore);
const { productivity } = storeToRefs(userinfoStore);
const alartObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});
const clickedButton = ref(undefined);
const viewdata = ref({});
const switch_scatter = ref("production");
const prm_tabledata = ref([
  { label: i18n.t("productivity.order_id"), key: "order_id", val: null },
  { label: i18n.t("productivity.order_no"), key: "order_no", val: null },
  {
    label: i18n.t("productivity.repeat_order_no"),
    key: "repeat_order_no",
    val: null
  },
  {
    label: i18n.t("productivity.plant"),
    key: "city_plant_no",
    val: null
  },
  { label: i18n.t("productivity.volume"), key: "prod_volume", val: null },
  {
    label: "",
    key: "prod_volume_size",
    val: null
  },
  {
    label: i18n.t("productivity.loss_sheets"),
    key: "loss_volume",
    val: null
  },
  {
    label: i18n.t("productivity.loss_rate"),
    key: "loss_rate",
    val: null,
    dig: 100,
    unit: "%"
  },
  {
    label: "",
    key: "box_size_mm",
    val: null
  },
  { label: i18n.t("productivity.bundle"), key: "bundle", val: null },
  {
    label: i18n.t("productivity.sc_os_slotter_input_value"),
    key: "sc_os_slotter_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_ds_slotter_input_value"),
    key: "sc_ds_slotter_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_register_input_value"),
    key: "sc_register_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_box_depth_input_value"),
    key: "sc_box_depth_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.dc_information"),
    key: "dc_information",
    val: null
  },
  {
    label: i18n.t("productivity.flute"),
    key: "flute_str",
    val: null
  },
  {
    label: i18n.t("productivity.start_time"),
    key: "prod_start_time_local",
    val: null
  },
  {
    label: i18n.t("productivity.end_time"),
    key: "prod_end_time_local",
    val: null
  },
  {
    label: i18n.t("productivity.production_time"),
    key: "prod_time",
    val: null
  },
  { label: i18n.t("productivity.stop_time"), key: "stop_time", val: null },
  {
    label: i18n.t("productivity.stop_count"),
    key: "stop_count",
    val: null
  },
  {
    label: i18n.t("productivity.stop_count_volume"),
    key: "stop_count_per_vol",
    val: null
  },
  {
    label: i18n.t("productivity.speed_ratio"),
    key: "prod_speed_ratio",
    val: null,
    dig: 100,
    unit: "%"
  },
  {
    label: i18n.t("productivity.productivity_per_order"),
    key: "productivity",
    val: null,
    dig: 100,
    unit: "%"
  }
]);
const tableerrdata = ref([]);

/**
 * 生産履歴
 */
const chartOptions_orderprod = ref({
  chart: {
    type: "area",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "400px",
    zoomType: "xy"
  },
  time: { useUTC: false },
  title: { text: "" },
  legend: { enabled: false },
  series: [{ data: [] }],
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    },
    plotLines: []
  },
  yAxis: {
    max: null,
    min: 0,
    title: { text: i18n.t("others.unit.sheets") }
  },
  tooltip: {
    //thisにポイントデータがくる
    formatter: function () {
      if (this.x) {
        return this.point.viewx + "<br>" + "<b>" + this.y + "</b>";
      } else return this.key;
    }
  },
  plotOptions: {
    area: {
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, Highcharts.getOptions().colors[0]],
          [
            1,
            new Highcharts.Color(Highcharts.getOptions().colors[0])
              .setOpacity(0)
              .get("rgba")
          ]
        ]
      },
      threshold: null
    },
    series: {
      turboThreshold: 0
    }
  }
});
/**
 * 散布図
 */
const chartOptionsPlot = ref({
  chart: {
    type: "scatter",
    backgroundColor: "transparent",
    style: { fontFamily: "Roboto", fontSize: "12px" },
    height: "400px",
    zoomType: "xy"
  },
  time: { useUTC: false },
  title: { text: "" },
  legend: { enabled: false },
  series: [{ data: [] }],
  credits: { enabled: false },
  xAxis: {
    type: "datetime",
    labels: {
      rotation: -45,
      max: null,
      min: null
    },
    allowDecimals: true,
    gridLineWidth: 1
  },
  yAxis: {
    max: null,
    min: 0,
    title: {
      text: null
    },
    allowDecimals: true
  },
  tooltip: {
    //thisにポイントデータがくる
    formatter: function () {
      return (
        "ORDER ID :" +
        this.point.order_id +
        "<br>" +
        "ORDER NO : " +
        this.point.order_no +
        "<br>" +
        "TERM : " +
        this.point.prod_start_time_local +
        " - " +
        this.point.prod_end_time_local +
        "<br>" +
        "<b>" +
        this.y +
        "</b>"
      );
    }
  },
  plotOptions: {
    scatter: {
      cursor: "pointer",
      marker: {
        radius: 3.5,
        symbol: "circle",
        states: {
          hover: { enabled: true },
          select: {
            enabled: true,
            fillColor: null,
            lineColor: selectedGraphStyle.borderColor.value,
            lineWidth: selectedGraphStyle.borderWidth,
            radius: selectedGraphStyle.plotRadius
          }
        }
      }
    },
    series: {
      allowPointSelect: true,
      events: {
        click: e => {
          clickedPoint(e.point);
        }
      },
      turboThreshold: 0
    }
  }
});
const isErrorDataLoading = ref(false);
const table_header = ref([
  {
    title: i18n.t("productivity.error_date_time"),
    align: "start",
    key: "t"
  },
  { title: i18n.t("productivity.error"), align: "left", key: "err" }
]);
const expanded = ref([]);
const table_headers_errordetail = ref([
  { title: i18n.t("productivity.error"), key: "err" },
  { title: i18n.t("productivity.meaning"), key: "meaning" },
  {
    title: i18n.t("productivity.detect_condition"),
    key: "detect_condition"
  },
  { title: i18n.t("productivity.countermeasure"), key: "countermeasure" },
  {
    title: i18n.t("productivity.cause"),
    key: "cause_of_occurrence"
  }
]);
const errorMaster_obj = ref(null);

const volumeButtons = computed(() => {
  if (unref(productivity)) {
    return ["productivity_per_order", "volume", "volume_size"];
  } else {
    return ["volume", "volume_size"];
  }
});

watch(
  stateFilterData,
  () => {
    fillData();
  },
  {
    deep: true,
    immediate: true
  }
);
watch(
  firstdata,
  () => {
    fillData();
  },
  {
    deep: true
  }
);

onMounted(() => {
  //オーダーテーブル初期表示
  if (unref(unitInch)) {
    unref(prm_tabledata)[5].label = i18n.t("productivity.volume_size_in");
    unref(prm_tabledata)[8].label = i18n.t("productivity.box_size_in");
  } else {
    unref(prm_tabledata)[5].label = i18n.t("productivity.volume_size_mm");
    unref(prm_tabledata)[8].label = i18n.t("productivity.box_size_mm");
  }

  if (!unref(productivity)) {
    //Productivity非表示
    unref(prm_tabledata).splice(23, 1);
  }
});

/**
 * 表示データをつくるメソッド
 */
function fillData() {
  const nextDateOf = yyyymmdd =>
    dayjs(yyyymmdd).add(1, "days").format("YYYY-MM-DD");
  const func_filter = item => {
    return (
      (unref(stateFilterData).selectregion.length === 0 ||
        unref(stateFilterData).selectregion.indexOf(item.region) >= 0) &&
      (unref(stateFilterData).selectcustomer.length === 0 ||
        unref(stateFilterData).selectcustomer.indexOf(item.customer) >= 0) &&
      (unref(stateFilterData).selectplant.length === 0 ||
        unref(stateFilterData).selectplant.indexOf(item.city_plant_no) >= 0) &&
      (unref(stateFilterData).selectsegment.length === 0 ||
        unref(stateFilterData).selectsegment.indexOf(item.segment) >= 0) &&
      (!unref(stateFilterData).from ||
        dayjs(item.prod_start_time_local).isAfter(
          unref(stateFilterData).from
        )) &&
      (!unref(stateFilterData).to ||
        dayjs(item.prod_start_time_local).isBefore(
          nextDateOf(unref(stateFilterData).to)
        )) &&
      (!unref(stateFilterData).selectorderid ||
        item.order_id.indexOf(unref(stateFilterData).selectorderid) >= 0) &&
      (!unref(stateFilterData).selectorderno ||
        item.order_no.indexOf(unref(stateFilterData).selectorderno) >= 0)
    );
  };
  let viewdata_ary = structuredClone(toRaw(unref(firstdata))).filter(
    func_filter
  );
  viewdata_ary.forEach(element => {
    element.x = new Date(element.x);
  });

  //セグメントでまとめたオブジェクトに変更
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  viewdata.value = groupBy(viewdata_ary, "segment");

  changeScatter();
}
/**
 * ポイントをクリックしたときのメソッド
 */
async function clickedPoint(element) {
  if (element === null) {
    return;
  }
  isErrorDataLoading.value = true;
  let orderinfo = element;
  //表描画
  unref(prm_tabledata).forEach(obj => {
    obj.val = orderinfo[obj.key];
  }); //グラフデータ取得＆描画

  let volsize = unref(prm_tabledata)[5];
  const fixedVolsize = convertUnit(volsize.val, unref(unitInch), true);
  volsize.val = fixedVolsize;

  let boxsize = unref(prm_tabledata)[8];
  const fixedBoxsize = convertBoxsize(boxsize.val, unref(unitInch));
  boxsize.val = fixedBoxsize;

  await getErrorSeries(orderinfo["order_id"]);
  await getOrderProdData(orderinfo["order_id"]);
  isErrorDataLoading.value = false;
}
async function getErrorMaster() {
  if (unref(errorMaster).length > 0) {
    return;
  }

  const reqOptions = {
    method: "GET",
    url: "/api/mst/m_errors",
    params: {
      lang: unref(language)
    }
  };
  await connectToAPI(reqOptions, unref(alartObj))
    .catch(error => {
      throw error;
    })
    .then(response => {
      errorMaster.value = response.data;
    });
}
async function getErrorSeries(order_id) {
  getErrorMaster();
  tableerrdata.value = [];
  const reqOptions = {
    method: "GET",
    url: "/api/errorseries",
    params: { order: order_id }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));

  let errordata = response.data;

  //ブランクだったらerrorMaster_obj作る
  //エラーマスタのデータを扱いやすいようにオブジェクトに変更、キーをerrorcodeにする
  //まずフィルタでerrorcodeがひとつのもののみをとって、reduceで変換
  if (!unref(errorMaster_obj)) {
    errorMaster_obj.value = unref(errorMaster)
      .filter(value => value.errorcode.length == 1)
      .reduce((obj, value) => {
        obj[value.errorcode[0].toUpperCase()] = {
          meaning: value.meaning
            ? value.meaning[0].replaceAll("<br>", "\n")
            : null,
          detect_condition: value.meaning
            ? value.detect_condition.replaceAll("<br>", "\n")
            : null,
          countermeasure: value.meaning
            ? value.countermeasure.replaceAll("<br>", "\n")
            : null,
          cause_of_occurrence: value.cause_of_occurrence
            ? value.cause_of_occurrence.replaceAll("<br>", "\n")
            : null
        };
        return obj;
      }, {});
  }

  let before_t = "";
  let before_err_list = [];
  let before_err_list_obj = [];
  let id_err = 0;
  if (errordata) {
    for (let obj of errordata) {
      if (before_t === obj.t && !before_err_list.includes(obj.t)) {
        before_err_list.push(obj.err);
        before_err_list_obj.push({
          err: obj.err,
          meaning: unref(errorMaster_obj)[obj.err]
            ? unref(errorMaster_obj)[obj.err].meaning
            : null,
          countermeasure: unref(errorMaster_obj)[obj.err]
            ? unref(errorMaster_obj)[obj.err].countermeasure
            : null,
          detect_condition: unref(errorMaster_obj)[obj.err]
            ? unref(errorMaster_obj)[obj.err].detect_condition
            : null,
          cause_of_occurrence: unref(errorMaster_obj)[obj.err]
            ? unref(errorMaster_obj)[obj.err].cause_of_occurrence
            : null
        });
      } else {
        if (before_t !== "") {
          unref(tableerrdata).push({
            id: id_err,
            t: before_t,
            err: before_err_list.join(","),
            err_list: before_err_list_obj
          });
          id_err++;
        }

        before_err_list = [obj.err];
        before_t = obj.t;
        before_err_list_obj = [
          {
            err: obj.err,
            meaning: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].meaning
              : null,
            countermeasure: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].countermeasure
              : null,
            detect_condition: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].detect_condition
              : null,
            cause_of_occurrence: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].cause_of_occurrence
              : null
          }
        ];
      }
    }
    unref(tableerrdata).push({
      id: id_err,
      t: before_t,
      err: before_err_list.join(","),
      err_list: before_err_list_obj
    });
  }
}
/**
 * 散布図クリック時に生産データとって描画する
 */
async function getOrderProdData(order_id) {
  const reqOptions = {
    method: "GET",
    url: "/api/orderprod",
    params: { order: order_id }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));

  //グラフ描画
  let resdata = response.data;
  //オーダーIDに対応したデータがfirstprodhistにあるなら描画する
  if (resdata) {
    //末尾のyが0だったら取り除く
    if (resdata.slice(-1)[0].y === 0) resdata.pop();
    //milli秒に変換
    resdata.forEach(element => {
      element.viewx = element.x;
      element.x = new Date(element.x);
    });
    unref(chartOptions_orderprod).series[0].data = resdata;
    //縦ライン
    unref(chartOptions_orderprod).xAxis.plotLines = [];
    if (unref(tableerrdata)) {
      for (let obj of unref(tableerrdata)) {
        unref(chartOptions_orderprod).xAxis.plotLines.push({
          color: "#FF0000",
          value: new Date(obj.t),
          width: 2,
          tmp: obj.err,
          events: {
            mouseover: function (e) {
              var series = this.axis.series[0],
                chart = series.chart,
                PointClass = series.pointClass,
                tooltip = chart.tooltip,
                point = new PointClass().init(series),
                normalizedEvent = chart.pointer.normalize(e);

              point.tooltipPos = [
                normalizedEvent.chartX - chart.plotLeft,
                normalizedEvent.chartY - chart.plotTop
              ];

              point.name = this.options.tmp;
              tooltip.refresh(point);
            },
            mouseout: function () {
              this.axis.chart.tooltip.hide();
            }
          }
        });
      }
    }
  } else unref(chartOptions_orderprod).series[0].data = [];
}
/**
 * 散布図の表示変更を押したときのメソッド
 */
function changeScatter(currentButton) {
  //散布図表示制御
  if (currentButton) {
    //切替ボタンクリック時
    switch_scatter.value = currentButton;
    clickedButton.value = currentButton;
  } else {
    if (unref(clickedButton)) {
      //フィルタ変更時
      switch_scatter.value = unref(clickedButton);
    } else {
      //初期表示
      unref(productivity)
        ? (switch_scatter.value = "productivity_per_order")
        : (switch_scatter.value = "volume");
      clickedButton.value = unref(switch_scatter);
    }
  }

  if (unref(switch_scatter) === "productivity_per_order") {
    unref(chartOptionsPlot).yAxis.max = 1;
    unref(chartOptionsPlot).yAxis.title.text = null;
    Object.values(unref(viewdata)).forEach(function (ary) {
      ary.forEach(function (value) {
        value["y"] = value["productivity"];
      });
    });
  } else if (unref(switch_scatter) === "volume") {
    unref(chartOptionsPlot).yAxis.max = null;
    unref(chartOptionsPlot).yAxis.title.text = i18n.t("others.unit.sheets");
    Object.values(unref(viewdata)).forEach(function (ary) {
      ary.forEach(function (value) {
        value["y"] = value["prod_volume"];
      });
      ary["y"] = ary["prod_volume"];
    });
  } else if (unref(switch_scatter) === "volume_size") {
    unref(chartOptionsPlot).yAxis.max = null;
    Object.values(unref(viewdata)).forEach(function (ary) {
      ary.forEach(function (value) {
        const sheetsize = value["vxs"];
        value["y"] = convertUnit(sheetsize, unref(unitInch), true);
      });
    });

    unref(chartOptionsPlot).yAxis.title.text = unref(unitInch) ? "K in²" : "㎡";
  }

  unref(chartOptionsPlot).series = [];
  let dataarr = [];

  if (unref(viewdata)["A"]) {
    if (unref(productivity)) {
      unref(chartOptionsPlot).series.push({
        name: "A",
        color: "#4a71f4",
        data: unref(viewdata)["A"]
      });
    } else {
      //無料会員
      dataarr = dataarr.concat(unref(viewdata)["A"]);
    }
  }
  if (unref(viewdata)["B"]) {
    if (unref(productivity)) {
      unref(chartOptionsPlot).series.push({
        name: "B",
        color: "#87c03a",
        data: unref(viewdata)["B"]
      });
    } else {
      dataarr = dataarr.concat(unref(viewdata)["B"]);
    }
  }
  if (unref(viewdata)["C"]) {
    if (unref(productivity)) {
      unref(chartOptionsPlot).series.push({
        name: "C",
        color: "#daa02a",
        data: unref(viewdata)["C"]
      });
    } else {
      dataarr = dataarr.concat(unref(viewdata)["C"]);
    }
  }
  if (unref(viewdata)["D"]) {
    if (unref(productivity)) {
      unref(chartOptionsPlot).series.push({
        name: "D",
        color: "#da3c41",
        data: unref(viewdata)["D"]
      });
    } else {
      dataarr = dataarr.concat(unref(viewdata)["D"]);
    }
  }

  if (!unref(productivity)) {
    //無料会員の場合セグメント区分なし
    unref(chartOptionsPlot).series.push({
      name: "order",
      color: "#da3c41",
      data: dataarr
    });
  }
}
function convertBoxsize(boxsize, isUnitInch) {
  //元々のboxsizeデータの単位はmm String型
  //単位：inの場合 (1mm = 0.0393701 inch)
  if (!isUnitInch) {
    return boxsize;
  }
  let xposition = boxsize.indexOf("x");
  let boxwidth = Math.round(
    parseInt(boxsize.substring(0, xposition)) * 0.0393701
  );
  let boxlength = Math.round(
    parseInt(boxsize.slice(xposition + 2)) * 0.0393701
  );

  let boxsizeIn = boxwidth.toString() + " " + "x" + " " + boxlength.toString();
  return boxsizeIn;
}
</script>
<style scoped>
.expanded {
  vertical-align: top;
  box-shadow: inset 0px 0px 10px -5px rgba(50, 50, 50, 1);
}
.v-data-table {
  white-space: pre-line;
}
:deep(.error-table.v-data-table table thead tr th) {
  font-size: 12px;
  font-weight: 700;
}
</style>
